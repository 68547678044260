import {
  useMutation,
  type MutationHookOptions,
  type MutationResult,
} from '@apollo/client';
import { metrics } from '@seek/metrics-js';
import { useCallback } from 'react';

import {
  AddSalaryNudgeFeedbackDocument,
  type AddSalaryNudgeFeedbackMutation,
  type AddSalaryNudgeFeedbackMutationVariables,
} from 'src/graphql/graphql';
import { logger } from 'src/modules/logger';
import { useMelwaysZone } from 'src/utils/melwaysHelper';

import { NUDGE_METRICS_PREFIX } from './useSalaryNudgeQuery';

type AddSalaryNudgeFeedbackMutationInterface = Pick<
  AddSalaryNudgeFeedbackMutationVariables,
  'nudgeId' | 'nudgeOption'
>;

const useAddSalaryNudgeFeedbackMutation = (
  options?: MutationHookOptions<
    AddSalaryNudgeFeedbackMutation,
    AddSalaryNudgeFeedbackMutationVariables
  >,
): [
  (arg: AddSalaryNudgeFeedbackMutationInterface) => void,
  MutationResult<AddSalaryNudgeFeedbackMutation>,
] => {
  const zone = useMelwaysZone();

  const [mutation, result] = useMutation(AddSalaryNudgeFeedbackDocument, {
    onError: (error, ...arg) => {
      metrics.count(NUDGE_METRICS_PREFIX, ['userresponded:error']);
      logger.error(
        { err: error, input: { zone } },
        'AddSalaryNudgeFeedback GraphQL Error',
      );
      options?.onError?.(error, ...arg);
    },
    onCompleted: (...arg) => {
      metrics.count(NUDGE_METRICS_PREFIX, ['userresponded:success']);
      options?.onCompleted?.(...arg);
    },
    ...options,
  });

  const addSalaryNudgeFeedbackMutation = useCallback(
    ({ nudgeId, nudgeOption }: AddSalaryNudgeFeedbackMutationInterface) => {
      mutation({ variables: { nudgeId, nudgeOption, zone } });
    },
    [mutation, zone],
  );

  return [addSalaryNudgeFeedbackMutation, result];
};

export default useAddSalaryNudgeFeedbackMutation;
