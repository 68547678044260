import { useTranslations } from '@vocab/react';
import { Autosuggest, IconLocation } from 'braid-design-system';
import { useCallback, useState } from 'react';

import useLocationSuggestions from 'src/modules/hooks/useLocationSuggestions';
import { useDispatch, useSelector } from 'src/store/react';
import { clearResults } from 'src/store/results';
import {
  updateWhereField as _updateWhereField,
  whereFieldFocused,
  type whereSuggestionSelected,
} from 'src/store/search';
import { selectWhereField } from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import translations from './.vocab';

export const MobileWhereField = ({
  onSuggestionSelected,
  onWhereInputBlur,
  onClear: onExternalClear,
}: {
  onSuggestionSelected: (
    payload: Parameters<typeof whereSuggestionSelected>[0],
  ) => void;
  onWhereInputBlur: () => void;
  onClear: () => void;
}) => {
  const { t } = useTranslations(translations);
  const dispatch = useDispatch();
  const whereField = useSelector(selectWhereField);
  const country = useMelwaysCountry();
  const [whereFieldLocalState, setWhereFieldLocalState] = useState(whereField);
  const { getSuggestions, suggestions } = useLocationSuggestions();

  const onChange = (params: any) => {
    // if params.value is defined then a value was selected
    // from Autosuggest dropdown, otherwise value is taken from text field.
    if (params.value) {
      setWhereFieldLocalState(params.text);
      onSuggestionSelected({
        suggestion: params.value,
        suggestionValue: params.text,
      });
    } else if (params.text !== whereFieldLocalState) {
      updateWhereField(params.text);
      getSuggestions({ text: params.text });
    }
  };

  const updateWhereField = (text: string) => {
    setWhereFieldLocalState(text);
    dispatch(_updateWhereField(text));
  };

  const onFocus = useCallback(() => {
    dispatch(whereFieldFocused());
    getSuggestions({ text: whereFieldLocalState });
  }, [dispatch, getSuggestions, whereFieldLocalState]);

  const onClear = () => {
    updateWhereField('');
    dispatch(clearResults());
    getSuggestions({ text: '' });
    onExternalClear();
  };

  return (
    <Autosuggest
      label=""
      data-automation="mobileWhereField__Srp"
      id="mobileWherefield__Srp"
      icon={<IconLocation />}
      onBlur={onWhereInputBlur}
      onClear={onClear}
      onChange={onChange}
      onFocus={onFocus}
      value={{ text: whereFieldLocalState }}
      suggestions={suggestions}
      reserveMessageSpace={false}
      showMobileBackdrop={true}
      scrollToTopOnMobile={false}
      placeholder={
        country === 'nz'
          ? t('Enter suburb, town or city')
          : t('Enter suburb, city, or region')
      }
    />
  );
};
