import { useState, useEffect } from 'react';

import useAuthenticated from 'src/hooks/useAuthenticated';
import { selectTags } from 'src/store/location/selectors';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';

// SSA ensure we don't render the search result summary until we have done a search
// In the case of 'new to you' that means waiting for client side search happening first
// which setBlockRenderUntilFirstNewToYouCompletedRequest manages

// This hook and everywhere it's used should be removed once SSA is working.
// Please don't let this be in the code base in the coming years with my name
// in blame 🙏🏾
export const useBlockDisplayingSearchResultsUntilClientRenders = () => {
  const isBehaviouralCuesFiltersEnabled = useSelector(
    selectFeatureFlag('behaviouralCuesFilters'),
  );

  const searchTags = useSelector(selectTags);

  const authenticateState = useAuthenticated();
  const waitForLocalRender =
    isBehaviouralCuesFiltersEnabled &&
    searchTags === 'new' &&
    authenticateState === 'pending';

  const [
    blockRenderUntilFirstNewToYouCompletedRequest,
    setBlockRenderUntilFirstNewToYouCompletedRequest,
  ] = useState(waitForLocalRender);

  useEffect(() => {
    if (
      isBehaviouralCuesFiltersEnabled &&
      blockRenderUntilFirstNewToYouCompletedRequest &&
      searchTags === 'new' &&
      authenticateState !== 'pending'
    ) {
      setBlockRenderUntilFirstNewToYouCompletedRequest(false);
    }
  }, [
    blockRenderUntilFirstNewToYouCompletedRequest,
    authenticateState,
    isBehaviouralCuesFiltersEnabled,
    searchTags,
  ]);

  return blockRenderUntilFirstNewToYouCompletedRequest;
};
