import { useEffect } from 'react';
// @ts-expect-error
import { provideHooks } from 'redial';
import loadable from 'sku/@loadable/component';

import PoeaCountryPicker from 'src/components/Poea/PoeaCountryPicker';
import { SafetyHelmet } from 'src/components/SafetyHelmet/SafetyHelmet';
import { BehavioralCuesFiltersProvider } from 'src/components/SearchResultPage/BehaviouralCuesFiltersContext/BehaviouralCuesFiltersContext';
import { BannerPlacement } from 'src/graphql/graphql';
import { useIntercom } from 'src/hooks/useIntercom';
import { useSaveJobFromQueryParam } from 'src/hooks/useSaveJob/utils/useSaveJobFromQueryParam';
import {
  isBrowserAnalyticsFacade,
  type JobBadgesFilter,
} from 'src/modules/AnalyticsFacade';
import { useSearchResultsHead } from 'src/modules/seo-header';
import { getBanner } from 'src/store/banner';
import { clearJobDetails } from 'src/store/jobdetails';
import { useDispatch, useSelector } from 'src/store/react';
import { getResults, saveSolReferencesAction } from 'src/store/results';
import { expandSearchFilters, persistCriteriaLocally } from 'src/store/search';
import {
  selectLocation,
  selectPoeaCountryPicker,
  selectSearchPerformedAnalyticsData,
} from 'src/store/selectors';
import type { RedialLocals } from 'src/types/RedialLocals';

import { SplitViewContextProvider, useSplitView } from './SplitViewContext';

export const SearchResultPage = loadable(
  /* #__LOADABLE__ */ () =>
    import(
      /* webpackChunkName: "SearchResultPage" */ 'src/components/SearchResultPage/SearchResultPage'
    ),
  {
    resolveComponent: (m) => m.default,
  },
);

const hooks = {
  fetch: ({
    analyticsFacade,
    brand,
    dispatch,
    getState,
    country,
    zone,
    path,
    query,
    cookies,
    userAgent,
    visitorId,
    locale,
    serverProps: {
      xRealIp,
      ja3Hash,
      ja4,
      threatScore,
      verifiedBot,
      botScore,
      ipCountry,
    } = {},
  }: RedialLocals) => {
    const searchArgs = {
      brand,
      country,
      locale,
      zone,
      path,
      query,
      cookies,
      userAgent,
      visitorId,
      xRealIp,
      ja3Hash,
      ja4,
      threatScore,
      verifiedBot,
      botScore,
      ipCountry,
    };
    dispatch(expandSearchFilters(true));
    return dispatch(getResults(searchArgs)).then(() => {
      const currentState = getState();
      const isPoeaCountryPicker = selectPoeaCountryPicker(currentState);
      if (!isPoeaCountryPicker) {
        const payload = selectSearchPerformedAnalyticsData(currentState);
        analyticsFacade.searchPerformed(payload);
      }
    });
  },
  defer: ({ dispatch, routeEnter }: RedialLocals) => {
    if (routeEnter && window.hj) {
      window.hj('trigger', 'search-results-all-browsers');
    }
    return dispatch(persistCriteriaLocally());
  },

  pageLoad: ({
    dispatch,
    getState,
    apolloClient,
    query,
    analyticsFacade,
  }: RedialLocals) => {
    const {
      results: { pills, solMetadata, isLoading },
      appConfig: {
        zoneFeatures: { LMIS_ENABLED },
      },
      search: { poeaCountryPicker },
      user: { userClientId },
    } = getState();
    if (LMIS_ENABLED) {
      dispatch(
        getBanner({
          apolloClient,
          placement: BannerPlacement.SerpFooterLinks,
          visitorId: userClientId,
        }),
      );
    }

    if (
      !isLoading &&
      isBrowserAnalyticsFacade(analyticsFacade) &&
      !poeaCountryPicker
    ) {
      const searchResultsTab = query?.tags?.includes('new')
        ? 'New to you'
        : 'All';

      analyticsFacade.searchResultsPageLoaded(
        {
          refinedKeywords: pills?.map(({ isActive, label }, index) => ({
            isActive,
            label,
            position: index,
          })),
          solMetadata,
          searchResultsTab,
          jobBadgesFilter: query?.tags
            ? ([query?.tags] as JobBadgesFilter)
            : [],
        },
        {
          flightId: solMetadata?.tags?.mordor__flights ?? '',
          linkPosition: query?.pos,
          jobBadgesFilter: query?.tags ? [query?.tags] : [],
          searchResultsTab,
        },
      );
    }

    dispatch(saveSolReferencesAction());
  },
};

function useClearJobDetailsOnLocationChange() {
  const dispatch = useDispatch();
  const currentUrl = useSelector(selectLocation).url;

  // clear job details for splitview
  useEffect(() => {
    const action = clearJobDetails();
    dispatch(action);
  }, [currentUrl, dispatch]);
}

const SearchResults = () => {
  useEffect(() => {
    window.hj =
      window.hj ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (hj.q = hj.q || []).push(arguments);
      };
  });

  useIntercom();

  useClearJobDetailsOnLocationChange();

  useSaveJobFromQueryParam();

  const helmetData = useSearchResultsHead();

  const splitViewContextValue = useSplitView();

  const isCountryPicker = useSelector(selectPoeaCountryPicker);

  return (
    <>
      <SafetyHelmet {...helmetData} key="head" />
      <SplitViewContextProvider value={splitViewContextValue}>
        {isCountryPicker ? (
          <PoeaCountryPicker />
        ) : (
          <BehavioralCuesFiltersProvider>
            <SearchResultPage key="body" />
          </BehavioralCuesFiltersProvider>
        )}
      </SplitViewContextProvider>
    </>
  );
};

export default provideHooks(hooks)(SearchResults);
