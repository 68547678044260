import type {
  SalaryType as SalaryFrequencyType,
  SearchParams,
} from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';
import { Box, Heading, HiddenVisually, Text } from 'braid-design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'src/components/NavLink/NavLink';
import type { SalaryRange_SearchSalaryRange } from 'src/config/types';

import translations from '../.vocab';

import * as styles from './SalaryType.css';

const ROBOTS_TAG = `nofollow`;

export const SalaryType = ({
  updateDefaultSalaryActiveField,
  salaryType,
  defaultFrequency,
  searchQueryWithoutSalary,
  salaryData,
  filterSelection,
  setFilterSelection,
}: {
  updateDefaultSalaryActiveField: () => void;
  salaryType: SalaryFrequencyType;
  defaultFrequency: SalaryFrequencyType;
  searchQueryWithoutSalary: Omit<SearchParams, 'salarytype' | 'salaryrange'>;
  salaryData: SalaryRange_SearchSalaryRange[];
  filterSelection: SearchParams;
  setFilterSelection: React.Dispatch<React.SetStateAction<SearchParams>>;
}) => {
  const { t } = useTranslations(translations);

  const [selectedSalaryType, setSelectedSalaryType] = useState(
    filterSelection?.salarytype || defaultFrequency,
  );

  const salaryTypes = useMemo(
    () =>
      salaryData.map(({ frequency: { type, label, isDefault } }) => {
        const isActive = type === salaryType;
        return {
          label,
          value: type as SalaryFrequencyType,
          isActive,
          refineParams:
            !isActive && !isDefault
              ? {
                  ...searchQueryWithoutSalary,
                  salarytype: type,
                }
              : searchQueryWithoutSalary,
        };
      }),
    [salaryData, salaryType, searchQueryWithoutSalary],
  );

  const handleChange = useCallback(
    (value: SalaryFrequencyType) => {
      setFilterSelection((current) => {
        delete current.salaryrange;
        const updated = {
          ...current,
          salarytype: value,
        };
        return updated;
      });

      updateDefaultSalaryActiveField();
    },
    [setFilterSelection, updateDefaultSalaryActiveField],
  );

  useEffect(() => {
    setSelectedSalaryType(filterSelection?.salarytype || defaultFrequency);
  }, [defaultFrequency, filterSelection?.salarytype]);

  return (
    <Box data={{ automation: 'salaryType' }} component="section">
      <HiddenVisually>
        <Heading level="1">{'Salary type'}</Heading>
      </HiddenVisually>
      <Box component="ul" role="radiogroup" display="flex">
        {salaryTypes?.map(({ isActive, value, label, refineParams }) => (
          <Box
            component="li"
            className={{
              [styles.item]: true,
              [styles.active]: selectedSalaryType === value,
            }}
            key={value}
            role="presentation"
            data-automation={`refineSalaryType${value}${
              selectedSalaryType === value ? '-active' : ''
            }`}
          >
            <Text
              tone={selectedSalaryType === value ? 'formAccent' : 'secondary'}
            >
              <Link
                rel={ROBOTS_TAG}
                role="radio"
                aria-label={t('Salary type, label', { label })}
                aria-checked={isActive}
                location={
                  isActive
                    ? {
                        pathname: '/jobs',
                        query: filterSelection,
                        hash: '#',
                      }
                    : {
                        pathname: '/jobs',
                        query: {
                          ...refineParams,
                          salarytype: value,
                        },
                      }
                }
                data-automation={isActive ? 'refineActiveSalaryType' : ''}
                onClick={() => handleChange(value)}
              >
                {label}
              </Link>
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
