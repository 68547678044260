import { useTranslations } from '@vocab/react';
import { Box, CheckboxStandalone, Stack, Text } from 'braid-design-system';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';

import NavLink from 'src/components/NavLink/NavLink';
import { classificationList } from 'src/components/Search/StaticList';
import { useSERPHasExternalJobs } from 'src/modules/hooks/useSERPHasExternalJobs';
import type { RefinementClassification } from 'src/modules/refine-job-search/lib/types';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import translations from './.vocab';

import * as styles from './RefineChecklist.css';

type FilterFn = () => boolean;

interface RenderChecklistProps {
  options?: RefinementClassification[];
  path: string;
  filter?: FilterFn;
  depth?: number;
  onSelectionChange?: (
    option: RefinementClassification,
    isActive: boolean,
  ) => void;
}

interface ChecklistItemProps extends RenderChecklistProps {
  option: RefinementClassification;
}

const ChecklistItem = ({
  option,
  depth = 0,
  path,
  filter,
  onSelectionChange,
}: ChecklistItemProps) => {
  const { t } = useTranslations(translations);
  const hasExternalJobs = useSERPHasExternalJobs();
  const testId = `${option.type === 'all' ? 'all-' : ''}${option.value}`;

  const country = useMelwaysCountry();

  const shouldFollow = useMemo(() => {
    const countryClassifications = classificationList[country];
    return (
      countryClassifications?.some((item) => item.value === option.value) ||
      false
    );
  }, [country, option.value]);

  const [isActive, setIsActive] = useState(option.isActive);

  useEffect(() => {
    setIsActive(option.isActive);
  }, [option.isActive]);

  const handleSelection = useCallback(() => {
    onSelectionChange?.(option, !isActive);
    setIsActive(!isActive);
  }, [onSelectionChange, option, isActive]);

  return (
    <Box
      component="li"
      key={option.value}
      position="relative"
      data-automation={`item-depth-${depth}`}
      paddingY="xxsmall"
    >
      <Stack space="small">
        <NavLink
          data-automation={testId}
          data-testid={testId}
          location={{
            pathname: path,
            query: option.refineParams,
          }}
          role="checkbox"
          aria-checked={isActive}
          aria-label={`${option.label} ${
            option.displayCount ? t('jobs', { count: option.displayCount }) : ''
          }`}
          rel={
            !shouldFollow && (!option.count || hasExternalJobs)
              ? 'nofollow'
              : undefined
          }
          onClick={handleSelection}
        >
          <Box
            component="span"
            display="flex"
            justifyContent="spaceBetween"
            paddingX="gutter"
            alignItems="center"
          >
            <CheckboxStandalone
              size="standard"
              id={option.value}
              checked={isActive || false}
              onChange={handleSelection}
              aria-label={option.label}
            />
            <Box
              paddingLeft="small"
              component="span"
              textAlign="left"
              width="full"
            >
              <Text
                weight={isActive ? 'strong' : undefined}
                baseline={false}
                data={{ automation: 'item-text' }}
                align="left"
              >
                {option.type === 'all'
                  ? t('All {classification}', {
                      classification: option.label,
                    })
                  : option.label}
              </Text>
            </Box>
            <Box
              component="span"
              paddingLeft={{ mobile: 'xsmall', tablet: 'small' }}
              className={styles.displayClassificationCount}
            >
              {option.displayCount ? (
                <Text align="right">{option.displayCount}</Text>
              ) : null}
            </Box>
          </Box>
        </NavLink>
        {/* Render children recursively if available */}
        {isActive && option.children && !isEmpty(option.children) && (
          <RefineChecklist
            path={path}
            filter={filter}
            options={option.children}
            depth={depth + 1}
            onSelectionChange={onSelectionChange}
          />
        )}
      </Stack>
    </Box>
  );
};

const RefineChecklist = ({
  options = [],
  depth = 0,
  filter = () => true,
  path,
  onSelectionChange,
}: RenderChecklistProps) => {
  const filteredOptions = options.filter(filter);

  return filteredOptions.length > 0 ? (
    <Box component="ul" background={depth === 0 ? 'surface' : 'neutralLight'}>
      {filteredOptions.map((option, i) => (
        <ChecklistItem
          key={i}
          option={option}
          depth={depth}
          path={path}
          filter={filter}
          onSelectionChange={onSelectionChange}
        />
      ))}
    </Box>
  ) : null;
};

export default RefineChecklist;
