
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiR28gdG8gcHJvZmlsZSIsIkhpIHtuYW1lfSwiOiJIaSB7bmFtZX0sIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiUGVyZ2kga2UgcHJvZmlsIiwiSGkge25hbWV9LCI6IkhhaSB7bmFtZX0hIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiUGVyZ2kga2UgcHJvZmlsIiwiSGkge25hbWV9LCI6IkhhaSB7bmFtZX0hIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoi4LmE4Lib4Lii4Lix4LiH4LmC4Lib4Lij4LmE4Lif4Lil4LmMIiwiSGkge25hbWV9LCI6IuC4quC4p%2BC4seC4quC4lOC4tSB7bmFtZX0sIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoi4LmE4Lib4Lii4Lix4LiH4LmC4Lib4Lij4LmE4Lif4Lil4LmMIiwiSGkge25hbWV9LCI6IuC4quC4p%2BC4seC4quC4lOC4tSB7bmFtZX0sIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiUGVyZ2kga2UgcHJvZmlsIiwiSGkge25hbWV9LCI6IkhhaSB7bmFtZX0hIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiUGVyZ2kga2UgcHJvZmlsIiwiSGkge25hbWV9LCI6IkhhaSB7bmFtZX0hIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoi4LmE4Lib4Lii4Lix4LiH4LmC4Lib4Lij4LmE4Lif4Lil4LmMIiwiSGkge25hbWV9LCI6IuC4quC4p%2BC4seC4quC4lOC4tSB7bmFtZX0sIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoi4LmE4Lib4Lii4Lix4LiH4LmC4Lib4Lij4LmE4Lif4Lil4LmMIiwiSGkge25hbWV9LCI6IuC4quC4p%2BC4seC4quC4lOC4tSB7bmFtZX0sIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiW8emw7bDtsO2IOG5r8O2w7bDtiDGpcWZw7bDtsO2xpLDrMOsw6zGmuG6veG6veG6vV0iLCJIaSB7bmFtZX0sIjoiW%2BG4qMOsw6zDrCB7bmFtZX0sXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHbyB0byBwcm9maWxlIjoiW8emw7bDtsO2IOG5r8O2w7bDtiDGpcWZw7bDtsO2xpLDrMOsw6zGmuG6veG6veG6vV0iLCJIaSB7bmFtZX0sIjoiW%2BG4qMOsw6zDrCB7bmFtZX0sXSJ9!"
        )
      )
      });
  
      export { translations as default };
    