import { useMelwaysInfo } from '@seek/melways-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getClassifications } from 'src/modules/refine-job-search';
import refineClassifications from 'src/modules/refine-job-search/refinements/classificationGraph';
import { useSelector } from 'src/store/react';
import { selectSearchQuery } from 'src/store/selectors';
import { useMelwaysZone } from 'src/utils/melwaysHelper';

import type { RefineField } from '../RefineBarV2';

import Classifications from './Classifications';

export const useClassificationsField = (
  handleSelection: (fieldId: string) => void,
): RefineField => {
  const { language: languageCode } = useMelwaysInfo();
  const zone = useMelwaysZone();
  const searchQuery = useSelector(selectSearchQuery);

  const cachedClassifications = useMemo(
    () => getClassifications(zone, languageCode),
    [zone, languageCode],
  );

  const currentCounts = useSelector(
    (state) => state.search.refinements?.counts,
  );

  const { classification: classifications } = useMemo(
    () =>
      refineClassifications(cachedClassifications, searchQuery, currentCounts),
    [cachedClassifications, searchQuery, currentCounts],
  );
  const selectedClassifications = useMemo(
    () => classifications.filter((item) => item.isActive),
    [classifications],
  );
  const [activeClassifications, setActiveClassifications] = useState<string[]>(
    [],
  );

  useEffect(() => {
    const currentClassifications = classifications
      ? classifications.filter((c) => c.isActive).map((c) => c.value.toString())
      : [];
    setActiveClassifications(currentClassifications);
  }, [classifications]);

  const handleClassificationSelection = useCallback(
    (value: string) => {
      setActiveClassifications((prevActiveClassifications) => {
        if (prevActiveClassifications.includes(value)) {
          return prevActiveClassifications.filter((v) => v !== value);
        }
        return [...prevActiveClassifications, value];
      });
      handleSelection('Classification');
    },
    [handleSelection],
  );

  const isSelected = activeClassifications.length > 0;
  const displayLabel = (() => {
    const labels: Record<number, string> = {
      0: 'Classifications',
      1: selectedClassifications[0] ? selectedClassifications[0].label : '',
    };
    return (
      labels[activeClassifications.length] ||
      `${activeClassifications.length} Classifications`
    );
  })();
  return {
    id: 'Classification',
    ariaLabel: 'Classification',
    label: displayLabel,
    filterComponent: () => (
      <Classifications
        handleSelection={handleClassificationSelection}
        classifications={classifications}
      />
    ),
    isSelected,
  };
};
