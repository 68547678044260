import { Box, PageBlock, useResponsiveValue } from 'braid-design-system';
import debounce from 'lodash/debounce';
import { AnimatePresence, motion } from 'motion/react';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FormEventHandler,
} from 'react';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useMetricsTimerContext } from 'src/modules/MetricsTimer/MetricsTimerContext';
import { isClientMobileWidth } from 'src/modules/responsive-helpers';
import { useDispatch, useSelector } from 'src/store/react';
import {
  searchSubmit,
  whereSuggestionSelected,
  updateDynamicSearchBarHeight,
} from 'src/store/search';
import { useRunSearch } from 'src/store/search/useRunSearch';
import {
  selectIsSrp,
  selectResults,
  selectSearchQuery,
  selectWhereField,
} from 'src/store/selectors';

import { useSearchPath } from '../SearchBar/SearchBar';

import { ExpandedSearchBar } from './components/ExpandedSearchBar/ExpandedSearchBar';
import { MinimisedSearchBar } from './components/MinimisedSearchBar/MinimisedSearchBar';
import { MobileSearchBar } from './components/MobileSearchBar/MobileSearchBar';
import { useAnimationDuration } from './hooks/useAnimationDuration';

import * as styles from '../Search.css';

const noop = () => {};

export type DynamicSearchBarType = 'expanded' | 'minimised' | 'mobile';

export const DynamicSearchBar = ({
  setIsMobileExpandedView = noop,
}: {
  setIsMobileExpandedView?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isMinSearchBar, setIsMinSearchBar] = useState(false);
  const isSRP = useSelector(selectIsSrp);

  const jobCardRef = useRef<HTMLElement | null>(null);
  const searchBarRef = useRef<HTMLElement | null>(null);

  const whereField = useSelector(selectWhereField);
  const dispatch = useDispatch();
  const analyticsFacade = useAnalyticsFacade();
  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });

  const { currentSearchPath, submitFormPath } = useSearchPath();

  const runSearch = useRunSearch();

  const immediateSearch = useCallback(
    (nextPathName: string = currentSearchPath) => {
      const action = runSearch({ pathname: nextPathName });
      setIsMobileExpandedView(false);
      dispatch(action);
    },
    [currentSearchPath, runSearch, setIsMobileExpandedView, dispatch],
  );
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));

  const debouncedSearch = useMemo(
    () => debounce(immediateSearch, 100, { leading: true }),
    [immediateSearch],
  );

  const [
    searchBarAndFirstJobCardIntersectionHeight,
    setSearchBarAndFirstJobCardIntersectionHeight,
  ] = useState(0);
  const jobs = useSelector(selectResults)?.jobs;

  useEffect(() => {
    if (isSRP && jobs) {
      const firstJobCardElement = ENV.CLIENT
        ? document.getElementById('jobcard-1')
        : null;

      jobCardRef.current = firstJobCardElement;

      const handleScroll = () => {
        if (jobCardRef.current && searchBarRef.current) {
          const jobCardTop = Math.floor(
            jobCardRef.current?.getBoundingClientRect()?.top,
          );
          const searchBarBottom = Math.floor(
            searchBarRef.current?.getBoundingClientRect()?.bottom,
          );

          const isFirstJobCardSearchbarIntersection =
            jobCardTop <= searchBarBottom;

          if (jobCardTop === searchBarBottom) {
            setSearchBarAndFirstJobCardIntersectionHeight(jobCardTop);
          }

          if (isFirstJobCardSearchbarIntersection) {
            if (!isMinSearchBar) {
              if (searchBarAndFirstJobCardIntersectionHeight >= jobCardTop)
                setIsMinSearchBar(true);
            }
          }

          if (!isFirstJobCardSearchbarIntersection) {
            if (isMinSearchBar) {
              if (searchBarAndFirstJobCardIntersectionHeight <= jobCardTop)
                setIsMinSearchBar(false);
            }
          }
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [
    searchBarAndFirstJobCardIntersectionHeight,
    isMinSearchBar,
    isSRP,
    isMobile,
    jobs,
  ]);

  const metrics = useMetricsTimerContext();

  const onSubmit = useCallback<FormEventHandler>(
    (event) => {
      metrics.startTimer('SearchToJobListItem:Visible');
      metrics.startTimer('SearchToJobListItem:Visible:Control');
      event.preventDefault();

      analyticsFacade.searchFormSubmitted();

      // @ts-expect-error This was the original implementation!
      dispatch(searchSubmit());

      debouncedSearch.cancel();
      immediateSearch(submitFormPath);
      setIsMobileExpandedView(false);

      if (
        isClientMobileWidth() &&
        document.activeElement &&
        document.activeElement instanceof HTMLElement
      ) {
        // NOTE: this will trigger KeywordField.onKeywordBlur -> debounceSearch (which triggers after 1s)
        // so we need to cancel it for the scenario that we're navigating
        // from home to /jobs
        document.activeElement.blur();
        debouncedSearch.cancel();
      }
    },

    // metrics context does not need to be in the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      analyticsFacade,
      dispatch,
      debouncedSearch,
      immediateSearch,
      submitFormPath,
    ],
  );

  const dynamicSearchBarRef = useRef<HTMLElement | null>(null);

  const onWhereSuggestionSelected = useCallback(
    (payload: Parameters<typeof whereSuggestionSelected>[0]) => {
      dispatch(whereSuggestionSelected(payload));
      debouncedSearch();
    },
    [dispatch, debouncedSearch],
  );

  const onWhereInputBlur = useCallback(() => {
    const { where = '' } = searchQuery;

    if (whereField.trim() !== where.trim()) {
      debouncedSearch();
    }
  }, [debouncedSearch, searchQuery, whereField]);

  const animationTransition = useAnimationDuration();

  useEffect(() => {
    const dynamicSearchBarRefCurrent = dynamicSearchBarRef.current;
    const resizeObserver = new ResizeObserver(() => {
      if (dynamicSearchBarRefCurrent) {
        dispatch(
          updateDynamicSearchBarHeight(dynamicSearchBarRefCurrent.clientHeight),
        );
      }
    });

    if (dynamicSearchBarRefCurrent) {
      resizeObserver.observe(dynamicSearchBarRefCurrent);
    }

    return () => {
      if (dynamicSearchBarRefCurrent) {
        resizeObserver.unobserve(dynamicSearchBarRefCurrent);
      }
    };
  }, [isSRP, dynamicSearchBarRef, dispatch]);

  return (
    <Box
      className={{ [styles.stickySearchBar]: isSRP }}
      ref={searchBarRef}
      id="searchBarRef"
    >
      <AnimatePresence>
        <form
          aria-labelledby="PerformASearch"
          role="search"
          name="SearchBar"
          id="SearchBar"
          method="get"
          action="/jobs"
          onSubmit={onSubmit}
        >
          <motion.div
            layout
            transition={{ duration: animationTransition }}
            style={{
              position: isSRP ? 'absolute' : 'static',
              width: '100%',
            }}
          >
            <Box
              ref={dynamicSearchBarRef}
              // This is to ensure this will not cover Header's language switcher
              zIndex={2}
              background="brand"
              paddingTop={{
                mobile: 'medium',
                tablet: isMinSearchBar ? 'none' : 'large',
              }}
              paddingBottom={{
                mobile: 'medium',
                tablet: isMinSearchBar ? 'none' : 'small',
              }}
            >
              <PageBlock width="large">
                {isMobile ? (
                  <MobileSearchBar
                    onWhereSuggestionSelected={onWhereSuggestionSelected}
                    onWhereInputBlur={onWhereInputBlur}
                    debouncedSearch={debouncedSearch}
                  />
                ) : (
                  <>
                    {!isMinSearchBar ? (
                      <ExpandedSearchBar
                        onWhereSuggestionSelected={onWhereSuggestionSelected}
                        onWhereInputBlur={onWhereInputBlur}
                        debouncedSearch={debouncedSearch}
                      />
                    ) : (
                      <MinimisedSearchBar
                        setIsMinSearchBar={setIsMinSearchBar}
                      />
                    )}
                  </>
                )}
              </PageBlock>
            </Box>
          </motion.div>
        </form>
      </AnimatePresence>
    </Box>
  );
};
