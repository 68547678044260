import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  IconArrow,
  Inline,
  Text,
  TextLink,
} from 'braid-design-system';
import { useCallback, useState } from 'react';

import { type NudgeCTA, NudgeTemplate } from '../NudgeTemplate/NudgeTemplate';

import translations from './.vocab';
import ErrorNudge from './ErrorNudge';
import ThankYouNudge from './ThankYouNudge';
import useAddSalaryNudgeFeedbackMutation from './useAddSalaryNudgeFeedbackMutation';
import { useProfileNudge } from './useProfileNudge';

import * as styles from './ProfileNudge.css';

const PROFILE_LINK = '/profile/me';
const getProfileLinkCTA = (text: string): NudgeCTA => ({
  Cta: () => (
    <Box className={styles.profileLinkContainer}>
      <Text weight="medium" tone="link" size="standard">
        <TextLink
          data-automation="profile-nudge-profile-link"
          href={PROFILE_LINK}
          weight="regular"
        >
          <Inline space="xsmall" alignY="center">
            <>{text}</>
            <IconArrow direction="right" />
          </Inline>
        </TextLink>
      </Text>
    </Box>
  ),
  href: PROFILE_LINK,
  linkText: text,
});

const ProfileNudge = ({ position }: { position: number }) => {
  const { t } = useTranslations(translations);
  const {
    heading,
    Icon,
    nudge,
    nudgeType,
    shouldFireMetrics,
    subHeading,
    text,
    loading,
  } = useProfileNudge();
  const [hasDismissNudge, setHasDismissNudge] = useState(false);

  const dismissNudge = useCallback(() => {
    setHasDismissNudge(true);
  }, [setHasDismissNudge]);

  const [addSalaryNudgeFeedbackMutation, addSalaryNudgeFeedbackMutationResult] =
    useAddSalaryNudgeFeedbackMutation();

  const shouldShowThankYouMessage =
    !addSalaryNudgeFeedbackMutationResult.loading &&
    addSalaryNudgeFeedbackMutationResult.data;
  const shouldShowError =
    !addSalaryNudgeFeedbackMutationResult.loading &&
    addSalaryNudgeFeedbackMutationResult.error;

  if (hasDismissNudge || loading || !nudge) {
    return null;
  }

  const profileLinkCTA = getProfileLinkCTA(t('Go to profile'));
  if (shouldShowError) {
    return (
      <ErrorNudge
        CTAs={[profileLinkCTA]}
        errorNudgeType={nudgeType}
        onDismiss={dismissNudge}
        position={position}
      />
    );
  }

  const { callToActions, firstName, id } = nudge;
  if (shouldShowThankYouMessage) {
    return (
      <ThankYouNudge
        CTAs={[profileLinkCTA]}
        name={firstName}
        onDismiss={dismissNudge}
        position={position}
      />
    );
  }

  const CTAs: NudgeCTA[] = callToActions.map(
    ({ operation: value, displayText }, index) => ({
      Cta: () => (
        <Button
          data={{ automation: 'profile-nudge-cta' }}
          key={value}
          onClick={() => {
            addSalaryNudgeFeedbackMutation({
              nudgeOption: value,
              nudgeId: id,
            });
          }}
          size="small"
          tone="neutral"
          variant={index === 0 ? 'ghost' : 'transparent'}
        >
          {displayText}
        </Button>
      ),
      linkText: displayText,
    }),
  );

  return (
    <NudgeTemplate
      CTAs={CTAs}
      data={{ automation: 'profile-nudge' }}
      heading={heading}
      Icon={Icon}
      id={id}
      nudgeType={nudgeType}
      onDismiss={() => {
        dismissNudge();

        addSalaryNudgeFeedbackMutation({
          nudgeId: id,
          nudgeOption: 'Dismiss',
        });
      }}
      position={position}
      shouldFireMetrics={shouldFireMetrics}
      shouldImpressNudge
      subHeading={subHeading}
      text={text}
    />
  );
};

export default ProfileNudge;
