import { useTranslations } from '@vocab/react';
import { Stack, Text, TextLink } from 'braid-design-system';

import { useTimedBanner } from 'src/config/appConfig';
import { isTimedComponentValid } from 'src/utils/timedComponent/timedComponentValidator';

import { GlobalNotificationBannerSkeleton } from '../GlobalNotificationBannerSkeleton/GlobalNotificationBannerSkeleton';

import translations from './.vocab';

export const ScamBanner = () => {
  const { t } = useTranslations(translations);
  const scamBanner = useTimedBanner('scamBanner');

  return isTimedComponentValid(scamBanner?.displayDates) ? (
    <GlobalNotificationBannerSkeleton
      dataAutomation="scamBanner"
      tone="info"
      localStorageKey="sB"
    >
      <Stack space="small">
        <Text weight="strong" size="small">
          {t('Beware of Suspected Fraudulent WhatsApp Groups')}
        </Text>
        <Text size="small">
          {t('Official communication from Jobsdb staff...', {
            Link: (children) => (
              <TextLink
                weight="weak"
                target="_blank"
                href="/page/jobsdb-official-whatsapp-account"
              >
                {children}
              </TextLink>
            ),
            MailtoLink: (children) => (
              <TextLink weight="weak" href="mailto:usersupport-hk@jobsdb.com">
                {children}
              </TextLink>
            ),
            MoreLink: (children) => (
              <TextLink
                weight="weak"
                target="_blank"
                href="/about/news/article/jobsdb-by-seek-alerts-jobseekers-on-suspected-fraudulent-whatsapp-groups"
              >
                {children}
              </TextLink>
            ),
          })}
        </Text>
      </Stack>
    </GlobalNotificationBannerSkeleton>
  ) : null;
};
