import type { Pills as PillsType } from '@seek/chalice-types';
import {
  Box,
  IconAdd,
  IconClear,
  Text,
  TooltipRenderer,
  useResponsiveValue,
} from 'braid-design-system';
import { forwardRef } from 'react';

import { truncateText } from '../../../../../utils/truncateText/truncateText.ts';

import * as styles from './Pills.css';

interface PillsProps {
  handleOnPillClick: (
    label: string,
    keyword: string,
    lastPill: boolean,
    position: number,
    isActive: boolean,
  ) => void;
  handleOnScroll: () => void;
  isAtEnd: boolean;
  pills: PillsType;
}

export const Pills = forwardRef<HTMLElement, PillsProps>(
  ({ handleOnPillClick, handleOnScroll, isAtEnd, pills }, ref) => {
    const isMobile =
      useResponsiveValue()({
        mobile: true,
        tablet: false,
      }) || false;

    return (
      <Box
        className={styles.hideScrollBar}
        onScroll={handleOnScroll}
        display="flex"
        overflow="scroll"
        ref={ref}
      >
        {pills.map(({ label, keywords, isActive }, index) => (
          <TooltipRenderer
            key={index}
            id={`pill-tooltip-${index}`}
            placement="bottom"
            tooltip={
              <Text>
                {isActive ? 'Remove from your search' : 'Add to your search'}
              </Text>
            }
          >
            {({ triggerProps }) => (
              <Box
                data={{ automation: `pill-${index}` }}
                {...triggerProps}
                key={index}
                display="flex"
                paddingRight={{
                  mobile:
                    isAtEnd && pills.length - 1 === index ? 'none' : 'xxsmall',
                  tablet: 'xsmall',
                }}
              >
                <Box
                  background={isActive ? 'customLight' : 'customDark'}
                  data-selected={isActive ? 'true' : 'false'}
                  className={styles.pill}
                  onClick={() =>
                    handleOnPillClick(
                      label,
                      keywords,
                      pills.length - 1 === index,
                      index,
                      isActive,
                    )
                  }
                >
                  <Box className={styles.innerTextPill}>
                    <Text
                      baseline={false}
                      maxLines={1}
                      size={isMobile ? 'small' : undefined}
                    >
                      {isMobile
                        ? truncateText({ text: label, length: 35 })
                        : label}
                    </Text>
                    <Box className={styles.pillIcon}>
                      <Text
                        baseline={false}
                        size="small"
                        icon={isActive ? <IconClear /> : <IconAdd />}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </TooltipRenderer>
        ))}
      </Box>
    );
  },
);
