import type { SearchResultJobV5 } from '@seek/chalice-types';
import { Box, Stack } from 'braid-design-system';
import type { ComponentProps } from 'react';

import { ModifyMySearchAccessabilityLink } from 'src/components/SearchResultPage/SplitView/AccessabilityLinks/AccessabilityLinks';
import { useSelector } from 'src/store/react';
import {
  selectCanonicalCompany,
  selectClearCompanyRefinements,
  selectIsRadialFilterNudgeShown,
} from 'src/store/selectors';

import CompanyResults from '../CompanyResults/CompanyResults';
import CompanySummary from '../CompanySummary/CompanySummary';
import { compareJobIds } from '../SplitView/helpers';

import { JobListItem } from './JobListItem/JobListItem';
import DistanceFromNudge from './Nudges/DistanceFromNudge/DistanceFromNudge';
import ProfileNudge from './Nudges/ProfileNudge/ProfileNudge';
import { useProfileNudge } from './Nudges/ProfileNudge/useProfileNudge';

interface SearchResultListProps
  extends Pick<ComponentProps<typeof JobListItem>, 'setCurrentJob'> {
  jobs: SearchResultJobV5[];
  isCompanySearch: boolean;
  selectedJobId?: string;
}

const getIndexOfFirstNonFeatured = (jobs: SearchResultJobV5[]): number =>
  jobs.findIndex((job) => !job.isFeatured);

const SearchResultList = ({
  jobs = [],
  setCurrentJob,
  selectedJobId,
  isCompanySearch,
}: SearchResultListProps) => {
  const { nudge: profileNudge } = useProfileNudge();
  const shouldShowProfileNudge = Boolean(profileNudge);
  const shouldShowDistanceFromNudge = useSelector(
    selectIsRadialFilterNudgeShown,
  );
  const canonicalCompany = useSelector(selectCanonicalCompany);
  const clearCompanyRefinements =
    useSelector(selectClearCompanyRefinements) || false;
  const companyName = canonicalCompany?.description;

  const profileNudgePosition = getIndexOfFirstNonFeatured(jobs) + 1;

  const jobList = jobs.map((job, index) => (
    <JobListItem
      position={index + 1}
      key={`${job.id}-${job.displayType}`}
      job={job}
      solMetadata={job.solMetadata}
      setCurrentJob={setCurrentJob}
      isSelected={compareJobIds(selectedJobId, job.id)}
    />
  ));

  const distanceFromNudgePosition = Math.floor(jobList.length / 2);

  if (shouldShowProfileNudge) {
    jobList.splice(
      profileNudgePosition,
      0,
      <ProfileNudge key="ProfileNudge" position={profileNudgePosition} />,
    );
  }

  if (shouldShowDistanceFromNudge) {
    jobList.splice(
      distanceFromNudgePosition,
      0,
      <DistanceFromNudge
        key="DistanceFromNudge"
        position={distanceFromNudgePosition}
      />,
    );
  }

  return (
    <>
      {isCompanySearch && companyName ? (
        <Box paddingBottom="medium">
          <Stack space="small">
            <CompanySummary companyName={companyName} />
            {typeof clearCompanyRefinements === 'object' ? (
              <CompanyResults
                isCompanySearch={isCompanySearch}
                clearCompanyRefinements={clearCompanyRefinements}
                jobs={jobs || []}
              />
            ) : null}
          </Stack>
        </Box>
      ) : null}

      <ModifyMySearchAccessabilityLink placement="job-list" position="top" />

      <Stack space="xsmall">{jobList}</Stack>

      <ModifyMySearchAccessabilityLink placement="job-list" position="bottom" />
    </>
  );
};

export default SearchResultList;
