import type { ApacCandidateHeader } from '@seek/apac-candidate-header-footer';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import { useMelwaysInfo } from '@seek/melways-react';
import type { Brand } from '@seek/melways-sites';
import type { ComponentProps } from 'react';

import useAuthenticated from 'src/hooks/useAuthenticated';
import { useCandidateDetails } from 'src/modules/hooks/useCandidateDetailsQuery';
import { useSetLanguage } from 'src/store/appConfig/hooks';
import { useSelector } from 'src/store/react';
import { selectHostname, selectIsJDP, selectUrl } from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import { useLanguageSwitcher } from './useLanguageSwitcher';

const resolveHeaderFooterAuthState = (
  authenticateState: ReturnType<typeof useAuthenticated>,
): ComponentProps<typeof ApacCandidateHeader>['authenticationStatus'] => {
  switch (authenticateState) {
    case 'authenticated':
      return AUTHENTICATION_TYPES.AUTHENTICATED;
    case 'unauthenticated':
      return AUTHENTICATION_TYPES.UNAUTHENTICATED;
    case 'pending':
      return AUTHENTICATION_TYPES.AUTH_PENDING;
  }
};

const useCommonProps = () => {
  const { language, brand } = useMelwaysInfo();
  const country = useMelwaysCountry();
  const authenticationStatus = useAuthenticated();
  const hostname = useSelector(selectHostname);

  return {
    // Common props
    authenticationStatus: resolveHeaderFooterAuthState(authenticationStatus),
    brand: brand as Brand,
    country,
    // Keeping the host for ANZ unified page
    hostname,
    // Assuming for non-seek site, we are displaying Asia market as unified version
    isUnified: brand !== 'seek' ? true : undefined,
    language: language || 'en',
  };
};

export const useHeaderProps = () => {
  const commonProps = useCommonProps();

  const { email, firstName } = useCandidateDetails();
  const isJDP = useSelector(selectIsJDP);
  const returnUrlForPostAuth = useSelector(selectUrl);
  const setLanguage = useSetLanguage();

  const userProfile = {
    firstName,
    lastName: '',
    email,
  };
  const onLanguageSwitch: ComponentProps<
    typeof ApacCandidateHeader
  >['onLanguageSwitch'] = (lang) => setLanguage(lang);

  const languageSwitcher = useLanguageSwitcher();

  return {
    ...commonProps,
    isEnableDivider: isJDP,
    languageSwitcher,
    onLanguageSwitch,
    returnUrlForPostAuth: returnUrlForPostAuth || '',
    userProfile,
  };
};

export const useFooterProps = () => {
  const commonProps = useCommonProps();

  return { ...commonProps, widthMode: 'large' as const };
};
