
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiUmVmaW5lIGJ5IFdvcmsgVHlwZSIsIndvcmsgdHlwZSI6IndvcmsgdHlwZSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiRmlsdGVyIGJlcmRhc2Fya2FuIFRpcGUgUGVrZXJqYWFuIiwid29yayB0eXBlIjoiamVuaXMgcGVrZXJqYWFuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiRmlsdGVyIGJlcmRhc2Fya2FuIFRpcGUgUGVrZXJqYWFuIiwid29yayB0eXBlIjoiamVuaXMgcGVrZXJqYWFuIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIiwid29yayB0eXBlIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIiwid29yayB0eXBlIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiRmlsdGVyIGJlcmRhc2Fya2FuIFRpcGUgUGVrZXJqYWFuIiwid29yayB0eXBlIjoiamVuaXMgcGVrZXJqYWFuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiRmlsdGVyIGJlcmRhc2Fya2FuIFRpcGUgUGVrZXJqYWFuIiwid29yayB0eXBlIjoiamVuaXMgcGVrZXJqYWFuIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIiwid29yayB0eXBlIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4Lii4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIiwid29yayB0eXBlIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiW8WY4bq94bq94bq9xpLDrMOsw6zguIHguLXguYnhur3hur3hur0gw5%2FDvcO9w70gxbTDtsO2w7bFmcS3IOG5rsO9w73Dvcal4bq94bq94bq9XSIsIndvcmsgdHlwZSI6IlvFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur1dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWZpbmUgYnkgV29yayBUeXBlIjoiW8WY4bq94bq94bq9xpLDrMOsw6zguIHguLXguYnhur3hur3hur0gw5%2FDvcO9w70gxbTDtsO2w7bFmcS3IOG5rsO9w73Dvcal4bq94bq94bq9XSIsIndvcmsgdHlwZSI6IlvFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur1dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    