import { Box } from 'braid-design-system';

import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';

import HoustonAd from '../../HoustonAd/HoustonAd';

import * as styles from './SkyScraperBanner.css';

/**
 * Read more about the usage of this banner in `src/components/SearchResultPage/HoustonAd/README.md`.
 */
export const SkyScraperBanner = () => {
  const showMarketingAdSplitView = useSelector(
    selectFeatureFlag('showMarketingAdSplitView'),
  );

  return showMarketingAdSplitView ? (
    <Box className={styles.towerAdSplitView}>
      <HoustonAd
        tagName="jobsearch_skyscraper"
        width={160}
        height={600}
        minWidth={styles.minimumDisplayWidth}
      />
    </Box>
  ) : null;
};
