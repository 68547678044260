import { useMelwaysInfo } from '@seek/melways-react';
import { isHomepagePath } from '@seek/seek-jobs-seo';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  Column,
  Columns,
  HiddenVisually,
  IconSearch,
  Text,
} from 'braid-design-system';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { type FormEventHandler, useCallback, useMemo } from 'react';

import { ScreenReaderSkipTarget } from 'src/components/ScreenReaderSkipTarget/ScreenReaderSkipTarget';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useMetricsTimerContext } from 'src/modules/MetricsTimer/MetricsTimerContext';
import { getClassifications } from 'src/modules/refine-job-search';
import refineClassifications from 'src/modules/refine-job-search/refinements/classificationGraph';
import { isClientMobileWidth } from 'src/modules/responsive-helpers';
import { useDispatch, useSelector } from 'src/store/react';
import { clearNewSince } from 'src/store/results';
import { searchSubmit, whereSuggestionSelected } from 'src/store/search';
import { useRunSearch } from 'src/store/search/useRunSearch';
import {
  selectFeatureFlag,
  selectPathname,
  selectSearchQuery,
  selectWhereField,
} from 'src/store/selectors';
import { useLocalisedLinks, useMelwaysZone } from 'src/utils/melwaysHelper';

import { getRefineSearchQuery } from '../utils/utils';

import translations from './.vocab';
import ClassificationsField from './ClassificationsField/ClassificationsField';
import KeywordField from './KeywordField/KeywordField';
import WhereField from './WhereField/WhereField';

import * as styles from './SearchBar.css';

interface SearchBarProps {
  collapsed: boolean;
  isSearchInView?: boolean;
  isMobileExpandedView?: boolean;
  setIsMobileExpandedView?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useSearchPath = () => {
  const currentPath = useSelector(selectPathname);
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));

  const [submitFormPath, currentSearchPath] = useLocalisedLinks([
    { path: '/jobs' },
    {
      path: isHomepagePath(currentPath) && !searchQuery ? '/' : '/jobs',
    },
  ]);

  return { submitFormPath, currentSearchPath };
};

const noop = () => {};

const SearchBar = (props: SearchBarProps) => {
  const {
    collapsed,
    isSearchInView,
    isMobileExpandedView,
    setIsMobileExpandedView = noop,
  } = props;
  const { t } = useTranslations(translations);
  const dispatch = useDispatch();
  const analyticsFacade = useAnalyticsFacade();

  const { currentSearchPath, submitFormPath } = useSearchPath();

  const whereField = useSelector(selectWhereField);
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));

  const { language: languageCode } = useMelwaysInfo();
  const zone = useMelwaysZone();
  const cachedClassifications = getClassifications(zone, languageCode);

  const currentCounts = useSelector((state) => state.search.refinements.counts);
  const showSecondaryFilters = useSelector(
    selectFeatureFlag('secondaryFilters'),
  );

  const { classification: classifications } = useMemo(
    () =>
      refineClassifications(cachedClassifications, searchQuery, currentCounts),
    [cachedClassifications, searchQuery, currentCounts],
  );

  const selectedClassifications = useMemo(
    () => classifications.filter((item) => item.isActive),
    [classifications],
  );
  const runSearch = useRunSearch();

  const immediateSearch = useCallback(
    (nextPathName: string = currentSearchPath) => {
      const action = runSearch({ pathname: nextPathName });
      setIsMobileExpandedView(false);
      dispatch(action);
    },
    [currentSearchPath, runSearch, setIsMobileExpandedView, dispatch],
  );

  const debouncedSearch = useMemo(
    () => debounce(immediateSearch, 100, { leading: true }),
    [immediateSearch],
  );

  const metrics = useMetricsTimerContext();
  const onSubmit = useCallback<FormEventHandler>(
    (event) => {
      metrics.startTimer('SearchToJobListItem:Visible');
      metrics.startTimer('SearchToJobListItem:Visible:Control');
      event.preventDefault();

      analyticsFacade.searchFormSubmitted();

      // @ts-expect-error This was the original implementation!
      dispatch(searchSubmit());

      // Lose stickiness of search when clicking on SEEK button.
      dispatch(clearNewSince());

      debouncedSearch.cancel();
      immediateSearch(submitFormPath);
      setIsMobileExpandedView(false);

      if (
        isClientMobileWidth() &&
        document.activeElement &&
        document.activeElement instanceof HTMLElement
      ) {
        // NOTE: this will trigger KeywordField.onKeywordBlur -> debounceSearch (which triggers after 1s)
        // so we need to cancel it for the scenario that we're navigating
        // from home to /jobs
        document.activeElement.blur();
        debouncedSearch.cancel();
      }
    },

    // metrics context does not need to be in the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      analyticsFacade,
      dispatch,
      debouncedSearch,
      immediateSearch,
      submitFormPath,
    ],
  );

  const onWhereSuggestionSelected = useCallback(
    (payload: Parameters<typeof whereSuggestionSelected>[0]) => {
      dispatch(whereSuggestionSelected(payload));
      debouncedSearch();
    },
    [dispatch, debouncedSearch],
  );

  const onWhereInputBlur = useCallback(() => {
    const { where = '' } = searchQuery;

    if (whereField.trim() !== where.trim()) {
      debouncedSearch();
    }
  }, [debouncedSearch, searchQuery, whereField]);

  const label = `${
    searchQuery.keywords
      ? t('{keywords} in', { keywords: searchQuery.keywords })
      : t('Jobs in')
  } ${get(selectedClassifications[0], 'label', t('All Classifications'))}`;

  return (
    <Box
      component="section"
      className={{
        [styles.searchBarRootIsCollapsed]: collapsed,
      }}
    >
      <Box
        data={{ automation: 'keywords-input-mobile' }}
        aria-label="Click to refine search"
        background="surface"
        padding="small"
        borderRadius="standard"
        display={{
          mobile: !isMobileExpandedView && collapsed ? 'block' : 'none',
          tablet: 'none',
        }}
        className={{
          [styles.maxWidthOnMobile]: true,
          [styles.animationFadeIn]: true,
        }}
        onClick={() => setIsMobileExpandedView(true)}
      >
        <Text icon={<IconSearch />}>{label}</Text>
      </Box>

      <Box
        position="relative"
        display={
          collapsed
            ? {
                mobile: isMobileExpandedView ? 'block' : 'none',
                tablet: 'block',
              }
            : undefined
        }
        className={{
          [styles.maxWidthOnMobile]: true,
          [styles.animationFadeIn]: true,
        }}
      >
        <ScreenReaderSkipTarget name="start-of-content" />

        <form
          aria-labelledby="PerformASearch"
          role="search"
          name="SearchBar"
          id="SearchBar"
          method="get"
          action="/jobs"
          onSubmit={onSubmit}
        >
          <HiddenVisually>
            <h1 id="PerformASearch">{t('Perform a job search')}</h1>
          </HiddenVisually>

          <input
            type="hidden"
            name="classification"
            value={searchQuery.classification ?? ''}
          />
          <input
            type="hidden"
            name="subclassification"
            value={searchQuery.subclassification ?? ''}
          />

          <Columns
            space={{
              mobile: 'gutter',
              tablet: 'xxsmall',
            }}
            alignY="bottom"
            collapseBelow="tablet"
          >
            <Column width="3/5">
              <Columns space="xxsmall" alignY="bottom" collapseBelow="tablet">
                <Column>
                  <KeywordField
                    onSuggestionSelected={debouncedSearch}
                    onInputBlur={debouncedSearch}
                    onClear={debouncedSearch}
                  />
                </Column>

                {!showSecondaryFilters ? (
                  <Column>
                    <ClassificationsField
                      isSearchInView={isSearchInView}
                      collapsed={collapsed}
                      classifications={classifications}
                      selectedClassifications={selectedClassifications}
                    />
                  </Column>
                ) : null}
              </Columns>
            </Column>
            <Column>
              <WhereField
                onSuggestionSelected={onWhereSuggestionSelected}
                onInputBlur={onWhereInputBlur}
                onClear={debouncedSearch}
              />
            </Column>
            <Column width="content">
              <Button
                id="searchButton"
                tone="brandAccent"
                type="submit"
                data={{
                  automation: 'searchButton',
                }}
                aria-label={t('Submit search')}
              >
                {t('SEEK')}
              </Button>
            </Column>
          </Columns>
        </form>
      </Box>
    </Box>
  );
};

export default SearchBar;
