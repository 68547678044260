import {
  Box,
  IconInfo,
  Text,
  TooltipRenderer,
  useResponsiveValue,
} from 'braid-design-system';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useSelector } from 'src/store/react';
import {
  selectIsResultsLoading,
  selectPills,
} from 'src/store/results/selectors';

import { RefineBarV2 } from '../RefineBarV2/RefineBarV2';

import { ScrollButton } from './components/ScrollButton/ScrollButton';

import * as styles from './SearchRefinementBar.css';

const isContentInView = (refCurrent: HTMLDivElement) =>
  refCurrent.scrollWidth <
    Math.floor(refCurrent.clientWidth + refCurrent.scrollLeft) ||
  refCurrent.scrollWidth === refCurrent.clientWidth;

const FILTER_PANEL_MAX_WIDTH = 400;

export const SearchRefinementBar = ({
  showStickySearchBar = false,
}: {
  showStickySearchBar?: boolean;
}) => {
  const pills = useSelector(selectPills);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [activeFieldId, setActiveFieldId] = useState<string | null>(null);
  const [panelPos, setPanelPos] = useState(0);

  const isLoading = useSelector(selectIsResultsLoading);

  const updateActiveField = useCallback(
    (fieldId: string, { left, width }: DOMRect) => {
      const panelMaxWidth = FILTER_PANEL_MAX_WIDTH;
      const viewportWidth = document.documentElement.clientWidth;
      const containerOffset = ref.current?.getBoundingClientRect().left || 0;
      const targetLeftPos = left - containerOffset;
      const exceedsViewportWidth = left + panelMaxWidth > viewportWidth;

      setPanelPos(
        exceedsViewportWidth
          ? targetLeftPos - (panelMaxWidth - width)
          : targetLeftPos,
      );

      setActiveFieldId(fieldId);
    },
    [],
  );

  const ref = useRef<HTMLDivElement>(null);

  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });

  const handleScroll = () => {
    const carouselDiv = ref.current;
    if (!carouselDiv) return;

    setActiveFieldId(null);

    const maxScrollLeft = carouselDiv.scrollWidth - carouselDiv.clientWidth;
    const newScrollLeft = carouselDiv.scrollLeft;

    setIsAtStart(newScrollLeft === 0);
    setIsAtEnd(newScrollLeft >= maxScrollLeft - 1);
  };

  const handleOnScrollButtonClick = useCallback(
    (direction: 'left' | 'right') => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();

      const carouselDiv = ref.current;
      if (carouselDiv) {
        const scrollAmount = carouselDiv.clientWidth * 0.9;
        carouselDiv.scrollBy({
          left: direction === 'left' ? -scrollAmount : scrollAmount,
          behavior: 'smooth',
        });
      }
    },
    [],
  );

  useEffect(() => {
    // Reset state when loading state
    setIsAtStart(true);

    const currentRef = ref.current;
    if (currentRef) {
      setIsAtEnd(isContentInView(currentRef));
    } else {
      setIsAtEnd(false);
    }
  }, [isLoading]);

  useEffect(() => {
    const carouselDiv = ref.current;
    if (carouselDiv) {
      carouselDiv.addEventListener('scroll', handleScroll);
      return () => {
        carouselDiv.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const isInView = ref?.current ? isContentInView(ref.current) : false;

  return (
    <Box
      data={{ automation: 'carousel' }}
      position="relative"
      alignItems="center"
      width="full"
    >
      {!isMobile && (
        <ScrollButton
          direction="left"
          label="Scroll left"
          handleOnClick={handleOnScrollButtonClick('left')}
          display={!isAtStart ? 'flex' : 'none'}
        />
      )}

      <Box
        className={{
          [styles.carouselContainer]: true,
          [styles.hideScrollBar]: true,
        }}
        display="flex"
        ref={ref}
        data-automation="searchRefinementCarousel"
      >
        <RefineBarV2
          expanded={true}
          showStickySearchBar={showStickySearchBar} // TODO Is this behaviour still required?
          isMobileExpandedView={false}
          activeFieldId={activeFieldId}
          setActiveFieldId={setActiveFieldId}
          updateActiveField={updateActiveField}
          panelPos={panelPos}
        />

        {!isLoading && pills?.length ? (
          <Box
            position={!isInView ? 'absolute' : undefined}
            style={{
              right: !isInView ? -36 : undefined,
            }}
            data-automation="pillsTooltip"
            display={{
              mobile: 'none',
              tablet: 'flex',
            }}
            top={0}
            bottom={0}
            alignItems="center"
            marginLeft="xsmall"
          >
            <TooltipRenderer
              id="pills-tooltip-renderer"
              tooltip={
                <Text>Add these options to your search for better results</Text>
              }
              placement="bottom"
            >
              {({ triggerProps }) => (
                <Box aria-label="Info" {...triggerProps}>
                  <IconInfo />
                </Box>
              )}
            </TooltipRenderer>
          </Box>
        ) : null}
      </Box>

      {!isMobile && (
        <ScrollButton
          direction="right"
          label="Scroll right"
          handleOnClick={handleOnScrollButtonClick('right')}
          display={!isAtEnd ? 'flex' : 'none'}
        />
      )}
    </Box>
  );
};
