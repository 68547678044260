import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { Zone } from '@seek/audience-zones';
import type { JobTag } from '@seek/chalice-types';
import type { Hubble } from '@seek/hubble';
import type { ConcreteEvent, FilterByName } from '@seek/hubble/lib/HubbleEvent';
import type { Analytics } from '@seek/seek-jobs-analytics';
import type { getSolWrapper } from '@seek/sol-js';
import { v4 as uuid } from 'uuid';

import impressionTracker from 'src/modules/job-tracking';
import type { TSeekExperiments } from 'src/store/experiments/types';
import type { Country } from 'src/types/globals';

import {
  TRACK_JOB_DETAILED_VIEWED,
  TRACK_SEARCH_RESULT_SELECTED,
} from '../graphql/mutations';

import { instrumentAnalytics } from './instrumentAnalytics';

type HubbleType = InstanceType<typeof Hubble>;
type HubbleEventPayload<T> = FilterByName<ConcreteEvent, T>['data'];
type SolDisplayEventParams = Parameters<
  ReturnType<typeof getSolWrapper>['trackDisplayEvent']
>;

export type JobBadgesFilter =
  HubbleEventPayload<'search_results_displayed'>['jobBadgesFilter'];

export type NudgeType = Parameters<Analytics['nudgePressed']>[0]['nudgeType'];
export type NudgeCTAProps = Pick<
  Parameters<Analytics['nudgePressed']>[0],
  'href' | 'linkText'
>;

export type GoogleOneTapActionOrigin = Parameters<
  Analytics['googleOneTapPressed']
>[0]['actionOrigin'];

// https://stackoverflow.com/questions/53674968/restrict-typescript-class-methods-implementing-interface#answer-53675287
type Exactly<T, U> = { [K in keyof U]: K extends keyof T ? T[K] : never };

export interface IAnalyticsFacade {
  jobCardAccordionLinkClicked(
    ...args: Parameters<Analytics['jobCardAccordionLinkClicked']>
  ): void;

  companyProfileWidgetAccordionLinkClicked(
    ...args: Parameters<Analytics['companyProfileWidgetAccordionLinkClicked']>
  ): void;

  companyProfileWidgetImpression(): void;

  refinementSuggestionClicked(
    hubblePayload: HubbleEventPayload<'refinement_suggestion_pressed'>,
  ): void;

  companyProfileWidgetLinkClicked(
    ...args: Parameters<Analytics['companyProfileWidgetLinkClicked']>
  ): void;

  companyProfileWidgetReviewsLinkClicked(
    ...args: Parameters<Analytics['companyProfileWidgetReviewsLinkClicked']>
  ): void;

  companySuggestionLinkPressed(
    ...args: Parameters<Analytics['companySuggestionLinkPressed']>
  ): void;

  contactMatchClicked(
    ...args: Parameters<Analytics['contactMatchClicked']>
  ): void;

  createSaveSearchClicked(
    ...args: Parameters<Analytics['createSaveSearchClicked']>
  ): void;

  createSaveSearchError(
    ...args: Parameters<Analytics['createSaveSearchError']>
  ): void;

  createUnregisteredSaveSearchClicked(
    ...args: Parameters<Analytics['createUnregisteredSaveSearchClicked']>
  ): void;

  createUnregisteredSaveSearchError(
    ...args: Parameters<Analytics['createUnregisteredSaveSearchError']>
  ): void;

  googleOneTapPressed(
    ...args: Parameters<Analytics['googleOneTapPressed']>
  ): void;

  homePageLoaded(...args: Parameters<Analytics['homePageLoaded']>): void;

  jobApplyClicked(...args: any[]): void;

  jobDetailsFetched(...args: Parameters<Analytics['jobDetailsFetched']>): void;

  jobDetailsLoaded(...args: any[]): void;

  keywordChanged(...args: Parameters<Analytics['keywordChanged']>): void;

  linkClicked(...args: Parameters<Analytics['linkClicked']>): void;

  lmisClicked(...args: Parameters<Analytics['lmisClicked']>): void;

  lmisImpression(...args: Parameters<Analytics['lmisImpression']>): void;

  countryChanged(country: Country): void;

  locationChanged(): void;

  loggedOutRecsImpression(
    ...args: Parameters<Analytics['loggedOutRecsImpression']>
  ): void;

  moreOptionsClicked(
    ...args: Parameters<Analytics['moreOptionsClicked']>
  ): void;

  navigationPressed(...args: Parameters<Analytics['navigationPressed']>): void;

  nudgeDismissed(...args: Parameters<Analytics['nudgeDismissed']>): void;

  nudgePressed(...args: Parameters<Analytics['nudgePressed']>): void;

  nudgeViewed(...args: Parameters<Analytics['nudgeViewed']>): void;

  paginationPressed(...args: Parameters<Analytics['paginationPressed']>): void;

  recommendedJobsImpression(...args: any[]): void;

  recentSearchClicked(
    ...args: Parameters<Analytics['recentSearchClicked']>
  ): void;

  recentSearchesImpression(
    ...args: Parameters<Analytics['recentSearchesImpression']>
  ): void;

  registerPressed(...args: Parameters<Analytics['registerPressed']>): void;

  registerExperiments(payload: TSeekExperiments): void;

  relatedSearchPressed(...args: any[]): void;

  reportJobClicked(): void;

  salaryBadgeClicked(
    ...args: Parameters<Analytics['salaryBadgeClicked']>
  ): void;

  saveJobClicked(...args: Parameters<Analytics['saveJobClicked']>): void;

  saveJobCreated(...args: any[]): void;

  saveJobRemoved(...args: any[]): void;

  searchBySuburbClicked(
    ...args: Parameters<Analytics['searchBySuburbClicked']>
  ): void;

  saveSearchFormDisclaimerLinkClicked(
    ...args: Parameters<Analytics['saveSearchFormDisclaimerLinkClicked']>
  ): void;

  searchFormSubmitted(
    ...args: Parameters<Analytics['searchFormSubmitted']>
  ): void;

  searchHelpPressed(): void;

  searchPerformed(...args: Parameters<Analytics['searchPerformed']>): void;

  searchResultsPageLoaded(...args: any[]): void;

  shareLinkClicked(...args: Parameters<Analytics['shareLinkClicked']>): void;

  signInPressed(...args: Parameters<Analytics['signInPressed']>): void;

  sourcrClicked(): void;

  sourcrImpression(): void;

  sortModeChanged(payload: Parameters<Analytics['sortModeChanged']>[0]): void;

  trackNudgeImpression(): void;

  userDetailsUpdated(
    payload: Parameters<Analytics['userDetailsUpdated']>[0],
  ): void;

  branchBannerCTAPressed(
    ...payload: Parameters<Analytics['branchBannerCTAPressed']>
  ): void;
  branchBannerDismissPressed(
    ...payload: Parameters<Analytics['branchBannerCTAPressed']>
  ): void;
  branchBannerImpression(
    ...payload: Parameters<Analytics['branchBannerCTAPressed']>
  ): void;

  homepageMarketingBannerClicked(
    ...payload: Parameters<Analytics['homepageMarketingBannerClicked']>
  ): void;

  verifiedHirerBadgeClicked(
    ...payload: Parameters<Analytics['verifiedHirerBadgeClicked']>
  ): void;

  skillsMatchRegisterPressed(
    ...payload: Parameters<Analytics['skillsMatchRegisterPressed']>
  ): void;

  skillsMatchSignInPressed(
    ...payload: Parameters<Analytics['skillsMatchSignInPressed']>
  ): void;

  skillsMatchFeedbackPressed(
    ...payload: Parameters<Analytics['skillsMatchFeedbackPressed']>
  ): void;

  skillsMatchProfileUpdated(
    ...payload: Parameters<Analytics['skillsMatchProfileUpdated']>
  ): void;

  skillsMatchLoadSucceeded(
    payload: Parameters<Analytics['skillsMatchLoadSucceeded']>[0],
    hubblePayload: HubbleEventPayload<'matched_skills_load_succeeded'>,
  ): void;

  skillsMatchLoadFailed(
    ...payload: Parameters<Analytics['skillsMatchLoadFailed']>
  ): void;

  skillsMatchAlert(...payload: Parameters<Analytics['skillsMatchAlert']>): void;

  skillsMatchAccordionPressed(
    ...payload: Parameters<Analytics['skillsMatchAccordionPressed']>
  ): void;

  poeaLinksPressed(...args: Parameters<Analytics['poeaLinksPressed']>): void;

  poeaCountryPickerPageLoaded(
    ...args: Parameters<Analytics['poeaCountryPickerPageLoaded']>
  ): void;

  setExperimentEligible(
    ...args: Parameters<Analytics['experimentsEligible']>
  ): void;
}

export class UniversalAnalyticsFacade
  // Implement the Interface in this way, we can prevent un-allowed methods creation, and force the class to create new method that listed in the interface.
  implements
    Exactly<IAnalyticsFacade, UniversalAnalyticsFacade>,
    IAnalyticsFacade
{
  protected seekJobsAnalytics: Analytics;

  constructor(options: { seekJobsAnalytics: Analytics }) {
    this.seekJobsAnalytics = options.seekJobsAnalytics;
    instrumentAnalytics(this.seekJobsAnalytics);
  }

  refinementSuggestionClicked(
    _: HubbleEventPayload<'refinement_suggestion_pressed'>,
  ): void {
    throw new Error('Method not implemented.');
  }

  public jobCardAccordionLinkClicked(
    ...args: Parameters<Analytics['jobCardAccordionLinkClicked']>
  ) {
    this.seekJobsAnalytics.jobCardAccordionLinkClicked(...args);
  }

  public companyProfileWidgetAccordionLinkClicked(
    ...args: Parameters<Analytics['companyProfileWidgetAccordionLinkClicked']>
  ) {
    this.seekJobsAnalytics.companyProfileWidgetAccordionLinkClicked(...args);
  }

  public companyProfileWidgetImpression() {
    this.seekJobsAnalytics.companyProfileWidgetImpression();
  }

  public companyProfileWidgetLinkClicked(
    ...args: Parameters<Analytics['companyProfileWidgetLinkClicked']>
  ) {
    this.seekJobsAnalytics.companyProfileWidgetLinkClicked(...args);
  }

  public companyProfileWidgetReviewsLinkClicked(
    ...args: Parameters<Analytics['companyProfileWidgetReviewsLinkClicked']>
  ) {
    this.seekJobsAnalytics.companyProfileWidgetReviewsLinkClicked(...args);
  }

  public companySuggestionLinkPressed(
    ...args: Parameters<Analytics['companySuggestionLinkPressed']>
  ): void {
    this.seekJobsAnalytics.companySuggestionLinkPressed(...args);
  }

  public contactMatchClicked(
    ...args: Parameters<Analytics['contactMatchClicked']>
  ) {
    this.seekJobsAnalytics.contactMatchClicked(...args);
  }

  public createSaveSearchClicked(
    ...args: Parameters<Analytics['createSaveSearchClicked']>
  ) {
    this.seekJobsAnalytics.createSaveSearchClicked(...args);
  }

  public createSaveSearchError(
    ...args: Parameters<Analytics['createSaveSearchError']>
  ) {
    this.seekJobsAnalytics.createSaveSearchError(...args);
  }

  public createUnregisteredSaveSearchClicked(
    ...args: Parameters<Analytics['createUnregisteredSaveSearchClicked']>
  ) {
    this.seekJobsAnalytics.createUnregisteredSaveSearchClicked(...args);
  }

  public createUnregisteredSaveSearchError(
    ...args: Parameters<Analytics['createUnregisteredSaveSearchError']>
  ) {
    this.seekJobsAnalytics.createUnregisteredSaveSearchError(...args);
  }

  public googleOneTapPressed(
    ...args: Parameters<Analytics['googleOneTapPressed']>
  ) {
    this.seekJobsAnalytics.googleOneTapPressed(...args);
  }

  public homePageLoaded(...args: Parameters<Analytics['homePageLoaded']>) {
    this.seekJobsAnalytics.homePageLoaded(...args);
  }

  public jobApplyClicked({
    href,
    jobBadges,
    experimentFlightId,
    jobViewOriginQuery,
    trackingCorrelationId,
    isHirerVerified,
    isMatchedSkillsWidgetShown,
    isMatchedSkillsNudgeShown,
    jobBadgesFilter,
    jobCardSellPointCount,
  }: {
    href: string;
    jobBadges?: string[];
    experimentFlightId: string;
    jobViewOriginQuery?: string;
    trackingCorrelationId?: string;
    isHirerVerified: boolean;
    isMatchedSkillsWidgetShown?: boolean;
    isMatchedSkillsNudgeShown?: boolean;
    jobBadgesFilter?: string[];
    jobCardSellPointCount?: number;
  }) {
    this.seekJobsAnalytics.jobApplyClicked({
      href,
      jobBadges,
      experimentFlightId,
      jobViewOriginQuery,
      trackingCorrelationId,
      isHirerVerified,
      isMatchedSkillsWidgetShown,
      isMatchedSkillsNudgeShown,
      jobBadgesFilter,
      jobCardSellPointCount,
    });
  }

  public searchHelpPressed() {
    this.seekJobsAnalytics.searchHelpPressed();
  }

  public jobDetailsFetched(
    ...args: Parameters<Analytics['jobDetailsFetched']>
  ) {
    this.seekJobsAnalytics.jobDetailsFetched(...args);
  }

  public jobDetailsLoaded(
    payload: Parameters<Analytics['jobDetailsPageLoaded']>[0],
  ) {
    this.seekJobsAnalytics.jobDetailsPageLoaded(payload);
  }

  public keywordChanged(...args: Parameters<Analytics['keywordChanged']>) {
    this.seekJobsAnalytics.keywordChanged(...args);
  }

  public linkClicked(...args: Parameters<Analytics['linkClicked']>) {
    this.seekJobsAnalytics.linkClicked(...args);
  }

  public lmisImpression(...args: Parameters<Analytics['lmisImpression']>) {
    this.seekJobsAnalytics.lmisImpression(...args);
  }

  public lmisClicked(...args: Parameters<Analytics['lmisClicked']>) {
    this.seekJobsAnalytics.lmisClicked(...args);
  }

  public countryChanged(country: Country) {
    this.seekJobsAnalytics.countryChanged(country);
  }

  public locationChanged() {
    this.seekJobsAnalytics.locationChanged();
  }

  public loggedOutRecsImpression(
    ...args: Parameters<Analytics['loggedOutRecsImpression']>
  ) {
    this.seekJobsAnalytics.loggedOutRecsImpression(...args);
  }

  public moreOptionsClicked(
    ...args: Parameters<Analytics['moreOptionsClicked']>
  ) {
    this.seekJobsAnalytics.moreOptionsClicked(...args);
  }

  public navigationPressed(
    ...args: Parameters<Analytics['navigationPressed']>
  ) {
    this.seekJobsAnalytics.navigationPressed(...args);
  }

  public nudgeDismissed(
    ...args: Parameters<Analytics['nudgeDismissed']>
  ): void {
    this.seekJobsAnalytics.nudgeDismissed(...args);
  }

  public nudgePressed(...args: Parameters<Analytics['nudgePressed']>): void {
    this.seekJobsAnalytics.nudgePressed(...args);
  }

  public nudgeViewed(...args: Parameters<Analytics['nudgeViewed']>) {
    this.seekJobsAnalytics.nudgeViewed(...args);
  }

  public paginationPressed(
    ...args: Parameters<Analytics['paginationPressed']>
  ) {
    this.seekJobsAnalytics.paginationPressed(...args);
  }

  public recentSearchClicked(
    ...args: Parameters<Analytics['recentSearchClicked']>
  ) {
    this.seekJobsAnalytics.recentSearchClicked(...args);
  }

  public recentSearchesImpression(
    ...args: Parameters<Analytics['recentSearchesImpression']>
  ) {
    this.seekJobsAnalytics.recentSearchesImpression(...args);
  }

  public recommendedJobsImpression() {}

  public registerPressed(...args: Parameters<Analytics['registerPressed']>) {
    this.seekJobsAnalytics.registerPressed(...args);
  }

  public registerExperiments(payload: TSeekExperiments) {
    const formattedExperiments = Object.values(payload).map((experiment) => ({
      id: experiment.name,
      variant: experiment.variation.index,
      num: experiment.num,
    }));
    this.seekJobsAnalytics.experiments(formattedExperiments);

    const formattedHubbleExperiments = Object.values(payload).reduce(
      (
        hubbleExperiments: Parameters<Analytics['hubbleExperiments']>[0],
        experiment,
      ) => {
        hubbleExperiments[experiment.name] = {
          id: experiment.name,
          name: experiment.name,
          participantStatus: 'enrolled',
          variant: experiment.variation.index,
        };
        return hubbleExperiments;
      },
      {},
    );
    this.seekJobsAnalytics.hubbleExperiments(formattedHubbleExperiments);
  }

  public relatedSearchPressed(
    ...args: Parameters<Analytics['relatedSearchPressed']>
  ) {
    this.seekJobsAnalytics.relatedSearchPressed(...args);
  }

  public reportJobClicked() {
    this.seekJobsAnalytics.reportJobClicked();
  }

  public salaryBadgeClicked() {
    this.seekJobsAnalytics.salaryBadgeClicked();
  }

  public saveJobClicked(...args: Parameters<Analytics['saveJobClicked']>) {
    this.seekJobsAnalytics.saveJobClicked(...args);
  }

  public saveJobCreated(...args: Parameters<Analytics['savedJobCreated']>) {
    this.seekJobsAnalytics.savedJobCreated(...args);
  }

  public saveJobRemoved(...args: Parameters<Analytics['savedJobRemoved']>) {
    this.seekJobsAnalytics.savedJobRemoved(...args);
  }

  public searchBySuburbClicked(
    ...args: Parameters<Analytics['searchBySuburbClicked']>
  ) {
    this.seekJobsAnalytics.searchBySuburbClicked(...args);
  }

  public saveSearchFormDisclaimerLinkClicked(
    ...args: Parameters<Analytics['saveSearchFormDisclaimerLinkClicked']>
  ) {
    this.seekJobsAnalytics.saveSearchFormDisclaimerLinkClicked(...args);
  }

  public searchFormSubmitted(
    ...args: Parameters<Analytics['searchFormSubmitted']>
  ): void {
    this.seekJobsAnalytics.searchFormSubmitted(...args);
  }

  public searchPerformed(...args: Parameters<Analytics['searchPerformed']>) {
    this.seekJobsAnalytics.searchPerformed(...args);
  }

  public searchResultsPageLoaded(
    ...args: Parameters<Analytics['searchResultsPageLoaded']>
  ) {
    this.seekJobsAnalytics.searchResultsPageLoaded(...args);
  }

  public shareLinkClicked(...args: Parameters<Analytics['shareLinkClicked']>) {
    this.seekJobsAnalytics.shareLinkClicked(...args);
  }

  public signInPressed(...args: Parameters<Analytics['signInPressed']>): void {
    this.seekJobsAnalytics.signInPressed(...args);
  }

  public sourcrClicked() {
    this.seekJobsAnalytics.sourcrClicked();
  }

  public sourcrImpression() {
    this.seekJobsAnalytics.sourcrImpression();
  }

  public sortModeChanged(payload: Parameters<Analytics['sortModeChanged']>[0]) {
    this.seekJobsAnalytics.sortModeChanged(payload);
  }

  public trackNudgeImpression() {}

  public userDetailsUpdated(
    payload: Parameters<Analytics['userDetailsUpdated']>[0],
  ) {
    this.seekJobsAnalytics.userDetailsUpdated(payload);
  }

  public branchBannerCTAPressed(
    ...payload: Parameters<Analytics['branchBannerCTAPressed']>
  ) {
    this.seekJobsAnalytics.branchBannerCTAPressed(...payload);
  }

  public branchBannerDismissPressed(
    ...payload: Parameters<Analytics['branchBannerDismissPressed']>
  ) {
    this.seekJobsAnalytics.branchBannerDismissPressed(...payload);
  }

  public branchBannerImpression(
    ...payload: Parameters<Analytics['branchBannerImpression']>
  ) {
    this.seekJobsAnalytics.branchBannerImpression(...payload);
  }

  public homepageMarketingBannerClicked(
    ...payload: Parameters<Analytics['homepageMarketingBannerClicked']>
  ) {
    this.seekJobsAnalytics.homepageMarketingBannerClicked(...payload);
  }

  public verifiedHirerBadgeClicked(
    ...args: Parameters<Analytics['verifiedHirerBadgeClicked']>
  ) {
    this.seekJobsAnalytics.verifiedHirerBadgeClicked(...args);
  }

  public skillsMatchRegisterPressed(
    ...args: Parameters<Analytics['skillsMatchRegisterPressed']>
  ) {
    this.seekJobsAnalytics.skillsMatchRegisterPressed(...args);
  }

  public skillsMatchSignInPressed(
    ...args: Parameters<Analytics['skillsMatchSignInPressed']>
  ): void {
    this.seekJobsAnalytics.skillsMatchSignInPressed(...args);
  }

  public skillsMatchFeedbackPressed(
    ...args: Parameters<Analytics['skillsMatchFeedbackPressed']>
  ): void {
    this.seekJobsAnalytics.skillsMatchFeedbackPressed(...args);
  }

  public skillsMatchProfileUpdated(
    ...args: Parameters<Analytics['skillsMatchProfileUpdated']>
  ): void {
    this.seekJobsAnalytics.skillsMatchProfileUpdated(...args);
  }

  public skillsMatchLoadSucceeded(
    payload: Parameters<Analytics['skillsMatchLoadSucceeded']>[0],
  ): void {
    this.seekJobsAnalytics.skillsMatchLoadSucceeded(payload);
  }

  public skillsMatchLoadFailed(
    ...args: Parameters<Analytics['skillsMatchLoadFailed']>
  ): void {
    this.seekJobsAnalytics.skillsMatchLoadFailed(...args);
  }

  public skillsMatchAlert(
    ...args: Parameters<Analytics['skillsMatchAlert']>
  ): void {
    this.seekJobsAnalytics.skillsMatchAlert(...args);
  }

  public skillsMatchAccordionPressed(
    ...args: Parameters<Analytics['skillsMatchAccordionPressed']>
  ): void {
    this.seekJobsAnalytics.skillsMatchAccordionPressed(...args);
  }

  public poeaLinksPressed(...args: Parameters<Analytics['poeaLinksPressed']>) {
    this.seekJobsAnalytics.poeaLinksPressed(...args);
  }

  public poeaCountryPickerPageLoaded(
    ...args: Parameters<Analytics['poeaCountryPickerPageLoaded']>
  ) {
    this.seekJobsAnalytics.poeaCountryPickerPageLoaded(...args);
  }

  public setExperimentEligible(
    ...args: Parameters<Analytics['experimentsEligible']>
  ): void {
    this.seekJobsAnalytics.experimentsEligible(...args);
  }
}

interface RefinedKeyword {
  isActive: boolean;
  label: string;
  position: number;
  [k: string]: unknown;
}
interface HubbleDataLayer {
  autosuggestRequestToken?: string;
  refinedKeywords: RefinedKeyword[];
}

export class BrowserAnalyticsFacade
  // Implement the Interface in this way, we can prevent un-allowed methods creation, and force the class to create new method that listed in the interface.
  implements
    Exactly<IAnalyticsFacade, BrowserAnalyticsFacade>,
    IAnalyticsFacade
{
  protected universalFacade: InstanceType<typeof UniversalAnalyticsFacade>;
  protected hubble: HubbleType;
  protected dataLayer: HubbleDataLayer;

  constructor(options: {
    hubble: HubbleType;
    universalFacade: InstanceType<typeof UniversalAnalyticsFacade>;
  }) {
    this.universalFacade = options.universalFacade;
    this.hubble = options.hubble;
    this.dataLayer = {
      refinedKeywords: [],
    };
  }

  public jobCardAccordionLinkClicked(
    ...args: Parameters<Analytics['jobCardAccordionLinkClicked']>
  ) {
    this.universalFacade.jobCardAccordionLinkClicked(...args);
  }

  public companyProfileWidgetAccordionLinkClicked(
    ...args: Parameters<Analytics['companyProfileWidgetAccordionLinkClicked']>
  ) {
    this.universalFacade.companyProfileWidgetAccordionLinkClicked(...args);
  }

  public companyProfileWidgetImpression() {
    this.universalFacade.companyProfileWidgetImpression();
  }

  public companyProfileWidgetLinkClicked(
    ...args: Parameters<Analytics['companyProfileWidgetLinkClicked']>
  ): void {
    this.universalFacade.companyProfileWidgetLinkClicked(...args);
  }

  public companyProfileWidgetReviewsLinkClicked(
    ...args: Parameters<Analytics['companyProfileWidgetReviewsLinkClicked']>
  ) {
    this.universalFacade.companyProfileWidgetReviewsLinkClicked(...args);
  }

  public searchHelpPressed(
    ...args: Parameters<Analytics['searchHelpPressed']>
  ) {
    this.universalFacade.searchHelpPressed(...args);
  }

  public companySuggestionLinkPressed(
    ...args: Parameters<Analytics['companySuggestionLinkPressed']>
  ): void {
    this.universalFacade.companySuggestionLinkPressed(...args);
  }

  public contactMatchClicked(
    ...args: Parameters<Analytics['contactMatchClicked']>
  ) {
    this.universalFacade.contactMatchClicked(...args);
  }

  public createSaveSearchClicked(
    ...args: Parameters<Analytics['createSaveSearchClicked']>
  ) {
    this.universalFacade.createSaveSearchClicked(...args);
  }

  public createSaveSearchError(
    ...args: Parameters<Analytics['createSaveSearchError']>
  ) {
    this.universalFacade.createSaveSearchError(...args);
  }

  public createUnregisteredSaveSearchClicked(
    ...args: Parameters<Analytics['createUnregisteredSaveSearchClicked']>
  ) {
    this.universalFacade.createUnregisteredSaveSearchClicked(...args);
  }

  public createUnregisteredSaveSearchError(
    ...args: Parameters<Analytics['createUnregisteredSaveSearchError']>
  ) {
    this.universalFacade.createUnregisteredSaveSearchError(...args);
  }

  public googleOneTapPressed(
    ...args: Parameters<Analytics['googleOneTapPressed']>
  ) {
    this.universalFacade.googleOneTapPressed(...args);
  }

  public homePageLoaded(...args: Parameters<Analytics['homePageLoaded']>) {
    this.universalFacade.homePageLoaded(...args);
  }

  public jobApplyClicked(
    args: {
      href: string;
      jobTags?: JobTag[];
      experimentFlightId: string;
      jobViewOriginQuery?: string;
      isHirerVerified: boolean;
      isMatchedSkillsWidgetShown?: boolean;
      isMatchedSkillsNudgeShown?: boolean;
      jobBadgesFilter?: string[];
      jobCardSellPointCount?: number;
    },
    hubblePayload: HubbleEventPayload<'job_apply_pressed'>,
  ) {
    const trackingCorrelationId = uuid();
    this.hubble.trackEvent(
      'job_apply_pressed',
      {
        ...hubblePayload,
      },
      {
        trackingCorrelationId,
      },
    );
    this.universalFacade.jobApplyClicked({
      ...args,
      trackingCorrelationId,
    });
  }

  public jobDetailsFetched(
    ...args: Parameters<Analytics['jobDetailsFetched']>
  ) {
    this.universalFacade.jobDetailsFetched(...args);
  }

  public jobDetailsLoaded(
    universalPayload: Parameters<Analytics['jobDetailsPageLoaded']>[0],
    hubblePayload: HubbleEventPayload<'job_details_displayed'> &
      // FIXME WHY are these fields optional?
      Required<
        Pick<HubbleEventPayload<'job_details_displayed'>, 'solMetadata'>
      >,
    eventCapturePayload: {
      zone: Zone;
      userQueryId?: string;
      omnitureTracking?: string;
      candidateId?: number;
      jobDetailsViewedCorrelationId?: string;
      tags: {
        testRecord?: boolean;
        testScope?: string;
      };
      isFeatured?: boolean;
    },
    apolloClient: ApolloClient<NormalizedCacheObject>,
    sessionId: string,
  ) {
    const trackingCorrelationId = uuid();

    this.universalFacade.jobDetailsLoaded({
      ...universalPayload,
      trackingCorrelationId,
    });

    this.hubble.trackEvent('job_details_displayed', hubblePayload, {
      trackingCorrelationId,
    });

    if (sessionId) {
      const jobId = parseInt(hubblePayload.jobId, 10);
      const adDisplayType = universalPayload.jobListingType;
      const {
        userQueryId,
        omnitureTracking,
        candidateId,
        jobDetailsViewedCorrelationId,
        tags,
        isFeatured,
      } = eventCapturePayload;

      apolloClient.mutate({
        mutation: TRACK_JOB_DETAILED_VIEWED,
        variables: {
          input: {
            jobseekerSessionId: sessionId,
            jobId,
            omnitureTracking,
            candidateId,
            jobDetailsViewedCorrelationId,
            tags,
          },
        },
      });

      if (adDisplayType) {
        apolloClient.mutate({
          mutation: TRACK_SEARCH_RESULT_SELECTED,
          variables: {
            input: {
              jobseekerSessionId: sessionId,
              jobId,
              adDisplayType: 'unknown',
              isFeatured,
              userQueryId,
              testTags: tags,
            },
          },
        });
      }
    }
  }

  public keywordChanged(...args: Parameters<Analytics['keywordChanged']>) {
    this.universalFacade.keywordChanged(...args);

    const keyword = args[0];
    const isSuggestion = args[1];
    const keywordRank = args[4];
    const suggestionMetadata = args[5];

    if (isSuggestion && keywordRank !== undefined) {
      this.dataLayer.autosuggestRequestToken = suggestionMetadata?.requestToken;

      this.hubble.trackEvent('keyword_autosuggest_pressed', {
        value: keyword,
        rank: keywordRank,
        keywordAutocompleteRequestToken: this.dataLayer.autosuggestRequestToken,
      });
    }
  }

  public linkClicked(...args: Parameters<Analytics['linkClicked']>) {
    this.universalFacade.linkClicked(...args);
  }

  public lmisImpression(...args: Parameters<Analytics['lmisImpression']>) {
    this.universalFacade.lmisImpression(...args);
  }

  public lmisClicked(...args: Parameters<Analytics['lmisClicked']>) {
    this.universalFacade.lmisClicked(...args);
  }

  public countryChanged(country: Country) {
    this.universalFacade.countryChanged(country);
  }

  public locationChanged() {
    this.universalFacade.locationChanged();
  }

  public loggedOutRecsImpression(
    ...args: Parameters<Analytics['loggedOutRecsImpression']>
  ) {
    this.universalFacade.loggedOutRecsImpression(...args);
  }

  public moreOptionsClicked(
    ...args: Parameters<Analytics['moreOptionsClicked']>
  ) {
    this.universalFacade.moreOptionsClicked(...args);
  }

  public navigationPressed(
    ...args: Parameters<Analytics['navigationPressed']>
  ) {
    this.universalFacade.navigationPressed(...args);
  }

  public nudgeDismissed(
    ...args: Parameters<Analytics['nudgeDismissed']>
  ): void {
    this.universalFacade.nudgeDismissed(...args);
  }

  public nudgePressed(...args: Parameters<Analytics['nudgePressed']>): void {
    this.universalFacade.nudgePressed(...args);
  }

  public nudgeViewed(...args: Parameters<Analytics['nudgeViewed']>) {
    this.universalFacade.nudgeViewed(...args);
  }

  public paginationPressed(
    ...args: Parameters<Analytics['paginationPressed']>
  ) {
    this.universalFacade.paginationPressed(...args);
  }

  public registerExperiments(payload: TSeekExperiments) {
    this.universalFacade.registerExperiments(payload);
  }

  public recentSearchClicked(
    ...args: Parameters<Analytics['recentSearchClicked']>
  ) {
    this.universalFacade.recentSearchClicked(...args);
  }

  public recentSearchesImpression(
    ...args: Parameters<Analytics['recentSearchesImpression']>
  ) {
    this.universalFacade.recentSearchesImpression(...args);
  }

  public recommendedJobsImpression(
    ...args: [
      HubbleEventPayload<'recommended_jobs_displayed'> & {
        solMetadata: SolDisplayEventParams[0];
      },
    ]
  ) {
    const [hubblePayload] = args;
    this.hubble.trackEvent('recommended_jobs_displayed', hubblePayload);
    this.universalFacade.recommendedJobsImpression();
  }

  public relatedSearchPressed(
    hubblePayload: HubbleEventPayload<'related_search_pressed'>,
  ): void {
    const { value: linkText, rank: linkPosition } = hubblePayload;
    this.hubble.trackEvent('related_search_pressed', hubblePayload);
    this.universalFacade.relatedSearchPressed({
      linkContext: {
        linkText,
        linkPosition: String(linkPosition),
      },
    });
  }

  public registerPressed(
    ...args: Parameters<Analytics['registerPressed']>
  ): void {
    this.universalFacade.registerPressed(...args);
  }

  public reportJobClicked() {
    this.universalFacade.reportJobClicked();
  }

  public salaryBadgeClicked() {
    this.universalFacade.salaryBadgeClicked();
  }

  public searchBySuburbClicked(
    ...args: Parameters<Analytics['searchBySuburbClicked']>
  ) {
    this.universalFacade.searchBySuburbClicked(...args);
  }

  public searchFormSubmitted(
    ...args: Parameters<Analytics['searchFormSubmitted']>
  ): void {
    this.universalFacade.searchFormSubmitted(...args);
  }

  public saveJobClicked(...args: Parameters<Analytics['saveJobClicked']>) {
    this.universalFacade.saveJobClicked(...args);
  }

  public saveJobCreated(
    ...args: [
      Omit<HubbleEventPayload<'job_save_pressed'>, 'jobId'>,
      ...Parameters<Analytics['savedJobCreated']>,
    ]
  ) {
    const trackingCorrelationId = uuid();
    const [hubblePayload, ...universalPayloadArgs] = args;
    this.universalFacade.saveJobCreated({
      ...universalPayloadArgs[0],
      trackingCorrelationId,
    });
    this.hubble.trackEvent(
      'job_save_pressed',
      {
        ...hubblePayload,
        jobId: universalPayloadArgs[0].jobId,
      },
      { trackingCorrelationId },
    );
  }

  public saveJobRemoved(
    ...args: [
      Omit<HubbleEventPayload<'job_unsave_pressed'>, 'jobId'>,
      ...Parameters<Analytics['savedJobRemoved']>,
    ]
  ) {
    const [hubblePayload, ...universalPayloadArgs] = args;
    const trackingCorrelationId = uuid();
    this.universalFacade.saveJobRemoved({
      ...universalPayloadArgs[0],
      trackingCorrelationId,
    });
    this.hubble.trackEvent(
      'job_unsave_pressed',
      {
        ...hubblePayload,
        jobId: universalPayloadArgs[0].jobId,
      },
      { trackingCorrelationId },
    );
  }

  public saveSearchFormDisclaimerLinkClicked(
    ...args: Parameters<Analytics['saveSearchFormDisclaimerLinkClicked']>
  ) {
    this.universalFacade.saveSearchFormDisclaimerLinkClicked(...args);
  }

  public searchPerformed(...args: Parameters<Analytics['searchPerformed']>) {
    this.universalFacade.searchPerformed(...args);
  }

  public searchResultsPageLoaded(
    hubblePayload: HubbleEventPayload<'search_results_displayed'>,
    ...args: Parameters<Analytics['searchResultsPageLoaded']>
  ) {
    const trackingCorrelationId = uuid();
    this.hubble.trackEvent(
      'search_results_displayed',
      {
        ...hubblePayload,
        keywordAutocompleteRequestToken: this.dataLayer.autosuggestRequestToken,
      },
      {
        trackingCorrelationId,
      },
    );
    this.universalFacade.searchResultsPageLoaded({
      ...args[0],
      trackingCorrelationId,
    });
  }

  public refinementSuggestionClicked(
    hubblePayload: HubbleEventPayload<'refinement_suggestion_pressed'>,
  ) {
    this.hubble.trackEvent('refinement_suggestion_pressed', hubblePayload);

    if (hubblePayload.refinedKeyword) {
      this.dataLayer.refinedKeywords = [
        ...this.dataLayer.refinedKeywords,
        hubblePayload.refinedKeyword,
      ];
    }
  }

  public shareLinkClicked(...args: Parameters<Analytics['shareLinkClicked']>) {
    this.universalFacade.shareLinkClicked(...args);
  }

  public signInPressed(...args: Parameters<Analytics['signInPressed']>): void {
    this.universalFacade.signInPressed(...args);
  }

  public sourcrClicked() {
    this.universalFacade.sourcrClicked();
  }

  public sourcrImpression() {
    this.universalFacade.sourcrImpression();
  }

  public sortModeChanged(payload: Parameters<Analytics['sortModeChanged']>[0]) {
    this.universalFacade.sortModeChanged(payload);
  }

  public trackNudgeImpression(
    ...args: Parameters<(typeof impressionTracker)['trackNudgeImpression']>
  ) {
    impressionTracker.trackNudgeImpression(...args);
  }

  public userDetailsUpdated(
    payload: Parameters<Analytics['userDetailsUpdated']>[0],
  ) {
    this.universalFacade.userDetailsUpdated(payload);
  }

  public branchBannerCTAPressed(
    ...payload: Parameters<Analytics['branchBannerCTAPressed']>
  ) {
    this.universalFacade.branchBannerCTAPressed(...payload);
  }

  public branchBannerDismissPressed(
    ...payload: Parameters<Analytics['branchBannerDismissPressed']>
  ) {
    this.universalFacade.branchBannerDismissPressed(...payload);
  }

  public branchBannerImpression(
    ...payload: Parameters<Analytics['branchBannerImpression']>
  ) {
    this.universalFacade.branchBannerImpression(...payload);
  }

  public homepageMarketingBannerClicked(
    ...payload: Parameters<Analytics['homepageMarketingBannerClicked']>
  ) {
    this.universalFacade.homepageMarketingBannerClicked(...payload);
  }

  public verifiedHirerBadgeClicked(
    ...args: Parameters<Analytics['verifiedHirerBadgeClicked']>
  ) {
    this.universalFacade.verifiedHirerBadgeClicked(...args);
  }

  public skillsMatchRegisterPressed(
    ...args: Parameters<Analytics['skillsMatchRegisterPressed']>
  ): void {
    this.universalFacade.skillsMatchRegisterPressed(...args);
  }

  public skillsMatchSignInPressed(
    ...args: Parameters<Analytics['skillsMatchSignInPressed']>
  ): void {
    this.universalFacade.skillsMatchSignInPressed(...args);
  }

  public skillsMatchFeedbackPressed(
    ...args: Parameters<Analytics['skillsMatchFeedbackPressed']>
  ): void {
    this.universalFacade.skillsMatchFeedbackPressed(...args);
  }

  public skillsMatchProfileUpdated(
    ...args: Parameters<Analytics['skillsMatchProfileUpdated']>
  ): void {
    this.universalFacade.skillsMatchProfileUpdated(...args);
  }

  public skillsMatchLoadSucceeded(
    payload: Parameters<Analytics['skillsMatchLoadSucceeded']>[0],
    hubblePayload: HubbleEventPayload<'matched_skills_load_succeeded'>,
  ): void {
    this.universalFacade.skillsMatchLoadSucceeded(payload);
    this.hubble.trackEvent('matched_skills_load_succeeded', hubblePayload);
  }

  public skillsMatchLoadFailed(
    ...args: Parameters<Analytics['skillsMatchLoadFailed']>
  ): void {
    this.universalFacade.skillsMatchLoadFailed(...args);
  }

  public skillsMatchAlert(
    ...args: Parameters<Analytics['skillsMatchAlert']>
  ): void {
    this.universalFacade.skillsMatchAlert(...args);
  }

  public skillsMatchAccordionPressed(
    ...args: Parameters<Analytics['skillsMatchAccordionPressed']>
  ): void {
    this.universalFacade.skillsMatchAccordionPressed(...args);
  }

  public poeaLinksPressed(...args: Parameters<Analytics['poeaLinksPressed']>) {
    this.universalFacade.poeaLinksPressed(...args);
  }

  public poeaCountryPickerPageLoaded(
    ...args: Parameters<Analytics['poeaCountryPickerPageLoaded']>
  ) {
    this.universalFacade.poeaCountryPickerPageLoaded(...args);
  }

  public setExperimentEligible(
    ...args: Parameters<Analytics['experimentsEligible']>
  ): void {
    this.universalFacade.setExperimentEligible(...args);
  }
}

export type AnalyticsFacade = InstanceType<
  typeof UniversalAnalyticsFacade | typeof BrowserAnalyticsFacade
>;

export const isBrowserAnalyticsFacade = (
  facade: AnalyticsFacade,
): facade is BrowserAnalyticsFacade => typeof window !== 'undefined';

export const isUniversalAnalyticsFacade = (
  facade: AnalyticsFacade,
): facade is UniversalAnalyticsFacade => typeof window === 'undefined';
