export const UPDATE_FEATURE_FLAGS = 'UPDATE_FEATURE_FLAGS';

export interface FeatureFlagsState {
  behaviouralCues: boolean;
  behaviouralCuesFilters: boolean;
  branchBannerPreview: boolean;
  isBranchEnabledFlag: boolean;
  showHomepageBanner: boolean;
  showFloatingSaveSearch: boolean;
  autoSelectSplitViewFirstJob: boolean;
  showMarketingAdSplitView: boolean;
  loggedOutRecs: boolean;
  remoteSearchFilter: boolean;
  remoteSearchFilterNewLabel: boolean;
  dynamicPills: boolean;
  secondaryFilters: boolean;
  dynamicPillsV2: boolean;
  keywordAutosuggestV2: boolean;
  hirerVerifiedBadge: boolean;
  serpJobCardInfoDensity1: boolean;
  newJobCardDensity: boolean;
  hideCompanyLogo: boolean;
  matchedQualities: boolean;
  hideApplyButtonOnPrivateAdvertiser: boolean;
  entryLevelBadge: boolean;
  homepageGoogleAds: boolean;
  homepageRecsBadgingStrongApplicant: boolean;
  homepageJobCardDensity: boolean;
}

export interface FeatureFlagsAction {
  type: typeof UPDATE_FEATURE_FLAGS;
  payload: {
    dynamicFlags: Record<string, any>;
  };
}

export type TFeatureFlagNames = keyof FeatureFlagsState;
