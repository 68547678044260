import { version } from 'src/config';
import trackingClient from 'src/modules/job-tracking';
import type { TypedThunkAction } from 'src/store/types';

export const tabImpression = (): TypedThunkAction => (_, getState) => {
  const state = getState();
  const {
    user: { seekerId, sessionId },
    jobdetails: { jobDetailsViewedCorrelationId, result },
  } = state;

  trackingClient.trackTabImpression({
    buildVersion: version,
    candidateId: seekerId,
    jobDetailsViewedCorrelationId,
    jobId: result?.job?.id,
    sessionId,
  });
};
