import { type Locale, getHostName } from '@seek/melways-sites';

import { useSelector } from 'src/store/react';
import {
  selectPathname,
  selectResultsLocation,
  selectWhereField,
} from 'src/store/selectors';
import { useMelwaysCountry, useMelwaysZone } from 'src/utils/melwaysHelper';

import { useSearchParams } from '../../search-results-page/search-params';
import { shouldRenderPOEA as hasPoeaKeywords } from '../../search-results-page/utils';

import type { HreflangLink } from './hreflang';

interface HreflangLinksInput {
  siteUrl: string;
  languages: string[];
  countryCode: string;
  shouldStripLocation?: boolean;
}

const stripLocationFromPath = (path: string): string =>
  path.replace(/\/in-[^/]+/, '');

const stripLanguagePrefixFromPath = (path: string): string =>
  path.replace(/^\/(th|id)\//, '/');

const countryIdsMap = {
  '24553': {
    country: 'sg',
    languages: ['en'],
    siteUrl: `https://${getHostName({
      site: 'candidate-jobstreet-sg',
      staging: false,
    })}`,
  },
  '24534': {
    country: 'ph',
    languages: ['en'],
    siteUrl: `https://${getHostName({
      site: 'candidate-jobstreet-ph',
      staging: false,
    })}`,
  },
  '24473': {
    country: 'id',
    languages: ['en', 'id'],
    siteUrl: `https://${getHostName({
      site: 'candidate-jobstreet-id',
      staging: false,
    })}`,
  },
  '24500': {
    country: 'my',
    languages: ['en'],
    siteUrl: `https://${getHostName({
      site: 'candidate-jobstreet-my',
      staging: false,
    })}`,
  },
  '3000': {
    country: 'au',
    languages: ['en'],
    siteUrl: `https://${getHostName({
      site: 'candidate-seek-au',
      staging: false,
    })}`,
  },
  '3001': {
    country: 'nz',
    languages: ['en'],
    siteUrl: `https://${getHostName({
      site: 'candidate-seek-nz',
      staging: false,
    })}`,
  },
  '24572': {
    country: 'th',
    languages: ['en', 'th'],
    siteUrl: `https://${getHostName({
      site: 'candidate-jobsdb-th',
      staging: false,
    })}`,
  },
  '24469': {
    country: 'hk',
    languages: ['en'],
    siteUrl: `https://${getHostName({
      site: 'candidate-jobsdb-hk',
      staging: false,
    })}`,
  },
};

export const usePoeaHrefLangLinks = (): HreflangLink[] => {
  const location = useSelector(selectResultsLocation);
  const whereField = useSelector(selectWhereField);
  const path = useSelector(selectPathname);
  const country = useMelwaysCountry();
  const zone = useMelwaysZone();
  const searchParams = useSearchParams();
  const keywords = searchParams?.keywords;

  const poeaHreflangLinks: HreflangLink[] = [];

  if (keywords && hasPoeaKeywords({ zone, keywords })) {
    return [];
  }

  const getLocationBasedOnWhereField = () => {
    /* This additional check is required for AU and NZ as the location is 
    set to the country by default even if the where text field is empty */
    const isWhereFieldEmpty = whereField.length === 0;
    if (!isWhereFieldEmpty) {
      return location;
    }
  };

  const getHreflangLinks = ({
    siteUrl,
    languages,
    countryCode,
    shouldStripLocation,
  }: HreflangLinksInput): HreflangLink[] => {
    const pathWithoutLangPrefix = stripLanguagePrefixFromPath(path);
    return languages.map((lang) => {
      const strippedPath = shouldStripLocation
        ? stripLocationFromPath(pathWithoutLangPrefix)
        : pathWithoutLangPrefix;
      const modifiedPath =
        lang === 'en' ? strippedPath : `/${lang}${strippedPath}`;

      return {
        href: `${siteUrl}${modifiedPath}`,
        rel: 'alternate',
        hreflang: `${lang}-${countryCode.toUpperCase()}` as Locale,
      };
    });
  };

  const whereLocation = getLocationBasedOnWhereField();

  if (whereLocation && whereLocation.whereId in countryIdsMap) {
    const {
      siteUrl,
      languages,
      country: countryCode,
    } = countryIdsMap[whereLocation.whereId as keyof typeof countryIdsMap];

    poeaHreflangLinks.push(
      ...getHreflangLinks({
        siteUrl,
        languages,
        countryCode,
        shouldStripLocation: true,
      }),
    );

    const currentCountry = Object.values(countryIdsMap).find(
      (entry) => entry.country === country,
    );

    if (currentCountry) {
      poeaHreflangLinks.push(
        ...getHreflangLinks({
          siteUrl: currentCountry.siteUrl,
          languages: currentCountry.languages,
          countryCode: currentCountry.country,
        }),
      );
    }
  }

  return poeaHreflangLinks;
};
