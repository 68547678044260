import gql from 'graphql-tag';

export const GET_HOME_RECOMMENDATIONS = gql`
  query GetHomeRecommendations(
    $zone: Zone!
    $visitorId: UUID!
    $timezone: Timezone!
    $locale: Locale!
    $limit: Int
  ) {
    viewer {
      personalDetails {
        firstName
      }
      emailAddress
      trackingId
      id
      recommendations {
        recommendedGlobalJobs(
          includeSavedJobs: true
          limit: $limit
          source: HOME
          zone: $zone
          visitorId: $visitorId
          platform: WEB
        ) {
          globalJobs {
            id
            isNew
            isSaved
            solMetadata
            job {
              id
              title
              advertiser {
                name(locale: $locale)
              }
              salary {
                currencyLabel(zone: $zone)
                label
              }
              location {
                label(locale: $locale)
              }
              listedAt {
                label(
                  context: JOB_POSTED
                  length: SHORT
                  timezone: $timezone
                  locale: $locale
                )
              }
              products {
                branding {
                  logo {
                    url
                  }
                }
                bullets
              }
            }
            tags {
              label(locale: $locale)
              type
            }
          }
          solMetadata
          totalCount
        }
      }
    }
  }
`;

export const GET_LOGGED_OUT_RECOMMENDATIONS = gql`
  query GetLoggedOutRecommendations(
    $jobId: ID!
    $limit: Int
    $zone: Zone!
    $visitorId: UUID!
    $timezone: Timezone
    $locale: Locale
  ) {
    loggedOutRecommendedJobs(
      jobId: $jobId
      limit: $limit
      source: LOGGED_OUT_HOME
      zone: $zone
      visitorId: $visitorId
      platform: WEB
    ) {
      globalJobs {
        solMetadata
        job {
          advertiser {
            name(locale: $locale)
          }
          id
          title
          location {
            label(locale: $locale)
          }
          listedAt {
            shortLabel(timezone: $timezone, locale: $locale)
          }
          products {
            branding {
              logo {
                url
              }
            }
          }
        }
      }
      solMetadata
    }
  }
`;
