import type { Zone } from '@seek/audience-zones';
import type { CountrySiteName, Locale } from '@seek/melways-sites';
import { createContext, useContext } from 'react';

import { parseLocale } from 'src/modules/locale-utils';

import type { AppConfig, ZoneConfig } from './types';
import { getBrandFromZone } from './utils/getBrandFromZone';

type AppConfigContextType = Omit<AppConfig, 'zone' | 'locale' | 'language' | 'brand' | 'site' >;

const AppConfigContext = createContext<AppConfigContextType>(
  {} as AppConfigContextType,
);
export const AppConfigProvider = AppConfigContext.Provider;
export const useMakeAppConfigContext = (
  config: AppConfig,
): AppConfigContextType => {
  const {
    language,
    locale,
    zone,
    brand,
    site,
    ...context
  } = config;

  return  context
};

export const useAppConfig = () => useContext(AppConfigContext);

export const useTimedBanner = <B extends keyof AppConfig['timedBanners']>(
  banner: B,
) => useAppConfig().timedBanners[banner];

export const useZoneFeatures = () => useAppConfig().zoneFeatures;

interface GenerateConfigArgs {
  zone: Zone;
  site: CountrySiteName;
  requestedLocale: Locale;
}
export const generateConfig = ({
  zone,
  requestedLocale,
  site,
}: GenerateConfigArgs): AppConfig => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const config = require(`./zone-configs/${zone}`).default as ZoneConfig;
  const locale = determineSelectedLocale({ config, requestedLocale });

  return {
    ...config,
    brand: getBrandFromZone(zone),
    locale,
    site,
    ...parseLocale(locale), // adds language and country to the config
  };
};

const determineSelectedLocale = ({
  config,
  requestedLocale,
}: {
  config: ZoneConfig;
  requestedLocale: Locale;
}) => {
  const available = config.availableLocales.includes(requestedLocale);
  return available ? requestedLocale : config.defaultLocale;
};
