import { ButtonLink, IconFilter, IconSearch } from 'braid-design-system';
import { useCallback } from 'react';

import {
  isBrowserAnalyticsFacade,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { useDispatch, useSelector } from 'src/store/react';
import { updateSearchSource } from 'src/store/results/index.ts';
import { selectSolMetadataRequestToken } from 'src/store/selectors';

import useSeoFooter from '../SeoFooter/useSeoFooter';

export interface RelatedSearchItemProps {
  text: string;
  href: string;
  position: number;
  rel?: string;
  type: 'related' | 'facets';
}

type OnLinkClick = (text: RelatedSearchItemProps['text']) => void;

const RelatedSearchItem = ({
  text,
  href,
  position,
  rel,
  type,
}: RelatedSearchItemProps) => {
  const analyticsFacade = useAnalyticsFacade();
  const searchRequestToken = useSelector(selectSolMetadataRequestToken);
  const { tabIndex } = useSeoFooter();
  const dispatch = useDispatch();

  const onLinkClick = useCallback<OnLinkClick>(
    (linkText) => {
      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.relatedSearchPressed({
          value: linkText,
          rank: position,
          solMetadata: {
            searchRequestToken,
          },
        });
      }
      if (type === 'related') {
        dispatch(updateSearchSource('RELATED'));
      }
    },
    [analyticsFacade, type, position, searchRequestToken, dispatch],
  );

  return (
    <ButtonLink
      variant="soft"
      size="small"
      tone="neutral"
      onClick={() => onLinkClick(text)}
      href={href}
      rel={rel}
      data={{ automation: `related-search-link-${position}` }}
      tabIndex={tabIndex}
      icon={type === 'related' ? <IconSearch /> : <IconFilter />}
      iconPosition="leading"
    >
      {text}
    </ButtonLink>
  );
};

export default RelatedSearchItem;
