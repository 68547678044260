import { useTranslations } from '@vocab/react';
import {
  Box,
  ButtonIcon,
  Column,
  Columns,
  IconChevron,
  IconSort,
  IconTick,
  Inline,
  MenuItem,
  MenuRenderer,
  Strong,
  Text,
} from 'braid-design-system';
import filter from 'lodash/filter';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import type { ComponentProps } from 'react';

import { MenuItemLink } from 'src/components/NavLink/NavLink';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';
import type { Option } from 'src/types/globals';

import translations from './.vocab';

import * as styles from './LinkSelect.css';

interface LinkSelectProps {
  elementType: 'distance' | 'sortby';
  label: string;
  icon?: ComponentProps<typeof Text>['icon'];
  navigationLabel: string;
  options: Option[];
  menuAlignment?: ComponentProps<typeof MenuRenderer>['align'];
  onChange?: (value: string) => void;
  postDropdownText?: string;
}

const LinkSelect = ({
  elementType,
  label,
  icon,
  menuAlignment = 'left',
  navigationLabel,
  options,
  onChange,
  postDropdownText,
}: LinkSelectProps) => {
  const { t } = useTranslations(translations);
  const isBehaviouralCuesFiltersEnabled = useSelector(
    selectFeatureFlag('behaviouralCuesFilters'),
  );

  const activeOption = filter(options, 'isActive')[0];
  const activeLabel = toLower(get(activeOption, 'label'));

  const inputId = `${elementType}-input`;
  const labelId = `${elementType}-label`;

  const onMenuItemClick = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  const RenderSingleOption = () => (
    <Text icon={icon} size="small">
      {`${label} `}
      <Strong>{activeLabel}</Strong>
      {postDropdownText ? ` ${postDropdownText}` : null}
    </Text>
  );

  const useRenderMultiOptions = () => {
    if (!activeLabel) {
      return null;
    }

    const hasPostDropdownText = Boolean(postDropdownText);

    return options.length === 1 ? (
      <RenderSingleOption />
    ) : (
      <Columns space="xsmall">
        {icon ? (
          <Column width="content">
            <Text size="small">{icon}</Text>
          </Column>
        ) : null}
        <Column>
          <Inline align="left" space="xsmall" alignY="center">
            <MenuRenderer
              reserveIconSpace={isBehaviouralCuesFiltersEnabled}
              align={menuAlignment}
              offsetSpace={isBehaviouralCuesFiltersEnabled ? 'xsmall' : 'small'}
              trigger={(triggerProps, { open }) => (
                <>
                  <Box
                    userSelect="none"
                    cursor="pointer"
                    className={{
                      [styles.fixMenuSpacing]: hasPostDropdownText,
                    }}
                    data-automation="trigger"
                    {...triggerProps}
                  >
                    {isBehaviouralCuesFiltersEnabled &&
                    elementType === 'sortby' ? (
                      <ButtonIcon
                        icon={<IconSort />}
                        label={label}
                        id="sorted-by"
                        data={{ automation: 'sortedByButtonIconBcues' }}
                      />
                    ) : (
                      <Text
                        size="small"
                        data={{ automation: `active-${elementType}` }}
                      >
                        {`${label} `}
                        <Strong>{`${activeLabel} `}</Strong>
                        <IconChevron
                          direction={open ? 'up' : 'down'}
                          alignY="lowercase"
                        />
                      </Text>
                    )}
                  </Box>
                  <Box
                    component="label"
                    htmlFor={inputId}
                    className={styles.backdrop}
                  >
                    {t('Done')}
                  </Box>
                </>
              )}
            >
              {options.map(
                ({ value, label: optionLabel, refineParams }, index) => {
                  const location = {
                    pathname: '/jobs',
                    query: refineParams,
                  };

                  return refineParams ? (
                    <MenuItemLink
                      data={{ automation: `${elementType}-${index}` }}
                      key={value}
                      onClick={() => {
                        onMenuItemClick(value);
                      }}
                      aria-label={t('{navigationLabel} {label}', {
                        navigationLabel,
                        label,
                      })}
                      href={location}
                    >
                      {optionLabel}
                    </MenuItemLink>
                  ) : (
                    <MenuItem
                      key={label}
                      icon={isBehaviouralCuesFiltersEnabled && <IconTick />}
                    >
                      {optionLabel}
                    </MenuItem>
                  );
                },
              )}
            </MenuRenderer>

            {hasPostDropdownText && (
              <Box component="label" htmlFor={inputId}>
                <Text size="small">
                  {' '}
                  {postDropdownText ? postDropdownText : ''}
                </Text>
              </Box>
            )}
          </Inline>
        </Column>
      </Columns>
    );
  };

  const OptionsToRender = () => useRenderMultiOptions();

  return (
    <Box
      aria-labelledby={labelId}
      role="contentinfo"
      id={elementType}
      data-automation={elementType}
    >
      <OptionsToRender />
    </Box>
  );
};

export default LinkSelect;
