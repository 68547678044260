import type { CountrySiteName } from '@seek/melways-sites';

import { environment } from 'src/config';
import type { CookieType } from 'src/types/cookie';

import { releasedSSASites, SERVER_SIDE_AUTH_COOKIE_NAME } from './constants';

export const shouldUseSSA = ({
  cookies,
  site,
}: {
  cookies: CookieType;
  site: CountrySiteName;
}) =>
  cookies[SERVER_SIDE_AUTH_COOKIE_NAME] === 'true' &&
  (environment === 'production' || environment === 'staging') &&
  releasedSSASites.includes(site);

export const isAuthError = (error: unknown) => {
  if (error instanceof Error) {
    return error.message.includes(
      'error occurred while executing the authentication middleware',
    );
  }

  return false;
};
