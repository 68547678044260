import { useTranslations } from '@vocab/react';
import type { ComponentProps } from 'react';

import { NudgeTemplate } from '../../NudgeTemplate/NudgeTemplate';

import translations from './.vocab';

const ErrorNudge = ({
  CTAs,
  errorNudgeType,
  onDismiss,
  position,
}: Pick<
  ComponentProps<typeof NudgeTemplate>,
  'CTAs' | 'errorNudgeType' | 'onDismiss' | 'position'
>) => {
  const { t } = useTranslations(translations);

  return (
    <NudgeTemplate
      CTAs={CTAs}
      data={{ automation: 'error-nudge' }}
      errorNudgeType={errorNudgeType}
      heading={t('There’s something wrong on our end.')}
      id="error"
      nudgeType="Error"
      onDismiss={onDismiss}
      position={position}
      text={t('We weren’t able to save your answer to your profile.')}
    />
  );
};

export default ErrorNudge;
