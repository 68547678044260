export const minEnrichedJob: Record<string, any> = {
  '37296827': {
    advertiser: {
      id: '38605420',
      name: 'Synthetic Testing',
    },
    classification: {
      id: 1209,
      description: 'Engineering',
    },
    subClassification: {
      id: 6036,
      description: 'Process Engineering',
    },
    locationInfo: {
      area: 'CBD & Inner Suburbs',
      location: 'Melbourne',
      locationIds: ['18578'],
    },
    hasRoleRequirements: false,
    adProductType: 'Classic',
    title: 'Synthetic Minimally Enriched Job',
    isPrivateAdvertiser: false,
    isLinkOut: false,
    listingDate: {
      label: '1d ago',
      dateTimeUtc: '2022-09-21T00:00:00Z',
    },
    workType: 'Full Time',
    salary: '$85,000 - $109,999',
    status: 'Active',
    companyProfile: null,
    content:
      '<p>This job has the minimum number of attributes in its job detail JSON. This is to simulate the worst case of a partially enriched job. Details of the optional and required attributes are here:</p> <p>https://github.com/SEEK-Jobs/eng-enrichment-pipeline-projector/blob/master/README_JOBS.md</p>',
    contactMatches: [],
    sourcr: null,
    gfjInfo: {
      location: {
        countryCode: 'AU',
        country: 'Australia',
        suburb: 'Melbourne',
        region: '',
        state: 'Victoria',
        postcode: '3000',
      },
      workTypes: {
        label: ['FULL_TIME'],
      },
    },
    seoInfo: {
      normalisedRoleTitle: 'synthetic minimally enriched job',
      workType: '242',
      classification: ['1209', '6008'],
      subClassification: ['6036', '6015'],
      where: 'Melbourne VIC 3004',
    },
  },
};
