import type { SortMode } from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';
import { memo, useCallback } from 'react';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { sortModeViewModel } from 'src/modules/refine-job-search';
import { useSelector } from 'src/store/react';
import { selectResultsSortMode, selectSearchQuery } from 'src/store/selectors';

import LinkSelect from '../LinkSelect/LinkSelect';

import translations from './.vocab';

const useSortModeOptions = () => {
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));
  const sortMode = useSelector(selectResultsSortMode);
  return sortModeViewModel({ searchQuery, sortMode });
};

const SortedBy = () => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();
  const sortModeOptions = useSortModeOptions();

  const onSortModeChange = useCallback(
    (value: SortMode) => {
      analyticsFacade.sortModeChanged(value);
    },
    [analyticsFacade],
  );

  if (sortModeOptions === undefined) {
    return <></>;
  }

  return (
    <LinkSelect
      menuAlignment="right"
      elementType="sortby"
      label={t('Sorted by')}
      navigationLabel={t('Sort By')}
      options={sortModeOptions}
      onChange={(sortedByValue: string) => {
        onSortModeChange(
          sortedByValue === 'ListedDate' ? 'ListedDate' : 'KeywordRelevance',
        );
      }}
    />
  );
};

export default memo(SortedBy);
