import type { Skill } from '@seek/ca-graphql-schema/types';
import type { JobDetailsView } from '@seek/job-details-view';
import type React from 'react';

import { useSkillsForJDV } from 'src/hooks/useSkills/useSkillsForJDV';
import { useSkillsMutations } from 'src/hooks/useSkills/useSkillsMutations';
import { logger } from 'src/modules/logger';

const SKILL_LIMIT = 50;
export const MINIMUM_MATCHES_TO_DISPLAY = 3;

export const useJDVSkillsMatchProps = (
  jobId: string,
): React.ComponentProps<typeof JobDetailsView>['qualities'] & {
  skillsCount: number;
  matchedQualitiesRequestToken: string | null;
} => {
  const { addSkill, removeSkill } = useSkillsMutations(SKILL_LIMIT);
  const { matchedQualitiesRequestToken, qualities, hasResume, error, loading } =
    useSkillsForJDV(jobId);
  const { matched, inferred, unmatched } = qualities ?? {};

  if (error) {
    logger.error(error, 'useJDVSkillsMatches.ts');
  }

  const skillsCount =
    (matched?.length ?? 0) + (inferred?.length ?? 0) + (unmatched?.length ?? 0);

  const shouldShowSkillsMatch = skillsCount >= MINIMUM_MATCHES_TO_DISPLAY;

  return {
    matchedQualitiesRequestToken,
    matched: matched as Skill[],
    inferred: inferred as Skill[],
    unmatched: unmatched as Skill[],
    skillsCount,
    shouldShowSkillsMatch,
    hasResume,
    isLoadingQualities: loading,
    error: error ? 'NetworkError' : undefined,
    onAddQuality: addSkill,
    onRemoveQuality: removeSkill,
  };
};
