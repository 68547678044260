import { Heading, HiddenVisually } from 'braid-design-system';
import { useSelector } from 'react-redux';

import { useSERPHasExternalJobs } from 'src/modules/hooks/useSERPHasExternalJobs';
import { useSharedHead } from 'src/modules/seo-header/shared/head';
import {
  selectCanonicalUrl,
  selectIsJDP,
  selectIsZeroResult,
  selectJobDetailsIsExpired,
  selectPathname,
  selectQuery,
  selectResultsLocation,
} from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import { SafetyHelmet } from '../SafetyHelmet/SafetyHelmet';

const SharedHead = () => {
  const canonicalUrl = useSelector(selectCanonicalUrl);
  const pathname = useSelector(selectPathname);
  const country = useMelwaysCountry();

  const query = useSelector(selectQuery);
  const isZeroResults = useSelector(selectIsZeroResult);
  const isExpired = useSelector(selectJobDetailsIsExpired);
  const isJDP = useSelector(selectIsJDP);

  const hasExternalJobs = useSERPHasExternalJobs();
  const location = useSelector(selectResultsLocation);

  const locationType = location?.type || '';

  const { meta, link, script, title } = useSharedHead({
    canonicalUrl,
    query,
    isZeroResults,
    pathname,
    hasExternalJobs,
    country,
    isExpired,
    locationType,
  });

  return (
    <>
      {!isJDP && (
        <HiddenVisually>
          <Heading level="1">{title}</Heading>
        </HiddenVisually>
      )}
      <SafetyHelmet meta={meta} link={link} script={script} title={title} />
    </>
  );
};

export default SharedHead;
