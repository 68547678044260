import {
  Box,
  Column,
  Columns,
  IconFilter,
  Inline,
  PageBlock,
  Stack,
  Text,
  useResponsiveValue,
} from 'braid-design-system';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Outlet } from 'react-router';
// @ts-expect-error: non-ts file
import { provideHooks } from 'redial';

import { isGooglebot } from 'src/modules/chalice-user-agent/googlebot.ts';
import { getUserAgent } from 'src/modules/chalice-user-agent/isomorphic-useragent.ts';
import type { TSeekExperiments } from 'src/store/experiments/types';
import { useDispatch, useSelector } from 'src/store/react';
import {
  expandSearchFilters,
  getCounts,
  showPoeaCountryPicker,
  showPoeaCountryPickerFromNavigation,
  updateCriteria,
} from 'src/store/search';
import { selectPoeaCountryPicker } from 'src/store/search/selectors.ts';
import {
  selectFeatureFlag,
  selectIsFiltersExpanded,
  selectIsHomePage,
  selectSearchQuery,
} from 'src/store/selectors';
import type { RedialLocals } from 'src/types/RedialLocals';

import { isPoeaPathWithoutLocation } from '../Poea/utils.tsx';

import { DynamicPill } from './DynamicPill/DynamicPill.tsx';
import { DynamicSearchBar } from './DynamicSearchBar/DynamicSearchBar.tsx';
import RefineBar from './RefineBar/RefineBar';
import SearchBar from './SearchBar/SearchBar';
import { SecondaryFilter } from './SecondaryFilter/SecondaryFilter';
import { useSecondaryFilterContext } from './SecondaryFilter/context/SecondaryFilterContext';

import * as styles from './Search.css';

const hooks = {
  first({ dispatch, path = '', query, zone }: RedialLocals) {
    return dispatch(
      updateCriteria({
        path,
        query,
        zone,
      }),
    );
  },
  defer({
    dispatch,
    country,
    path = '',
    query,
    cookies,
    zone,
    visitorId,
  }: RedialLocals) {
    return dispatch(
      getCounts({
        zone,
        country,
        path,
        query,
        cookies,
        solId: visitorId,
      }),
    );
  },

  pageLoad({ getState }: RedialLocals) {
    if (getState().search.poeaCountryPicker) {
      history.replaceState({}, '', '?countryPickerPage=true');
    }
  },

  fetch({
    dispatch,
    path = '',
    getState,
    referrer,
    analyticsFacade,
    zone,
  }: RedialLocals) {
    if (zone === 'asia-6') {
      const state = getState();
      const userAgent = getUserAgent();
      const urlHasCountryPickerQueryString = state.location?.url?.includes(
        'countryPickerPage=true',
      );
      const { poeaCountryPickerFromNavigation } = state.search;

      const isGoogleReferrerOrBot =
        referrer.includes('google') || isGooglebot(userAgent);

      const showCountryPicker =
        (isGoogleReferrerOrBot && isPoeaPathWithoutLocation(path)) ||
        urlHasCountryPickerQueryString ||
        poeaCountryPickerFromNavigation;

      if (showCountryPicker) {
        analyticsFacade.poeaCountryPickerPageLoaded({
          currentPage: 'country picker',
        });
        dispatch(showPoeaCountryPickerFromNavigation(false));
        return dispatch(showPoeaCountryPicker(true));
      }
      return dispatch(showPoeaCountryPicker(false));
    }
  },
};

export interface SearchProps {
  experiments?: TSeekExperiments;
}

const Search = () => {
  const filtersExpanded = useSelector(selectIsFiltersExpanded);
  const isCountryPicker = useSelector(selectPoeaCountryPicker);
  const dispatch = useDispatch();
  const showSecondaryFilters = useSelector(
    selectFeatureFlag('secondaryFilters'),
  );
  const showDynamicPills = useSelector(selectFeatureFlag('dynamicPills'));
  const dynamicPillsV2 = useSelector(selectFeatureFlag('dynamicPillsV2'));
  const isHomePage = useSelector(selectIsHomePage);
  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  const { setIsOpen } = useSecondaryFilterContext();

  const [isSearchInView, setIsSearchInView] = useState(true);

  const { ref: searchInViewRef } = useInView({
    onChange: (inView) => {
      setIsSearchInView(() => inView);
    },
    initialInView: true,
  });

  const searchQuery = useSelector(selectSearchQuery);
  const numberOfFilterSelected = [
    searchQuery.classification,
    searchQuery.worktype,
    searchQuery.daterange,
  ].reduce(
    (count, filterSelected) => count + (filterSelected?.split(',').length || 0),
    0,
  );

  const [isMobileExpandedView, setIsMobileExpandedView] = useState(false);

  if (isCountryPicker) {
    return <Outlet />;
  }

  if (dynamicPillsV2) {
    return (
      <Box height="full">
        <DynamicSearchBar />
        <Outlet />
      </Box>
    );
  }

  return (
    <Box height="full">
      <Box
        position="relative"
        // This is to ensure this will not cover Header's language switcher
        zIndex={2}
        background="brand"
        paddingBottom={{
          mobile: showSecondaryFilters ? 'small' : 'medium',
          tablet: 'small',
        }}
        paddingX={{
          mobile: 'none',
          tablet: showSecondaryFilters ? 'large' : 'medium',
        }}
        className={{
          [styles.branding]: !showSecondaryFilters,
          [styles.searchBarPadding]: !showSecondaryFilters,
          [styles.secondaryFilterPadding]: showSecondaryFilters,
        }}
      >
        {/* Mobile web*/}
        {showSecondaryFilters && isMobile && (
          <>
            <PageBlock width="large">
              <Columns space={isHomePage ? 'none' : 'xsmall'}>
                <Column>
                  <SearchBar collapsed={!isHomePage && filtersExpanded} />
                </Column>
                <Column width="content">
                  <Box display={!isHomePage ? 'block' : 'none'}>
                    <Box
                      boxShadow="borderField"
                      borderRadius="standard"
                      padding="xsmall"
                      onClick={() => setIsOpen(true)}
                    >
                      <IconFilter />
                      {numberOfFilterSelected > 0 && (
                        <Box
                          position="absolute"
                          borderRadius="large"
                          background="positiveLight"
                          className={styles.selectionIndicator}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          style={{ top: '14px', right: '4px' }}
                        >
                          <Text weight="strong" size="xsmall">
                            {numberOfFilterSelected}
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Column>
              </Columns>
            </PageBlock>
            {showDynamicPills && (
              <Box paddingTop="small" display={!isHomePage ? 'block' : 'none'}>
                <DynamicPill />
              </Box>
            )}
          </>
        )}

        {/* desktop and tablet*/}
        {showSecondaryFilters && !isMobile && (
          <Box
            position="relative"
            zIndex={2}
            background="brand"
            paddingBottom="xsmall"
          >
            <PageBlock width="large">
              <SearchBar collapsed={filtersExpanded} />
              <Box display="block" style={{ paddingTop: '20px' }}>
                <Columns space="large" alignY="center">
                  <Column>
                    {showDynamicPills && !isHomePage && <DynamicPill />}
                  </Column>
                  <Column width="content">
                    <Box
                      data={{ automation: 'filterButton' }}
                      className={styles.filterButton}
                      component="button"
                      paddingLeft="medium"
                      paddingRight={
                        numberOfFilterSelected > 0 ? 'small' : 'gutter'
                      }
                      paddingY="xsmall"
                      borderRadius="standard"
                      cursor="pointer"
                      onClick={() => {
                        setIsOpen(true);
                        dispatch(expandSearchFilters(true));
                      }}
                    >
                      <Inline space="xsmall" alignY="center">
                        <Text
                          size="standard"
                          weight="strong"
                          icon={<IconFilter />}
                          baseline={false}
                        >
                          {'Filters'}
                        </Text>
                        {numberOfFilterSelected > 0 && (
                          <Box>
                            <Box
                              borderRadius="large"
                              background="positiveLight"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              className={styles.selectionIndicator}
                              style={{
                                marginTop: '-2px',
                                marginBottom: '-2px',
                              }}
                            >
                              <Text
                                weight="strong"
                                size="xsmall"
                                data={{ automation: 'numberOfFilter' }}
                              >
                                {numberOfFilterSelected}
                              </Text>
                            </Box>
                          </Box>
                        )}
                      </Inline>
                    </Box>
                  </Column>
                </Columns>
              </Box>
            </PageBlock>
          </Box>
        )}

        {/* SearchBar and RefineBar without Secondary Filters and Dynamic Pills*/}
        {!showSecondaryFilters && (
          <PageBlock width="medium">
            <Box ref={searchInViewRef} data={{ automation: 'searchInView' }}>
              <Stack
                space={{
                  mobile: filtersExpanded ? 'xsmall' : 'none',
                  tablet: 'xsmall',
                }}
              >
                <SearchBar
                  collapsed={filtersExpanded}
                  isSearchInView={isSearchInView}
                  isMobileExpandedView={isMobileExpandedView}
                  setIsMobileExpandedView={setIsMobileExpandedView}
                />
                <RefineBar
                  expanded={filtersExpanded}
                  isSearchInView={isSearchInView}
                  isMobileExpandedView={isMobileExpandedView}
                />
              </Stack>
            </Box>
          </PageBlock>
        )}

        {showSecondaryFilters && <SecondaryFilter />}
      </Box>
      <Outlet />
    </Box>
  );
};

export default provideHooks(hooks)(Search);
