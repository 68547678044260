import { ApolloLink, Observable, type Operation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { metrics } from '@seek/metrics-js';

import { logger } from 'src/modules/logger/index.ts';

import { GET_SAVED_SEARCHES } from '../queries/apacSavedSearch';
import { GET_BANNER } from '../queries/banner';
import { GET_JOB_DETAILS } from '../queries/jobDetails.ts';
import {
  GET_HOME_RECOMMENDATIONS,
  GET_LOGGED_OUT_RECOMMENDATIONS,
} from '../queries/recommendations';
import { GET_SALARY_NUDGE } from '../queries/salaryNudge.ts';
import {
  GET_HOME_SAVED_JOBS,
  SEARCH_SAVED_AND_APPLIED_JOBS,
} from '../queries/savedJobs';

export const GRAPHQL_CUSTOM_METRIC_COUNT_NAME = 'graphql.count';
export const GRAPHQL_CUSTOM_METRIC_DURATION_NAME = 'graphql.duration';

/**
 * List of allowed queries to be captured by custom metrics.
 * Add the query name here to capture custom metrics for it.
 */
const ALLOWED_QUERIES_METRICS_TO_BE_CAPTURED = [
  getOperationName(GET_LOGGED_OUT_RECOMMENDATIONS),
  getOperationName(GET_SAVED_SEARCHES),
  getOperationName(GET_HOME_RECOMMENDATIONS),
  getOperationName(GET_HOME_SAVED_JOBS),
  getOperationName(GET_BANNER),
  getOperationName(SEARCH_SAVED_AND_APPLIED_JOBS),
  getOperationName(GET_SALARY_NUDGE),
  getOperationName(GET_JOB_DETAILS),
  'MarqueeCompanies', // Get companies query for the carousel. The query itself were called from a metropolis package.
];

const verifyErrorFromApolloLink = ({
  error,
  operation,
}: {
  error: any;
  operation: Operation;
}) => {
  try {
    logger.info(
      {
        input: {
          errorClassType: error?.constructor?.name,
          errorType: error?.name,
          errorMessage: error?.message,
          operationName: operation.operationName,
          operationVars: operation.variables,
        },
      },
      'Error from ApolloLink',
    );
  } catch (e) {
    logger.info(
      {
        input: {
          error: e,
        },
      },
      'Unknown error when logging apollo link error',
    );
  }
};

/**
 * This custom ApolloLink is used to capture custom metrics for GraphQL queries.
 */
const customMetricLink = new ApolloLink((operation, forward) => {
  const startTime = new Date().valueOf();
  const shouldNotPublishCustomMetric =
    !ALLOWED_QUERIES_METRICS_TO_BE_CAPTURED.includes(operation.operationName);

  if (shouldNotPublishCustomMetric) {
    return forward(operation);
  }

  return new Observable((observer) => {
    const observable = forward(operation);
    const subscription = observable.subscribe({
      next(result) {
        if (result.errors && result.errors.length > 0) {
          publishCustomMetric({
            name: GRAPHQL_CUSTOM_METRIC_COUNT_NAME,
            type: 'count',
            status: 'error',
            operation,
          });
        } else {
          publishCustomMetric({
            name: GRAPHQL_CUSTOM_METRIC_COUNT_NAME,
            type: 'count',
            status: 'success',
            operation,
          });
        }
        observer.next(result);
      },
      error(error) {
        verifyErrorFromApolloLink({ error, operation });
        const duration = new Date().valueOf() - startTime;

        publishCustomMetric({
          name: GRAPHQL_CUSTOM_METRIC_DURATION_NAME,
          type: 'timing',
          operation,
          duration,
        });

        publishCustomMetric({
          name: GRAPHQL_CUSTOM_METRIC_COUNT_NAME,
          type: 'count',
          status: 'error',
          operation,
        });

        observer.error(error);
      },
      complete() {
        const duration = new Date().valueOf() - startTime;

        publishCustomMetric({
          name: GRAPHQL_CUSTOM_METRIC_DURATION_NAME,
          type: 'timing',
          operation,
          duration,
        });

        observer.complete();
      },
    });

    return () => subscription.unsubscribe();
  });
});

type PublicCustomMetricTags =
  | {
      operation: Operation;
      name: string;
      type: 'count';
      status: 'success' | 'error';
    }
  | {
      operation: Operation;
      name: string;
      type: 'timing';
      duration: number;
    };

const publishCustomMetric = (args: PublicCustomMetricTags) => {
  const customMetricTags = [`name:${args.operation.operationName}`];

  if (args.type === 'timing') {
    metrics.timing(args.name, args.duration, customMetricTags);
  } else {
    metrics.count(
      args.name,
      customMetricTags.concat([`status:${args.status}`]),
    );
  }
};

export default customMetricLink;
