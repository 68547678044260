
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiV2XigJl2ZSB1cGRhdGVkIHlvdXIge2JyYW5kfSBQcm9maWxlLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGhhbmtzIHtuYW1lfSEifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiS2FtaSB0ZWxhaCBtZW1wZXJiYXJ1aSBQcm9maWwge2JyYW5kfSBBbmRhLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGVyaW1hIGthc2loIHtuYW1lfSwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiS2FtaSB0ZWxhaCBtZW1wZXJiYXJ1aSBQcm9maWwge2JyYW5kfSBBbmRhLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGVyaW1hIGthc2loIHtuYW1lfSwifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoi4LmA4Lij4Liy4LmE4LiU4LmJ4LiX4Liz4LiB4Liy4Lij4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmMIHticmFuZH0g4LiC4Lit4LiH4LiE4Li44LiT4LmB4Lil4LmJ4LinIiwiVGhhbmtzIHtuYW1lfSEiOiLguILguK3guJrguITguLjguJMge25hbWV9ISJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoi4LmA4Lij4Liy4LmE4LiU4LmJ4LiX4Liz4LiB4Liy4Lij4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmMIHticmFuZH0g4LiC4Lit4LiH4LiE4Li44LiT4LmB4Lil4LmJ4LinIiwiVGhhbmtzIHtuYW1lfSEiOiLguILguK3guJrguITguLjguJMge25hbWV9ISJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiS2FtaSB0ZWxhaCBtZW1wZXJiYXJ1aSBQcm9maWwge2JyYW5kfSBBbmRhLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGVyaW1hIGthc2loIHtuYW1lfSwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiS2FtaSB0ZWxhaCBtZW1wZXJiYXJ1aSBQcm9maWwge2JyYW5kfSBBbmRhLiIsIlRoYW5rcyB7bmFtZX0hIjoiVGVyaW1hIGthc2loIHtuYW1lfSwifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoi4LmA4Lij4Liy4LmE4LiU4LmJ4LiX4Liz4LiB4Liy4Lij4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmMIHticmFuZH0g4LiC4Lit4LiH4LiE4Li44LiT4LmB4Lil4LmJ4LinIiwiVGhhbmtzIHtuYW1lfSEiOiLguILguK3guJrguITguLjguJMge25hbWV9ISJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoi4LmA4Lij4Liy4LmE4LiU4LmJ4LiX4Liz4LiB4Liy4Lij4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmMIHticmFuZH0g4LiC4Lit4LiH4LiE4Li44LiT4LmB4Lil4LmJ4LinIiwiVGhhbmtzIHtuYW1lfSEiOiLguILguK3guJrguITguLjguJMge25hbWV9ISJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiW8W04bq94bq94oCZ4bm94bq94bq9IMeax5rGpcaMxIPEg%2BG5r%2BG6veG6vcaMIMO9w73DtsO2x5rHmsWZIHticmFuZH0gxqTFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0uXSIsIlRoYW5rcyB7bmFtZX0hIjoiW%2BG5ruG4qcSDxIPEg8SD4LiB4Li14LmJxLfFoSB7bmFtZX0hXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZeKAmXZlIHVwZGF0ZWQgeW91ciB7YnJhbmR9IFByb2ZpbGUuIjoiW8W04bq94bq94oCZ4bm94bq94bq9IMeax5rGpcaMxIPEg%2BG5r%2BG6veG6vcaMIMO9w73DtsO2x5rHmsWZIHticmFuZH0gxqTFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0uXSIsIlRoYW5rcyB7bmFtZX0hIjoiW%2BG5ruG4qcSDxIPEg8SD4LiB4Li14LmJxLfFoSB7bmFtZX0hXSJ9!"
        )
      )
      });
  
      export { translations as default };
    