import { normaliseSearchQuery } from '@seek/seek-jobs-seo';

import isSuburb from 'src/modules/locations';

import { selectQuery } from '../location/selectors';
import type { ChaliceStore } from '../types';

import { getPageSize } from './resultsHelpers';

const selectRadialFilterResults = (state: ChaliceStore) => {
  const { location, totalCount } = state.results;
  const { query } = state.search;

  const storeIsRadialFilterShown = isSuburb(location);
  const storeIsRadialFilterNudgeShown =
    parseInt(query.page!, 10) === 2 &&
    (totalCount || 0) >= 50 &&
    storeIsRadialFilterShown &&
    !query.hasOwnProperty('distance');

  return {
    isRadialFilterShown: storeIsRadialFilterShown,
    isRadialFilterNudgeShown: storeIsRadialFilterNudgeShown,
  };
};

export const selectSearchPerformedAnalyticsData = (state: ChaliceStore) => {
  const {
    canonicalCompany,
    companySuggestion,
    joraCrossLink,
    location,
    results,
    solMetadata,
    sortMode,
    title,
    totalCount,
    userQueryId,
    relatedSearches,
  } = state.results;
  const queryParams = selectQuery(state);

  return {
    query: state.search.query,
    userQueryId: userQueryId!,
    flightId: solMetadata?.tags?.mordor__flights ?? '',
    relatedSearchDisplayCount: relatedSearches?.length,
    hasQueryParams: Object.keys(queryParams).length > 0,
    result: {
      canonicalCompany,
      companySuggestion,
      // FIXME: Does not exist in store.results
      canonicalUrl: undefined,
      // This has been added to the store.results to resolve linting errors for now. The proper fix would have to be taken care in future for the pagination issue (source: @melvin)
      totalPages: 0,
      // FIXME: In the previous analytics middleware implementation,
      // The correct data (state.results.results.jobs) was not being passed through.
      // Eg. result.data === undefined // true
      // The absence of this data, if important, would have been a critical error. Rather than
      // "fix" it (and send a much larger payload) we should reassess actual tracking requirements
      // before making changes.
      data: [],
      // FIXME: Missing (but optional)
      encodedUrlMeta: undefined,
      // FIXME: info object missing
      info: {
        timeTaken: 0,
        source: state.location.href!,
        experiment: '',
      },
      joraCrossLink,
      location,
      normalisedQuery: normaliseSearchQuery({
        path: state.location.pathname!,
        query: state.search.query,
      }),
      searchParams: results?.searchParams ?? {},
      solMetadata,
      sortModes: sortMode!,
      // FIXME: SearchViewModel is missing suggestions
      suggestions: {},
      title,

      totalCount: totalCount!,
      userQueryId: userQueryId!,
      totalCountNewToYou: 0,
    },
    ...selectRadialFilterResults(state),
  };
};

export const selectIsCompanySearch = (state: ChaliceStore) =>
  Boolean(state.results.results?.searchParams?.companysearch);
export const selectSearchParams = (state: ChaliceStore) =>
  state.results.results?.searchParams;
export const selectPaginationTotalPages = (state: ChaliceStore) => {
  const totalCount =
    state.search.query.tags === 'new'
      ? state.user.authenticated && state.results.totalCountNewToYou
      : state.results.totalCount;
  const pageSize = getPageSize(state.appConfig.zoneFeatures);

  return (totalCount && Math.ceil(totalCount / pageSize)) || 0;
};
export const selectResultsJobIds = (state: ChaliceStore) =>
  state.results.jobIds;
export const selectResults = (state: ChaliceStore) => state.results.results;
export const selectResultsLocation = (state: ChaliceStore) =>
  state.results.location;
export const selectResultsLocationDescription = (state: ChaliceStore) =>
  state.results.location?.description || '';
export const selectResultsTitle = (state: ChaliceStore) => state.results.title;
export const selectResultsLocationDescriptions = (state: ChaliceStore) => {
  const location = state.results.location;
  if (location) {
    return location.localisedDescriptions;
  }
  return {};
};
export const selectJoraCrossLink = (state: ChaliceStore) =>
  state.results.joraCrossLink;
export const selectIsResultsLoading = (state: ChaliceStore) =>
  state.results.isLoading;
export const selectIsResultsHidden = (state: ChaliceStore) =>
  state.results.hidden;
export const selectIsZeroResult = (state: ChaliceStore) =>
  state.results.results?.isZeroResults || false;
export const selectRelatedSearches = (state: ChaliceStore) =>
  state.results.relatedSearches;
export const selectCompanySuggestion = (state: ChaliceStore) =>
  state.results.companySuggestion;
export const selectSolMetadataString = (state: ChaliceStore) =>
  state.results.solMetadataString;
export const selectSolMetadata = (state: ChaliceStore) =>
  state.results.solMetadata;
export const selectSolMetadataRequestToken = (state: ChaliceStore) =>
  state.results.solMetadata?.requestToken;
export const selectSolMetadataLocationDistance = (state: ChaliceStore) =>
  state.results.solMetadata?.locationDistance;
export const selectIsResultsInError = (state: ChaliceStore) =>
  state.results.isError;
export const selectCanonicalCompany = (state: ChaliceStore) =>
  state.results.canonicalCompany;
export const selectUniqueSearchToken = (state: ChaliceStore) =>
  state.results.uniqueSearchToken;
export const selectIsRadialFilterShown = (state: ChaliceStore) =>
  state.results.isRadialFilterShown;
export const selectIsRadialFilterNudgeShown = (state: ChaliceStore) =>
  state.results.isRadialFilterNudgeShown;
export const selectResultsSortMode = (state: ChaliceStore) =>
  state.results.sortMode;
export const selectResultsCount = (state: ChaliceStore) =>
  state.results.totalCount;
export const selectDisplayTotalCount = (state: ChaliceStore) =>
  state.results.results?.summary?.displayTotalCount || '0';
export const selectSelectedJobId = (state: ChaliceStore) =>
  state.results.selectedJobId;
export const selectNormalisedCompanyName = (state: ChaliceStore) =>
  state.results.companySuggestion?.search.companyName ||
  state.results.canonicalCompany?.description;
export const selectFacets = (state: ChaliceStore) => state.results.facets;
export const selectPills = (state: ChaliceStore) => state.results.pills;
export const selectTitleTagShortLocationName = (state: ChaliceStore) =>
  state.results.titleTagShortLocationName;
export const selectTitleTagTestTitle = (state: ChaliceStore) =>
  state.results.titleTagTestTitle;
export const selectGPTTargeting = (state: ChaliceStore) =>
  state.results.gptTargeting;
export const selectSearchSource = (state: ChaliceStore) =>
  state.results.searchSource;
