import { CookieConsentBanner } from './CookieConsentBanner/CookieConsentBanner';
import { PrivacyPolicyBanner } from './PrivacyPolicyBanner/PrivacyPolicyBanner';
import { ScamBanner } from './ScamBanner/ScamBanner';

export const GlobalNotificationBanner = () => (
  <>
    <CookieConsentBanner />
    <PrivacyPolicyBanner />
    <ScamBanner />
  </>
);
