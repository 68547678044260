import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useCreateReportedJob } from 'src/modules/hooks/ReportJobAd';
import { useCandidateDetails } from 'src/modules/hooks/useCandidateDetailsQuery';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

export default (jobId: string) => {
  const createReportedJob = useCreateReportedJob();
  const { email: emailAddress } = useCandidateDetails();
  const country = useMelwaysCountry();
  const analyticsFacade = useAnalyticsFacade();

  const submitReport = async (formValues: {
    reason: string;
    comment: string;
    email: string;
  }): Promise<boolean> => {
    const { reason, comment, email } = formValues;
    analyticsFacade.reportJobClicked();

    try {
      const result = await createReportedJob({
        jobId: Number(jobId),
        reason,
        comment,
        email: email || emailAddress,
        country,
      });
      return result;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return Promise.resolve(false);
    }
  };

  return submitReport;
};
