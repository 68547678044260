import { Box, Heading, HiddenVisually } from 'braid-design-system';
import { useCallback, type ComponentProps } from 'react';

import RefineChecklist from './RefineChecklist';

import * as styles from './Classifications.css';

interface ClassificationsProps {
  handleSelection: (value: string) => any;
  classifications: ComponentProps<typeof RefineChecklist>['options'];
}

const Classifications = (props: ClassificationsProps) => {
  const { handleSelection, classifications } = props;
  const handleSelectionChange = useCallback(
    (option: { value: string }, isActive: boolean) => {
      if (isActive) {
        handleSelection(option.value);
      }
    },
    [handleSelection],
  );

  return (
    <Box
      component="nav"
      role="navigation"
      aria-labelledby="RefineClassifications"
      overflow="auto"
      borderRadius="xlarge"
      paddingTop="small"
      className={styles.dropdownContainerSize}
    >
      <HiddenVisually>
        <Heading level="1" id="RefineClassifications">
          Classifications
        </Heading>
      </HiddenVisually>
      <RefineChecklist
        options={classifications}
        path="/jobs"
        onSelectionChange={handleSelectionChange}
      />
    </Box>
  );
};

export default Classifications;
