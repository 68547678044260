import { useTranslations } from '@vocab/react';
import type { ComponentProps } from 'react';

import { useBrandName } from 'src/hooks/useBrandName';

import { NudgeTemplate } from '../../NudgeTemplate/NudgeTemplate';
import { IconProfileSuccess } from '../icons';

import translations from './.vocab';

const ThankYouNudge = ({
  CTAs,
  name,
  onDismiss,
  position,
}: Pick<
  ComponentProps<typeof NudgeTemplate>,
  'CTAs' | 'onDismiss' | 'position'
> & { name: string }) => {
  const { t } = useTranslations(translations);
  const brandName = useBrandName();

  return (
    <NudgeTemplate
      CTAs={CTAs}
      data={{ automation: 'thank-you-nudge' }}
      heading={t('Thanks {name}!', { name })}
      Icon={IconProfileSuccess}
      id="thank_you"
      nudgeType="Thank You"
      onDismiss={onDismiss}
      position={position}
      text={t('We’ve updated your {brand} Profile.', { brand: brandName })}
    />
  );
};

export default ThankYouNudge;
