
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IkFsbCBqb2JzIiwiQXVzdHJhbGlhIjoiQXVzdHJhbGlhIiwiSG9uZyBLb25nIjoiSG9uZyBLb25nIiwiSW5kb25lc2lhIjoiSW5kb25lc2lhIiwiTWFsYXlzaWEiOiJNYWxheXNpYSIsIk5ldyBaZWFsYW5kIjoiTmV3IFplYWxhbmQiLCJQaGlsaXBwaW5lcyI6IlBoaWxpcHBpbmVzIiwiU2luZ2Fwb3JlIjoiU2luZ2Fwb3JlIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IlNlbXVhIHBla2VyamFhbiIsIkF1c3RyYWxpYSI6IkF1c3RyYWxpYSIsIkhvbmcgS29uZyI6IkhvbmcgS29uZyIsIkluZG9uZXNpYSI6IkluZG9uZXNpYSIsIk1hbGF5c2lhIjoiTWFsYXlzaWEiLCJOZXcgWmVhbGFuZCI6IlNlbGFuZGlhIEJhcnUiLCJQaGlsaXBwaW5lcyI6IkZpbGlwaW5hIiwiU2luZ2Fwb3JlIjoiU2luZ2FwdXJhIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IlNlbXVhIHBla2VyamFhbiIsIkF1c3RyYWxpYSI6IkF1c3RyYWxpYSIsIkhvbmcgS29uZyI6IkhvbmcgS29uZyIsIkluZG9uZXNpYSI6IkluZG9uZXNpYSIsIk1hbGF5c2lhIjoiTWFsYXlzaWEiLCJOZXcgWmVhbGFuZCI6IlNlbGFuZGlhIEJhcnUiLCJQaGlsaXBwaW5lcyI6IkZpbGlwaW5hIiwiU2luZ2Fwb3JlIjoiU2luZ2FwdXJhIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkF1c3RyYWxpYSI6IuC4reC4reC4quC5gOC4leC4o%2BC5gOC4peC4teC4oiIsIkhvbmcgS29uZyI6IuC4ruC5iOC4reC4h%2BC4geC4hyIsIkluZG9uZXNpYSI6IuC4reC4tOC4meC5guC4lOC4meC4teC5gOC4i%2BC4teC4oiIsIk1hbGF5c2lhIjoi4Lih4Liy4LmA4Lil4LmA4LiL4Li14LiiIiwiTmV3IFplYWxhbmQiOiLguJnguLTguKfguIvguLXguYHguKXguJnguJTguYwiLCJQaGlsaXBwaW5lcyI6IuC4n%2BC4tOC4peC4tOC4m%2BC4m%2BC4tOC4meC4quC5jCIsIlNpbmdhcG9yZSI6IuC4quC4tOC4h%2BC4hOC5guC4m%2BC4o%2BC5jCIsIlRoYWlsYW5kIjoi4LmE4LiX4LiiIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkF1c3RyYWxpYSI6IuC4reC4reC4quC5gOC4leC4o%2BC5gOC4peC4teC4oiIsIkhvbmcgS29uZyI6IuC4ruC5iOC4reC4h%2BC4geC4hyIsIkluZG9uZXNpYSI6IuC4reC4tOC4meC5guC4lOC4meC4teC5gOC4i%2BC4teC4oiIsIk1hbGF5c2lhIjoi4Lih4Liy4LmA4Lil4LmA4LiL4Li14LiiIiwiTmV3IFplYWxhbmQiOiLguJnguLTguKfguIvguLXguYHguKXguJnguJTguYwiLCJQaGlsaXBwaW5lcyI6IuC4n%2BC4tOC4peC4tOC4m%2BC4m%2BC4tOC4meC4quC5jCIsIlNpbmdhcG9yZSI6IuC4quC4tOC4h%2BC4hOC5guC4m%2BC4o%2BC5jCIsIlRoYWlsYW5kIjoi4LmE4LiX4LiiIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IlNlbXVhIHBla2VyamFhbiIsIkF1c3RyYWxpYSI6IkF1c3RyYWxpYSIsIkhvbmcgS29uZyI6IkhvbmcgS29uZyIsIkluZG9uZXNpYSI6IkluZG9uZXNpYSIsIk1hbGF5c2lhIjoiTWFsYXlzaWEiLCJOZXcgWmVhbGFuZCI6IlNlbGFuZGlhIEJhcnUiLCJQaGlsaXBwaW5lcyI6IkZpbGlwaW5hIiwiU2luZ2Fwb3JlIjoiU2luZ2FwdXJhIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IlNlbXVhIHBla2VyamFhbiIsIkF1c3RyYWxpYSI6IkF1c3RyYWxpYSIsIkhvbmcgS29uZyI6IkhvbmcgS29uZyIsIkluZG9uZXNpYSI6IkluZG9uZXNpYSIsIk1hbGF5c2lhIjoiTWFsYXlzaWEiLCJOZXcgWmVhbGFuZCI6IlNlbGFuZGlhIEJhcnUiLCJQaGlsaXBwaW5lcyI6IkZpbGlwaW5hIiwiU2luZ2Fwb3JlIjoiU2luZ2FwdXJhIiwiVGhhaWxhbmQiOiJUaGFpbGFuZCJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkF1c3RyYWxpYSI6IuC4reC4reC4quC5gOC4leC4o%2BC5gOC4peC4teC4oiIsIkhvbmcgS29uZyI6IuC4ruC5iOC4reC4h%2BC4geC4hyIsIkluZG9uZXNpYSI6IuC4reC4tOC4meC5guC4lOC4meC4teC5gOC4i%2BC4teC4oiIsIk1hbGF5c2lhIjoi4Lih4Liy4LmA4Lil4LmA4LiL4Li14LiiIiwiTmV3IFplYWxhbmQiOiLguJnguLTguKfguIvguLXguYHguKXguJnguJTguYwiLCJQaGlsaXBwaW5lcyI6IuC4n%2BC4tOC4peC4tOC4m%2BC4m%2BC4tOC4meC4quC5jCIsIlNpbmdhcG9yZSI6IuC4quC4tOC4h%2BC4hOC5guC4m%2BC4o%2BC5jCIsIlRoYWlsYW5kIjoi4LmE4LiX4LiiIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IuC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkF1c3RyYWxpYSI6IuC4reC4reC4quC5gOC4leC4o%2BC5gOC4peC4teC4oiIsIkhvbmcgS29uZyI6IuC4ruC5iOC4reC4h%2BC4geC4hyIsIkluZG9uZXNpYSI6IuC4reC4tOC4meC5guC4lOC4meC4teC5gOC4i%2BC4teC4oiIsIk1hbGF5c2lhIjoi4Lih4Liy4LmA4Lil4LmA4LiL4Li14LiiIiwiTmV3IFplYWxhbmQiOiLguJnguLTguKfguIvguLXguYHguKXguJnguJTguYwiLCJQaGlsaXBwaW5lcyI6IuC4n%2BC4tOC4peC4tOC4m%2BC4m%2BC4tOC4meC4quC5jCIsIlNpbmdhcG9yZSI6IuC4quC4tOC4h%2BC4hOC5guC4m%2BC4o%2BC5jCIsIlRoYWlsYW5kIjoi4LmE4LiX4LiiIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IlvhuqzGmsaaIMS1w7bDtsO2w7bDtsOfxaFdIiwiQXVzdHJhbGlhIjoiW%2BG6rMeax5rFoeG5r8WZxIPEg8aaw6zDrMSDxINdIiwiSG9uZyBLb25nIjoiW%2BG4qMO2w7bDtuC4geC4teC5icSjIOG4sMO2w7bDtuC4geC4teC5icSjXSIsIkluZG9uZXNpYSI6IlvDj%2BC4geC4teC5icaMw7bDtuC4geC4teC5ieG6veG6vcWhw6zDrMSDxINdIiwiTWFsYXlzaWEiOiJb4bmCxIPEg8aaxIPEg8O9w73FocOsw6zEg8SDXSIsIk5ldyBaZWFsYW5kIjoiW8OR4bq94bq94bq9xbUgxrXhur3hur3hur3Eg8SDxIPGmsSDxIPEg%2BC4geC4teC5icaMXSIsIlBoaWxpcHBpbmVzIjoiW8ak4bipw6zDrMOsxprDrMOsw6zGpcalw6zDrMOs4LiB4Li14LmJ4bq94bq94bq9xaFdIiwiU2luZ2Fwb3JlIjoiW8Wgw6zDrOC4geC4teC5icSjxIPEg8alw7bDtsWZ4bq94bq9XSIsIlRoYWlsYW5kIjoiW%2BG5ruG4qcSDxIPEg8Osw6zDrMaaxIPEg8SD4LiB4Li14LmJxoxdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9icyI6IlvhuqzGmsaaIMS1w7bDtsO2w7bDtsOfxaFdIiwiQXVzdHJhbGlhIjoiW%2BG6rMeax5rFoeG5r8WZxIPEg8aaw6zDrMSDxINdIiwiSG9uZyBLb25nIjoiW%2BG4qMO2w7bDtuC4geC4teC5icSjIOG4sMO2w7bDtuC4geC4teC5icSjXSIsIkluZG9uZXNpYSI6IlvDj%2BC4geC4teC5icaMw7bDtuC4geC4teC5ieG6veG6vcWhw6zDrMSDxINdIiwiTWFsYXlzaWEiOiJb4bmCxIPEg8aaxIPEg8O9w73FocOsw6zEg8SDXSIsIk5ldyBaZWFsYW5kIjoiW8OR4bq94bq94bq9xbUgxrXhur3hur3hur3Eg8SDxIPGmsSDxIPEg%2BC4geC4teC5icaMXSIsIlBoaWxpcHBpbmVzIjoiW8ak4bipw6zDrMOsxprDrMOsw6zGpcalw6zDrMOs4LiB4Li14LmJ4bq94bq94bq9xaFdIiwiU2luZ2Fwb3JlIjoiW8Wgw6zDrOC4geC4teC5icSjxIPEg8alw7bDtsWZ4bq94bq9XSIsIlRoYWlsYW5kIjoiW%2BG5ruG4qcSDxIPEg8Osw6zDrMaaxIPEg8SD4LiB4Li14LmJxoxdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    