import { Box } from 'braid-design-system';

import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';

import HoustonAd from '../../HoustonAd/HoustonAd';

import * as styles from './MRECBanner.css';

/**
 * Read more about the usage of this banner in `src/components/SearchResultPage/HoustonAd/README.md`.
 */
export const MRECBanner = () => {
  const showMarketingAdSplitView = useSelector(
    selectFeatureFlag('showMarketingAdSplitView'),
  );

  return showMarketingAdSplitView ? (
    <Box
      className={styles.mrecAdWrapper}
      data={{ automation: 'mrec-banner-wrapper' }}
    >
      <HoustonAd
        tagName="jobsearch_mrec"
        height={250}
        width={300}
        minWidth={styles.minimumDisplayWidth}
      />
    </Box>
  ) : null;
};
