import type { Locale } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';

import { useMelwaysLocale } from 'src/utils/melwaysHelper';

import translations from './.vocab';
import type { VocabLocale } from './types';

const monthYear = (locale: Locale, translatedMonth: string[]) => {
  const date = new Date();

  const year =
    new Intl.DateTimeFormat(locale)
      .formatToParts(date)
      .find(({ type }) => type === 'year')?.value ||
    date.getFullYear().toString();

  const month = translatedMonth[date.getMonth()];

  return {
    month,
    year,
  };
};

export const useMonthYear = () => {
  const locale = useMelwaysLocale();
  const { t } = useTranslations(translations);
  const translatedMonths = [
    t('jan-title'),
    t('feb-title'),
    t('mar-title'),
    t('apr-title'),
    t('may-title'),
    t('jun-title'),
    t('jul-title'),
    t('aug-title'),
    t('sep-title'),
    t('oct-title'),
    t('nov-title'),
    t('dec-title'),
  ];
  return monthYear(locale, translatedMonths);
};

export const getMonthYear = async (locale: VocabLocale) => {
  const messages = await translations.getMessages(locale);
  const translatedMonths = [
    messages['jan-title'].format(),
    messages['feb-title'].format(),
    messages['mar-title'].format(),
    messages['apr-title'].format(),
    messages['may-title'].format(),
    messages['jun-title'].format(),
    messages['jul-title'].format(),
    messages['aug-title'].format(),
    messages['sep-title'].format(),
    messages['oct-title'].format(),
    messages['nov-title'].format(),
    messages['dec-title'].format,
  ] as string[];
  return monthYear(locale, translatedMonths);
};
