import type { GetHomeRecommendationsQuery } from 'src/graphql/graphql';

const globalJobs: NonNullable<
  NonNullable<
    NonNullable<GetHomeRecommendationsQuery['viewer']>['recommendations']
  >['recommendedGlobalJobs']
>['globalJobs'] = [
  {
    __typename: 'RecommendedGlobalJob',
    job: {
      __typename: 'Job',
      advertiser: {
        __typename: 'Advertiser',
        name: 'Surge Direct Pty Ltd',
      },
      id: '29663948',
      title: 'Pastry Chef & Chef de Partie',
      location: {
        label: 'Cairns, Cairns & Far North QLD',
      },
      salary: null,
      listedAt: {
        label: '1h ago',
      },
      products: {
        branding: {
          logo: {
            url: 'https://image-service-cdn.seek.com.au/7681d821cee6efb70961d6436430839eac077c1e/f3c5292cec0e05e4272d9bf9146f390d366481d0',
          },
        },
      },
    },
    tags: [
      {
        label: 'New to you',
        type: 'NEW',
      },

      {
        label: 'Viewed',
        type: 'VIEWED',
      },
      {
        label: 'Expiring soon',
        type: 'EXPIRES_SOON',
      },
      {
        label: 'Be an early applicant',
        type: 'EARLY_APPLICANT',
      },
      {
        label: 'You may be a strong applicant',
        type: 'TOP_APPLICANT',
      },
    ],
    isNew: true,
    isSaved: false,
    solMetadata: {
      token: '1~201b49e4-d75c-4e74-a267-cdf652e36e77',
      jobId: '55268268',
      tags: {
        'recs:recSysChannel': '',
        'recs:product': 'recommended_jobs_page',
        'recs:experimentGroup': '51',
        'recs:flight': '51',
      },
    },
    id: '29663948',
  },
  {
    __typename: 'RecommendedGlobalJob',
    job: {
      __typename: 'Job',
      advertiser: {
        __typename: 'Advertiser',
        name: 'OnCue Talent',
      },
      id: '29752529',
      title: "EXTRA's Wanted for TV Commercials, TV Series & Film in Melbourne",
      salary: {
        label: '$25.66 p/hr + 11.5% Super + NFP Salary Packaging',
      },
      location: {
        label: 'Oakleigh South, Victoria',
      },
      listedAt: {
        label: '2d ago',
      },
      products: {
        branding: {
          logo: {
            url: 'https://image-service-cdn.seek.com.au/2e92eb70e5c5adfdc1e0ac590b3c64debd9dc3a4/f3c5292cec0e05e4272d9bf9146f390d366481d0',
          },
        },
      },
    },
    tags: [
      {
        label: 'New to you',
        type: 'UNSEEN',
      },
      {
        label: 'Be an early applicant',
        type: 'EARLY_APPLICANT',
      },
    ],
    isNew: false,
    isSaved: true,
    solMetadata: {
      token: '1~201b49e4-d75c-4e74-a267-cdf652e36e77',
      jobId: '55258592',
      tags: {
        'recs:recSysChannel': '',
        'recs:product': 'recommended_jobs_page',
        'recs:experimentGroup': '51',
        'recs:flight': '51',
      },
    },
    id: '29752529',
  },
  {
    __typename: 'RecommendedGlobalJob',
    job: {
      __typename: 'Job',
      advertiser: {
        __typename: 'Advertiser',
        name: 'Spirit',
      },
      id: '29677777',
      title:
        'No Experience Needed - Apply today start tomorrow - Full Time Appointment Setter',
      salary: {
        label: '$90,000 – $110,000 per year',
      },
      location: {
        label: 'North Sydney, Sydney NSW',
      },
      listedAt: {
        label: '9d ago',
      },
      products: {
        branding: {
          logo: {
            url: 'https://image-service-cdn.seek.com.au/f318d46b3b07d6d90a8f4120c48defbc7379a208/f3c5292cec0e05e4272d9bf9146f390d366481d0',
          },
        },
      },
    },
    isNew: false,
    isSaved: true,
    solMetadata: {
      token: '1~201b49e4-d75c-4e74-a267-cdf652e36e77',
      jobId: '55087627',
      tags: {
        'recs:recSysChannel': '',
        'recs:product': 'recommended_jobs_page',
        'recs:experimentGroup': '51',
        'recs:flight': '51',
      },
    },
    id: '29677777',
  },
  {
    __typename: 'RecommendedGlobalJob',
    job: {
      __typename: 'Job',
      advertiser: {
        __typename: 'Advertiser',
        name: 'Charterhouse Professional Support',
      },
      id: '29752529',
      title: 'Senior EA/Business Manager $110,000 package',
      salary: {
        label: '$110,000 package',
        currencyLabel: 'MYR',
      },
      location: {
        label: 'Sydney',
      },
      listedAt: {
        label: '1d ago',
      },
      products: {
        branding: {
          logo: {
            url: 'https://image-service-cdn.seek.com.au/3585866db3300b0803a44f1491f50ac457b6f116/dffceafa8da80ae865f1fa835a87a74a26be6546',
          },
        },
      },
    },
    isNew: false,
    isSaved: false,
    solMetadata: {
      token: '1~201b49e4-d75c-4e74-a267-cdf652e36e77',
      jobId: '55087627',
      tags: {
        'recs:recSysChannel': '',
        'recs:product': 'recommended_jobs_page',
        'recs:experimentGroup': '51',
        'recs:flight': '51',
      },
    },
    id: '29752529',
  },
  {
    __typename: 'RecommendedGlobalJob',
    job: {
      __typename: 'Job',
      advertiser: {
        __typename: 'Advertiser',
        name: 'Jobstreet',
      },
      id: '999',
      title: 'Error State for Job Detail',
      salary: {
        label: '$80,000 – $1,000,000 per year',
        currencyLabel: 'MYR',
      },
      location: {
        label: 'Kuala Lumpur, Malaysia',
      },
      listedAt: {
        label: '30d ago',
      },
      products: null,
    },
    isNew: false,
    isSaved: false,
    solMetadata: {
      token: '1~201b49e4-d75c-4e74-a267-cdf652e36e77',
      jobId: '55087627',
      tags: {
        'recs:recSysChannel': '',
        'recs:product': 'recommended_jobs_page',
        'recs:experimentGroup': '51',
        'recs:flight': '51',
      },
    },
    id: '999',
  },
];

export const mockRecommendations = (
  limit: number = 3,
): GetHomeRecommendationsQuery => ({
  viewer: {
    personalDetails: {
      __typename: 'PersonalDetails',
      firstName: 'FIRST_NAME',
    },
    emailAddress: 'EMAIL_ADDRESS',
    trackingId: 'TRACKING_ID',
    id: 100,
    recommendations: {
      __typename: 'Recommendations',
      recommendedGlobalJobs: {
        solMetadata: {
          token: '1~5c5623c0-3996-4dc8-82a0-fe8b1b450963',
          tags: {
            'recs:type': 'recommendedJobs',
          },
        },
        globalJobs: globalJobs.slice(0, limit),
        totalCount: 50,
      },
    },
  },
});
