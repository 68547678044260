import { Box, Stack, Text } from 'braid-design-system';

import { useSearchParams } from 'src/modules/seo-header/search-results-page/search-params';
import { useSelector } from 'src/store/react';
import {
  selectClearCompanyRefinements,
  selectCompanySuggestion,
  selectResults,
} from 'src/store/selectors';
import type {
  SearchViewModelHeader,
  SearchViewModelSuggestions,
} from 'src/types/globals';

import CompanyResults from '../CompanyResults/CompanyResults';
import CompanySuggestion, {
  BroadenCompanySearch,
} from '../CompanySuggestion/CompanySuggestion';
import DidYouMean from '../DidYouMean/DidYouMean';
import SearchResultsDistance from '../SearchResultDistance/SearchResultsDistance';
import SearchResultSummary from '../SearchResultSummary/SearchResultSummary';

const paddingY = 'large';

export const SearchResultsHeaderSpacer = () => (
  <Box component="span" display="block" padding={paddingY} aria-hidden>
    <Text size="small">&nbsp;</Text>
  </Box>
);

interface SearchResultsDistanceHeadersProps {
  shouldShowDistanceHeader: boolean | undefined;
  header: SearchViewModelHeader | null | undefined;
  locationSuggestions: SearchViewModelSuggestions | null | undefined;
}

const SearchResultsHeaders = ({
  shouldShowDistanceHeader,
  header,
  locationSuggestions,
}: SearchResultsDistanceHeadersProps) => {
  const searchResultsViewModel = useSelector(selectResults);
  const companySuggestion = useSelector(selectCompanySuggestion);
  const clearCompanyRefinements =
    useSelector(selectClearCompanyRefinements) || false;

  const { searchParams, jobs } = searchResultsViewModel || {};
  const { companyName, keywords: suggestCompanyAsKeywords } =
    companySuggestion?.search || {};

  const isCompanySearch = Boolean(searchParams && searchParams.companysearch);
  const showCompanyResults =
    typeof clearCompanyRefinements === 'object' && !isCompanySearch;

  const { tags } = useSearchParams();
  const isNewToYou = tags === 'new';
  return (
    <Box paddingY={paddingY}>
      <Stack space="large">
        <SearchResultSummary key="search-result-summary" />
        {!isNewToYou && (
          <>
            {companyName ? (
              <CompanySuggestion
                companyName={companyName}
                suggestionCount={companySuggestion?.count}
                searchParams={searchParams}
              />
            ) : null}
            {shouldShowDistanceHeader ||
            locationSuggestions ||
            suggestCompanyAsKeywords ||
            showCompanyResults ? (
              <Stack space="medium">
                {suggestCompanyAsKeywords ? (
                  <BroadenCompanySearch
                    keywords={suggestCompanyAsKeywords}
                    suggestionCount={companySuggestion?.count}
                    searchParams={searchParams}
                  />
                ) : null}
                {shouldShowDistanceHeader && !showCompanyResults ? (
                  <SearchResultsDistance message={header} />
                ) : null}
                {locationSuggestions ? (
                  <DidYouMean locations={locationSuggestions} />
                ) : null}
                {showCompanyResults ? (
                  <CompanyResults
                    isCompanySearch={isCompanySearch}
                    clearCompanyRefinements={clearCompanyRefinements}
                    jobs={jobs || []}
                  />
                ) : null}
              </Stack>
            ) : null}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default SearchResultsHeaders;
