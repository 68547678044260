
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGhlcmXigJlzIHNvbWV0aGluZyB3cm9uZyBvbiBvdXIgZW5kLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGVyamFkaSBrZXNhbGFoYW4gZGFyaSBwaWhhayBrYW1pLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IkthbWkgdGlkYWsgZGFwYXQgbWVueWltcGFuIGphd2FiYW4ga2UgcHJvZmlsIGthbXUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGVyamFkaSBrZXNhbGFoYW4gZGFyaSBwaWhhayBrYW1pLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IkthbWkgdGlkYWsgZGFwYXQgbWVueWltcGFuIGphd2FiYW4ga2UgcHJvZmlsIGthbXUuIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoi4Lie4Lia4LiC4LmJ4Lit4Lic4Li04LiU4Lie4Lil4Liy4LiU4Lia4Liy4LiH4Lit4Lii4LmI4Liy4LiHIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LiX4Liz4LiB4Liy4Lij4Lia4Lix4LiZ4LiX4Li24LiB4LiE4Liz4LiV4Lit4Lia4LiC4Lit4LiH4LiE4Li44LiT4Lil4LiH4Lia4LiZ4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LmE4LiU4LmJIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoi4Lie4Lia4LiC4LmJ4Lit4Lic4Li04LiU4Lie4Lil4Liy4LiU4Lia4Liy4LiH4Lit4Lii4LmI4Liy4LiHIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LiX4Liz4LiB4Liy4Lij4Lia4Lix4LiZ4LiX4Li24LiB4LiE4Liz4LiV4Lit4Lia4LiC4Lit4LiH4LiE4Li44LiT4Lil4LiH4Lia4LiZ4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LmE4LiU4LmJIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGVyamFkaSBrZXNhbGFoYW4gZGFyaSBwaWhhayBrYW1pLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IkthbWkgdGlkYWsgZGFwYXQgbWVueWltcGFuIGphd2FiYW4ga2UgcHJvZmlsIGthbXUuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiVGVyamFkaSBrZXNhbGFoYW4gZGFyaSBwaWhhayBrYW1pLiIsIldlIHdlcmVu4oCZdCBhYmxlIHRvIHNhdmUgeW91ciBhbnN3ZXIgdG8geW91ciBwcm9maWxlLiI6IkthbWkgdGlkYWsgZGFwYXQgbWVueWltcGFuIGphd2FiYW4ga2UgcHJvZmlsIGthbXUuIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoi4Lie4Lia4LiC4LmJ4Lit4Lic4Li04LiU4Lie4Lil4Liy4LiU4Lia4Liy4LiH4Lit4Lii4LmI4Liy4LiHIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LiX4Liz4LiB4Liy4Lij4Lia4Lix4LiZ4LiX4Li24LiB4LiE4Liz4LiV4Lit4Lia4LiC4Lit4LiH4LiE4Li44LiT4Lil4LiH4Lia4LiZ4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LmE4LiU4LmJIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoi4Lie4Lia4LiC4LmJ4Lit4Lic4Li04LiU4Lie4Lil4Liy4LiU4Lia4Liy4LiH4Lit4Lii4LmI4Liy4LiHIiwiV2Ugd2VyZW7igJl0IGFibGUgdG8gc2F2ZSB5b3VyIGFuc3dlciB0byB5b3VyIHByb2ZpbGUuIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Liq4Liy4Lih4Liy4Lij4LiW4LiX4Liz4LiB4Liy4Lij4Lia4Lix4LiZ4LiX4Li24LiB4LiE4Liz4LiV4Lit4Lia4LiC4Lit4LiH4LiE4Li44LiT4Lil4LiH4Lia4LiZ4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LmE4LiU4LmJIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiW%2BG5ruG4qeG6veG6veG6vcWZ4bq94bq94bq94oCZxaEgxaHDtsO2w7ZtzILhur3hur3hur3hua%2FhuKnDrMOsw6zguIHguLXguYnEoyDFtcWZw7bDtsO24LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMO2w7bDtseax5rHmsWZIOG6veG6veG6veC4geC4teC5icaMLl0iLCJXZSB3ZXJlbuKAmXQgYWJsZSB0byBzYXZlIHlvdXIgYW5zd2VyIHRvIHlvdXIgcHJvZmlsZS4iOiJbxbThur3hur3hur0gxbXhur3hur3hur3FmeG6veG6veG6veC4geC4teC5ieKAmeG5ryDEg8SDxIPDn8aa4bq94bq94bq9IOG5r8O2w7bDtiDFocSDxIPEg%2BG5veG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPguIHguLXguYnFocW14bq94bq94bq9xZkg4bmvw7bDtsO2IMO9w73DvcO2w7bDtseax5rHmsWZIMalxZnDtsO2w7bGksOsw6zDrMaa4bq94bq94bq9Ll0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGVyZeKAmXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQuIjoiW%2BG5ruG4qeG6veG6veG6vcWZ4bq94bq94bq94oCZxaEgxaHDtsO2w7ZtzILhur3hur3hur3hua%2FhuKnDrMOsw6zguIHguLXguYnEoyDFtcWZw7bDtsO24LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMO2w7bDtseax5rHmsWZIOG6veG6veG6veC4geC4teC5icaMLl0iLCJXZSB3ZXJlbuKAmXQgYWJsZSB0byBzYXZlIHlvdXIgYW5zd2VyIHRvIHlvdXIgcHJvZmlsZS4iOiJbxbThur3hur3hur0gxbXhur3hur3hur3FmeG6veG6veG6veC4geC4teC5ieKAmeG5ryDEg8SDxIPDn8aa4bq94bq94bq9IOG5r8O2w7bDtiDFocSDxIPEg%2BG5veG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPguIHguLXguYnFocW14bq94bq94bq9xZkg4bmvw7bDtsO2IMO9w73DvcO2w7bDtseax5rHmsWZIMalxZnDtsO2w7bGksOsw6zDrMaa4bq94bq94bq9Ll0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    