import { useMelwaysInfo } from '@seek/melways-react';
import type { Brand } from '@seek/melways-sites';

import { brandToCaseMap } from 'src/config/utils/constants';

type BrandName = (typeof brandToCaseMap)[keyof typeof brandToCaseMap];

export const useBrandName = (): BrandName => {
  const { brand } = useMelwaysInfo();
  return getBrandName(brand);
};

export const getBrandName = (brand: Brand) => {
  const brandName = brandToCaseMap[brand];
  return brandName || 'SEEK';
};
