import { Box, Divider, Heading, IconFilter, Stack } from 'braid-design-system';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectFeatureFlag } from 'src/store/selectors';

import { useDateListedField } from '../DateListed/useDateListedField';
import { useSalaryRangeField } from '../SalaryRange/useSalaryRangeField';
import { useWorkArrangementsField } from '../WorkArrangements/useWorkArrangementsField';

interface CombinedRefineFields {
  id: string;
  ariaLabel: string;
  label?: JSX.Element | string;
  summarySentence?: string;
  filterComponent: () => JSX.Element;
  isSelected: boolean;
}

export const useCombinedFields = (
  handleSelection: (fieldId: string) => void,
): CombinedRefineFields => {
  const remoteSearchFilterFeatureToggle = useSelector(
    selectFeatureFlag('remoteSearchFilter'),
  );
  const salaryRangeField = useSalaryRangeField(handleSelection);
  const workArrangementsField = useWorkArrangementsField(handleSelection);
  const dateListedField = useDateListedField(handleSelection);

  const fields = useMemo(
    () => [
      salaryRangeField,
      dateListedField,
      ...(remoteSearchFilterFeatureToggle ? [workArrangementsField] : []),
    ],
    [
      salaryRangeField,
      dateListedField,
      remoteSearchFilterFeatureToggle,
      workArrangementsField,
    ],
  );

  const selectedItems = useMemo(
    () =>
      fields.filter((field) => field.isSelected).map((field) => field.label),
    [fields],
  );

  const count = selectedItems.length;
  const displayLabel = (
    <>
      {count > 0 ? `${count} ` : ''}
      <IconFilter />
    </>
  );

  return {
    id: 'CombinedFields',
    ariaLabel: 'Combined fields',
    label: displayLabel,
    filterComponent: () => (
      <Box
        style={{
          maxHeight: '450px',
          overflowX: 'hidden',
          margin: '-24px -24px -24px 0 ',
        }}
        overflow="auto"
      >
        <Box paddingRight="medium" paddingY="medium">
          <Stack space="medium">
            <Stack space="medium" data={{ automation: 'workType' }}>
              <Heading level="4">Pay</Heading>
              <>{salaryRangeField.filterComponent()}</>
            </Stack>
            <Divider />
            {remoteSearchFilterFeatureToggle && (
              <Stack space="medium" data={{ automation: 'remoteOption' }}>
                <Heading level="4">Remote options</Heading>
                <>{workArrangementsField.filterComponent()}</>
                <Divider />
              </Stack>
            )}
            <Stack space="medium" data={{ automation: 'listingTime' }}>
              <Heading level="4">Listing time</Heading>
              <>{dateListedField.filterComponent()}</>
            </Stack>
          </Stack>
        </Box>
      </Box>
    ),
    isSelected:
      salaryRangeField.isSelected ||
      Boolean(dateListedField.isSelected) ||
      (remoteSearchFilterFeatureToggle &&
        Boolean(workArrangementsField.isSelected)),
  };
};
