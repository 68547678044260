import { useMelwaysInfo } from '@seek/melways-react';
import { GoogleOneTap } from '@seek/online-identity-ui-packages';
import { isHomepagePath, isSearchResultsPath } from '@seek/seek-jobs-seo';
import { TextLinkButton } from 'braid-design-system';
import { useCallback, useMemo } from 'react';

import { isGoogleOneTapEnabled } from 'src/config';
import {
  useAnalyticsFacade,
  type GoogleOneTapActionOrigin,
} from 'src/modules/AnalyticsFacade';
import { useSelector } from 'src/store/react';
import { selectAuthenticated, selectLocation } from 'src/store/selectors';
import { getSearchParams } from 'src/utils/urlParams';

const useReturnPath = () => {
  const currentLocation = useSelector(selectLocation);
  let returnPath = currentLocation.pathname;

  if (Object.keys(currentLocation.query ?? {}).length) {
    returnPath += `?${getSearchParams(currentLocation.query).toString()}`;
  }

  if (currentLocation.hash) {
    returnPath += currentLocation.hash;
  }

  return returnPath;
};

type GoogleOneTapEnablement = {
  enabled: boolean;
  actionOrigin: GoogleOneTapActionOrigin;
};
const useGoogleOneTapEnablement = (): GoogleOneTapEnablement => {
  const { pathname } = useSelector(selectLocation);

  return useMemo(() => {
    const isHomePage = isHomepagePath(pathname);
    const isSERP = isSearchResultsPath(pathname);

    return {
      enabled: isGoogleOneTapEnabled && (isHomePage || isSERP),
      actionOrigin: isHomePage ? 'home' : 'search results',
    };

    // Relying on the pathname would trigger showing the widget with every pathname change
    // Having that the widget is dismissed when users tap outside of it, we don't want to show it again
    // Hence, ignoring the dependency array here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const GoogleOneTapWrapper = () => {
  const { locale } = useMelwaysInfo();
  const isAuthenticated = useSelector(selectAuthenticated);
  const returnPath = useReturnPath();

  const analyticsFacade = useAnalyticsFacade();
  const { actionOrigin, enabled } = useGoogleOneTapEnablement();

  const onSuccess = useCallback(() => {
    analyticsFacade.googleOneTapPressed({
      actionOrigin,
    });
  }, [actionOrigin, analyticsFacade]);

  return enabled ? (
    <GoogleOneTap
      isAuthenticated={isAuthenticated}
      language={locale}
      returnPath={returnPath}
      onSuccess={onSuccess}
    />
  ) : (
    // This allows us to test the Google One Tap tracking in environments where it is not enabled
    !isAuthenticated && (
      <TextLinkButton data={{ automation: 'g-1-t-btn' }} onClick={onSuccess}>
        <></>
      </TextLinkButton>
    )
  );
};
