import { Box, Text, IconChevron, FieldLabel, Stack } from 'braid-design-system';
import { useCallback } from 'react';

import { useSalaryRange, type SalaryRange } from '../useSalaryRange';

import * as styles from './SalaryField.css';

export const SalaryField = ({
  salary,
  selectedSalaryLabel,
  activeDropdownField,
  setActiveDropdownField,
}: {
  salary: SalaryRange;
  selectedSalaryLabel?: string;
  activeDropdownField: string | null;
  setActiveDropdownField: (field: string | null) => void;
}) => {
  const { defaultCurrency } = useSalaryRange();

  const handleChange = useCallback(() => {
    setActiveDropdownField(
      activeDropdownField === salary.id ? null : salary.id,
    );
  }, [activeDropdownField, salary.id, setActiveDropdownField]);

  return (
    <Stack data={{ automation: 'salaryField' }} space="xxsmall">
      <FieldLabel
        htmlFor={`salarySelection-${salary.id}`}
        label={`${salary.label} • ${defaultCurrency.code}`}
      />
      <Box
        component="input"
        id={`salarySelection-${salary.id}`}
        type="checkbox"
        role="button"
        onChange={handleChange}
      />
      <Box
        component="label"
        htmlFor={`salarySelection-${salary.id}`}
        data={{ automation: `salaryField${salary.label}` }}
      >
        <Box
          position="relative"
          className={styles.salaryRangeBar}
          borderRadius="standard"
          display="flex"
          justifyContent="spaceBetween"
          padding="small"
          width="full"
        >
          <Text>{selectedSalaryLabel}</Text>
          <Text>
            <IconChevron
              direction={activeDropdownField === salary.id ? 'up' : 'down'}
            />
          </Text>
        </Box>
      </Box>
    </Stack>
  );
};
