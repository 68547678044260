import type { Language, Locale } from '@seek/melways-sites';

import { useSelector } from 'src/store/react';
import { selectAlternateLinks } from 'src/store/selectors';
import { useLocalisedLinks } from 'src/utils/melwaysHelper';

export interface HreflangLink {
  rel: 'alternate';
  href: string;
  hreflang: Language | Locale | 'x-default';
}

export const useLocalisedHrefLangLinks = () => {
  const alternateLinks = useSelector(selectAlternateLinks);

  const links = Object.entries(alternateLinks ?? []);

  const options = links.map(([language, link]) => ({
    path: link,
    language: (language as Language) ?? 'en',
    absolute: true,
  }));
  const qualifiedLinks = useLocalisedLinks(options);

  return links.reduce<HreflangLink[]>((finalLinks, [lang], index) => {
    const language = lang as Language;
    const qualifiedLink = qualifiedLinks[index];

    const newAlternateLink: HreflangLink = {
      href: qualifiedLink,
      rel: 'alternate',
      hreflang: language,
    };
    const newAlternateLinks = [newAlternateLink];

    if (language === 'en') {
      newAlternateLinks.push({
        ...newAlternateLink,
        hreflang: 'x-default',
      });
    }

    return finalLinks.concat(newAlternateLinks);
  }, []);
};
