import { useEffect, useState } from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useCandidateDetailsQuery } from 'src/modules/hooks/useCandidateDetailsQuery';
import { useDispatch } from 'src/store/react';
import { updateAuthenticated } from 'src/store/user';
import { SET_SEEKER_ID } from 'src/store/user/types';

export const useFetchCandidateDetails = () => {
  const [isCandidateFetched, setIsCandidateFetched] = useState(false);

  const analyticsFacade = useAnalyticsFacade();

  const dispatch = useDispatch();

  const { account, error, loading } = useCandidateDetailsQuery();

  useEffect(() => {
    if (!isCandidateFetched && !loading) {
      if (account || error) {
        if (account) {
          analyticsFacade.userDetailsUpdated({
            authenticated: true,
            trackingId: account.trackingId,
            loginId: String(account.id),
          });

          // We're syncing the SEEKER ID in the store for redux actions that can't ..
          // .. call hooks
          dispatch({
            type: SET_SEEKER_ID,
            payload: { id: account.id },
          });
        } else if (error) {
          const payload = { authenticated: false };

          analyticsFacade.userDetailsUpdated(payload);
          dispatch(updateAuthenticated(payload));
        }

        setIsCandidateFetched(true);
      }
    }
  }, [account, error, analyticsFacade, dispatch, loading, isCandidateFetched]);
};
