import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import {
  GetMatchedQualitiesDocument,
  type MatchedQualities,
} from 'src/graphql/graphql.ts';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';
import { selectAuthenticated } from 'src/store/user/selectors.ts';
import { useMelwaysLocale } from 'src/utils/melwaysHelper';

import { limitQualities, map, sync } from './useSkillsForJDV.helper';

export const useSkillsForJDV = (jobDetailsId: string) => {
  const isAuthenticated = useSelector(selectAuthenticated);
  const enableMatchedQualities = useSelector(
    selectFeatureFlag('matchedQualities'),
  );
  const locale = useMelwaysLocale();

  const { data, loading, error } = useQuery(GetMatchedQualitiesDocument, {
    variables: {
      jobDetailsId,
      locale,
    },
    skip: !isAuthenticated || !enableMatchedQualities,
    ssr: false,
  });

  const { viewer, jobDetails } = data ?? {};
  const { matchedSkills: unsynchedSkills } = jobDetails?.personalised ?? {};
  const { skills2, resumes } = viewer ?? {}; // from profile

  const [qualities, setQualities] = useState<MatchedQualities | null>(null);
  const [matchedQualitiesRequestToken, setMatchedQualitiesRequestToken] =
    useState<string | null>(null);

  useEffect(() => {
    if (!qualities && unsynchedSkills) {
      // only run once
      const newQualities = limitQualities(sync(map(unsynchedSkills), skills2));
      setQualities(newQualities);
      setMatchedQualitiesRequestToken(
        unsynchedSkills.matchedQualitiesRequestToken ?? null,
      );
    }
  }, [unsynchedSkills, skills2, qualities]); // todo to be fixed: When loading is become false, but new qualities are not synced yet => loading state is not accurate

  return {
    qualities,
    matchedQualitiesRequestToken,
    skillCount: skills2?.length ?? 0,
    hasResume: (resumes?.length ?? 0) > 0,
    error,
    loading: qualities === null || loading, // temp fix
  };
};
