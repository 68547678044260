import { useEffect, useState } from 'react';

import { Placeholder } from 'src/components/Placeholder/Placeholder';
import { advertsIsEnabled } from 'src/config';
import { useAppConfig } from 'src/config/appConfig';
import { GooglePublisherTag } from 'src/modules/gpt-react';

import useHoustonAd from './useHoustonAd';

interface Props {
  height: number;
  minWidth: number;
  tagName: string;
  width: number;
}

const HoustonAd = ({ minWidth, tagName, width, height }: Props) => {
  const { targetingData, shouldRenderAdverts } = useHoustonAd({
    minimumClientWidth: minWidth,
  });
  const { country } = useAppConfig();
  const { GPT_ACCOUNT_ID } = useAppConfig();
  const tagId = `/${GPT_ACCOUNT_ID}/SEEK${country}/${tagName}`;

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (shouldRenderAdverts && isClient) {
    return advertsIsEnabled ? (
      <GooglePublisherTag
        tagId={tagId}
        responsiveSizing={[{ sizes: [{ height, width }] }]}
        targetingData={targetingData}
      />
    ) : (
      <Placeholder
        width={width}
        height={height}
        label={`Advertisement with Tag ID: ${tagId}`}
        data-automation={`ad-${tagName}`}
      />
    );
  }

  return null;
};

export default HoustonAd;
