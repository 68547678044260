import { useTranslations } from '@vocab/react';

import { useSelector } from 'src/store/react';
import { selectKeywordField, selectWhereField } from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import translations from './.vocab';

export const useMinSearchBarText = () => {
  const { t } = useTranslations(translations);
  const keywordsField = useSelector(selectKeywordField);
  const whereField = useSelector(selectWhereField);
  const separator = ' • ';

  const country = useMelwaysCountry();

  const locationDisplayDefault: Record<typeof country, string> = {
    au: t('Australia'),
    nz: t('New Zealand'),
    hk: t('Hong Kong'),
    th: t('Thailand'),
    id: t('Indonesia'),
    my: t('Malaysia'),
    ph: t('Philippines'),
    sg: t('Singapore'),
  };

  if (keywordsField && whereField) {
    return `${keywordsField}${separator}${whereField}`;
  }

  if (keywordsField) {
    return `${keywordsField}${separator}${locationDisplayDefault[country]}`;
  }

  if (whereField) {
    return `${t('All jobs')}${separator}${whereField}`;
  }

  return `${t('All jobs')}${separator}${locationDisplayDefault[country]}`;
};
