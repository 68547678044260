import { Box, Stack, Tiles } from 'braid-design-system';
import { useCallback, useEffect, useState } from 'react';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import { useSelector } from 'src/store/react';
import { selectPathname, selectSearchQuery } from 'src/store/selectors';

import { SalaryField } from './SalaryField/SalaryField';
import { SalaryFieldDropdown } from './SalaryField/SalaryFieldDropdown/SalaryFieldDropdown';
import { SalaryType } from './SalaryType/SalaryType';
import { useSalaryRange } from './useSalaryRange';

interface SalaryProps {
  handleSelection: (value: string) => void;
}

export const SalaryRange = (props: SalaryProps) => {
  const { handleSelection } = props;
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));
  const [filterSelection, setFilterSelection] = useState(searchQuery);
  const pathName = useSelector(selectPathname);
  const [currentPathName, setCurrentPathName] = useState(pathName);
  const [activeDropdownField, setActiveDropdownField] = useState<string | null>(
    null,
  );

  useEffect(() => {
    // When candidate were to switch between homepage and serp and vice versa,
    // filterSelection should be updated against the searchQuery to reflect
    // the current search params.
    if (pathName !== currentPathName) {
      setFilterSelection(searchQuery);
      setCurrentPathName(pathName);
    }
  }, [currentPathName, pathName, searchQuery]);

  const {
    defaultSalaryRangeFrom,
    defaultSalaryRangeTo,
    salaryRanges,
    salarySelected,
    setSalarySelected,
    frequency,
    defaultFrequency,
    searchQueryWithoutSalary,
    data,
  } = useSalaryRange();

  const updateDefaultSalaryActiveField = useCallback(() => {
    setSalarySelected({
      salaryFromValue: defaultSalaryRangeFrom?.value,
      salaryToValue: defaultSalaryRangeTo?.value,
    });
  }, [
    setSalarySelected,
    defaultSalaryRangeFrom?.value,
    defaultSalaryRangeTo?.value,
  ]);

  useEffect(() => {
    const salaryRange = filterSelection?.salaryrange;
    const [salaryFrom, salaryTo] = salaryRange?.split('-') || [
      undefined,
      undefined,
    ];

    setSalarySelected({
      salaryFromValue: salaryFrom || defaultSalaryRangeFrom?.value,
      salaryToValue: salaryTo || defaultSalaryRangeTo?.value,
    });
  }, [
    defaultSalaryRangeFrom?.value,
    defaultSalaryRangeTo?.value,
    filterSelection?.salaryrange,
    setSalarySelected,
  ]);

  return (
    <>
      <Box
        component="label"
        position="fixed"
        inset={0}
        onClick={() => setActiveDropdownField(null)}
        display={activeDropdownField ? 'block' : 'none'}
      />
      <Stack space="large" data={{ automation: 'salaryRange' }}>
        <SalaryType
          updateDefaultSalaryActiveField={updateDefaultSalaryActiveField}
          salaryType={frequency}
          defaultFrequency={defaultFrequency}
          searchQueryWithoutSalary={searchQueryWithoutSalary}
          salaryData={data}
          filterSelection={filterSelection}
          setFilterSelection={setFilterSelection}
        />
        <Tiles columns={2} space="medium">
          {salaryRanges.map((salary, index) => (
            <Stack key={index} space="xxsmall">
              <SalaryField
                salary={salary}
                selectedSalaryLabel={salary.selectedSalaryLabel}
                activeDropdownField={activeDropdownField}
                setActiveDropdownField={setActiveDropdownField}
              />
              <SalaryFieldDropdown
                handleSelection={handleSelection}
                salary={salary}
                salarySelected={salarySelected}
                setSalarySelected={setSalarySelected}
                salaryType={frequency}
                setFilterSelection={setFilterSelection}
                activeDropdownField={activeDropdownField}
                setActiveDropdownField={setActiveDropdownField}
                filterSelection={filterSelection}
              />
            </Stack>
          ))}
        </Tiles>
      </Stack>
    </>
  );
};
