import { siteConfig, type CountrySiteName } from '@seek/melways-sites';
import type { FunctionComponent } from 'react';
import type {
  RouteObject,
  LoaderFunction,
  LoaderFunctionArgs,
} from 'react-router';

import { supportedLanguages } from 'src/modules/routes-regexp';
import { createUrlResolverWithDefaultHomepage } from 'src/utils/melwaysHelper';

import Search from './components/Search/Search';
import App from './handlers/App';
import Home from './handlers/Home';
import JobDetails from './handlers/JobDetails';
import SearchResults from './handlers/SearchResults';

const withOptionalLoader =
  (loaderFunction: LoaderFunction | undefined) =>
  async (args: LoaderFunctionArgs) =>
    (await loaderFunction?.(args)) || null;

const WILDCARD_PATH = '*';
const isWildCardPath = (path: string) => path === WILDCARD_PATH;

const generateRouteForSite = ({
  Component,
  path,
  site,
  withSearchBar,
  loaderFunction,
}: {
  Component: FunctionComponent;
  path: string;
  site: CountrySiteName;
  withSearchBar?: boolean;
  loaderFunction?: LoaderFunction;
}): RouteObject[] =>
  siteConfig[site].languages
    .filter((language) => supportedLanguages.includes(language))
    .reduce<RouteObject[]>((allRoutes, language, index) => {
      // We only want to generate one wildcard route per site
      if (isWildCardPath(path) && index > 0) {
        return allRoutes;
      }

      const urlResolver = createUrlResolverWithDefaultHomepage({
        site,
        language,
      });

      const route: RouteObject = {
        path: isWildCardPath(path) ? path : urlResolver({ path }),
        Component: withSearchBar ? Search : Component,
      };

      allRoutes.push({
        ...route,
        loader: withOptionalLoader(loaderFunction),
        id: withSearchBar
          ? `${path}-SEARCH-${language}`
          : `${path}-${language}`,
        // Only add children if we're using Search as the component
        ...(withSearchBar ? { children: [{ ...route, Component }] } : {}),
      });

      return allRoutes;
    }, []);

const reactRoutes = (
  site: CountrySiteName,
  loaderFunction?: LoaderFunction,
): RouteObject[] => [
  {
    path: '/',
    Component: App,
    children: [
      // Home page
      ...generateRouteForSite({
        Component: Home,
        path: '/',
        site,
        loaderFunction,
        withSearchBar: true,
      }),
      // Job details page
      ...generateRouteForSite({
        Component: JobDetails,
        path: '/job/:jobId',
        site,
        loaderFunction,
      }),
      // Expired job details page
      ...generateRouteForSite({
        Component: JobDetails,
        path: '/expiredjob/:jobId',
        site,
        loaderFunction,
      }),
      // We're using SearchResults as a fallback for all other routes
      ...generateRouteForSite({
        Component: SearchResults,
        path: WILDCARD_PATH,
        site,
        loaderFunction,
        withSearchBar: true,
      }),
    ],
  },
];

export default reactRoutes;
