import type { Pills as PillsType } from '@seek/chalice-types';
import {
  Box,
  IconAdd,
  IconClear,
  Text,
  useResponsiveValue,
} from 'braid-design-system';
import { forwardRef } from 'react';

import { truncateText } from 'src/utils/truncateText/truncateText.ts';

import * as styles from './Pills.css.ts';

import * as refineBarStyles from '../../../RefineBarV2/RefineBarV2.css';

interface PillsProps {
  handleOnPillClick: (
    label: string,
    keyword: string,
    position: number,
    isActive: boolean,
  ) => void;
  pills: PillsType;
}

export const Pills = forwardRef<HTMLElement, PillsProps>(
  ({ handleOnPillClick, pills }, ref) => {
    const isMobile =
      useResponsiveValue()({
        mobile: true,
        tablet: false,
      }) || false;

    return (
      <Box display="flex" ref={ref}>
        {pills.map(({ label, keywords, isActive }, index) => (
          <Box
            data={{ automation: `pill-${index}` }}
            key={index}
            display="flex"
            paddingRight={pills.length - 1 === index ? 'none' : 'xxsmall'}
          >
            <Box
              background={isActive ? 'customLight' : 'customDark'}
              data-selected={isActive ? 'true' : 'false'}
              className={refineBarStyles.pillButton}
              onClick={() =>
                handleOnPillClick(label, keywords, index, isActive)
              }
            >
              <Box className={styles.innerTextPill}>
                <Text
                  baseline={false}
                  maxLines={1}
                  size={isMobile ? 'small' : undefined}
                >
                  {isMobile ? truncateText({ text: label, length: 35 }) : label}
                </Text>
                <Box
                  className={{
                    [styles.pillIcon]: true,
                    [refineBarStyles.pillIcon]: true,
                  }}
                >
                  <Text
                    baseline={false}
                    size="small"
                    icon={isActive ? <IconClear /> : <IconAdd />}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  },
);
