import { useQuery } from '@apollo/client';

import { GetCandidateDetailsDocument } from 'src/graphql/graphql';
import { useSelector } from 'src/store/react';
import { selectAuthenticated } from 'src/store/selectors';

type CandidateAccount = {
  email: string;
  firstName: string;
  id?: number;
  trackingId?: string;
};

export const useCandidateDetails = (): CandidateAccount => {
  const { account } = useCandidateDetailsQuery();

  return {
    email: account?.emailAddress || '',
    firstName: account?.personalDetails.firstName || '',
    id: account?.id,
    trackingId: account?.trackingId,
  };
};

export const useCandidateDetailsQuery = () => {
  const isAuthenticated = useSelector(selectAuthenticated);

  const { data, loading, error } = useQuery(GetCandidateDetailsDocument, {
    ssr: false,
    skip: !isAuthenticated,
  });

  return {
    account: data?.viewer,
    loading,
    error,
  };
};
