import type { Zone } from '@seek/audience-zones';
import type { SearchParams } from '@seek/chalice-types';
import type { Language } from '@seek/melways-sites';

import type { Option } from 'src/types/globals';

import type { Meta } from '../meta';

export const UPDATE_CRITERIA = 'UPDATE_CRITERIA';
export const GET_COUNTS_BEGIN = 'GET_COUNTS_BEGIN';
export const GET_COUNTS_SUCCESS = 'GET_COUNTS_SUCCESS';
export const GET_COUNTS_ERROR = 'GET_COUNTS_ERROR';
export const UPDATE_BASEKEYWORDS_FIELD = 'UPDATE_BASEKEYWORDS_FIELD';
export const UPDATE_KEYWORDS_FIELD = 'UPDATE_KEYWORDS_FIELD';
export const UPDATE_WHERE_FIELD = 'UPDATE_WHERE_FIELD';
export const UPDATE_DYNAMIC_SEARCHBAR_HEIGHT =
  'UPDATE_DYNAMIC_SEARCHBAR_HEIGHT';
export const SELECT_REFINEMENT = 'SELECT_REFINEMENT';
export const SELECT_WHERE = 'SELECT_WHERE';
export const KEYWORD_CHANGED = 'KEYWORD_CHANGED';
export const SUBMIT_SEARCH = 'SUBMIT_SEARCH';
export const WHERE_FIELD_FOCUSED = 'WHERE_FIELD_FOCUSED';
export const WHERE_FIELD_BLANKED = 'WHERE_FIELD_BLANKED';
export const WHERE_FIELD_RECENT_SHOWN = 'WHERE_FIELD_RECENT_SHOWN';
export const WHERE_FIELD_RECENT_SELECTED = 'WHERE_FIELD_RECENT_SELECTED';
export const WHERE_FIELD_SUGGESTED_SHOWN = 'WHERE_FIELD_SUGGESTED_SHOWN';
export const WHERE_FIELD_SUGGESTED_SELECTED = 'WHERE_FIELD_SUGGESTED_SELECTED';
export const WHERE_FIELD_AUTOSUGGESTED_SELECTED =
  'WHERE_FIELD_AUTOSUGGESTED_SELECTED';
export const JOB_CLASSIFICATION_CLICKED = 'JOB_CLASSIFICATION_CLICKED';
export const JOB_SUBCLASSIFICATION_CLICKED = 'JOB_SUBCLASSIFICATION_CLICKED';
export const JOB_LOCATION_CLICKED = 'JOB_LOCATION_CLICKED';
export const JOB_COMPANY_CLICKED = 'JOB_COMPANY_CLICKED';
export const JOB_AREA_CLICKED = 'JOB_AREA_CLICKED';
export const JOB_SUBURB_CLICKED = 'JOB_SUBURB_CLICKED';
export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';
export const POEA_COUNTRY_PICKER = 'POEA_COUNTRY_PICKER';
export const POEA_COUNTRY_PICKER_FROM_NAVIGATION =
  'POEA_COUNTRY_PICKER_FROM_NAVIGATION';

type Match = [number, number];
interface DisplayPart {
  matches: Match[];
  text: string;
}
export interface LocationSuggestion {
  id: number;
  country: string;
  type: string;
  displayParts: DisplayPart[];
  displayText: string;
}

export interface Counts {
  location?: Record<string, number>;
  classification?: Record<string, number>;
  worktype?: Record<string, number>;
}

export interface SearchState {
  baseKeywords: string;
  keywordsField: string;
  whereField: string;
  query: SearchParams;
  lastQuery: SearchParams;
  hasLoadedCounts: boolean;
  filtersExpanded: boolean;
  poeaCountryPicker: boolean;
  poeaCountryPickerFromNavigation: boolean;
  refinements: {
    counts?: Counts;
    sortmode?: Option[];
    clear?: {
      company?: Record<string, unknown>;
    } & Record<string, unknown>;
  };
  dynamicSearchBarHeight: number;
}

export interface KeywordChangedAction {
  type: typeof KEYWORD_CHANGED;
  payload: {
    text: string;
    isSuggestion: boolean;
    previousText: string;
  };
}

export interface JobMetaClickAction {
  type:
    | typeof JOB_AREA_CLICKED
    | typeof JOB_CLASSIFICATION_CLICKED
    | typeof JOB_COMPANY_CLICKED
    | typeof JOB_LOCATION_CLICKED
    | typeof JOB_SUBURB_CLICKED
    | typeof JOB_SUBCLASSIFICATION_CLICKED;
  meta: { hotjar: string };
}

export interface SelectRefinementAction {
  type: typeof SELECT_REFINEMENT;
}

export interface EmptyAction {
  type: typeof WHERE_FIELD_FOCUSED;
}

export interface CountsApiResultItem {
  name: 'worktype' | 'classification' | 'location';
  items: Record<number, number>;
}
export interface CountsApiResult {
  counts: CountsApiResultItem[];
}

export type SearchAction =
  | EmptyAction
  | JobMetaClickAction
  | SelectRefinementAction
  | {
      type: typeof SUBMIT_SEARCH;
      meta: Meta;
    }
  | {
      type: typeof TOGGLE_FILTERS;
      payload: {
        filtersExpanded: boolean;
      };
    }
  | {
      type: typeof POEA_COUNTRY_PICKER;
      payload: {
        poeaCountryPicker: boolean;
      };
    }
  | {
      type: typeof POEA_COUNTRY_PICKER_FROM_NAVIGATION;
      payload: {
        poeaCountryPickerFromNavigation: boolean;
      };
    }
  | {
      type: typeof UPDATE_KEYWORDS_FIELD;
      payload: { keywordsField: string };
    }
  | {
      type: typeof UPDATE_WHERE_FIELD;
      payload: {
        whereField: string;
      };
    }
  | {
      type: typeof UPDATE_CRITERIA;
      payload: {
        lastQuery: SearchParams;
        query: SearchParams;
        zone: Zone;
        languageCode: Language;
      };
      meta: Meta;
    }
  | {
      type: typeof UPDATE_BASEKEYWORDS_FIELD;
      payload: {
        baseKeywords: string;
      };
    }
  | {
      type: typeof UPDATE_DYNAMIC_SEARCHBAR_HEIGHT;
      payload: {
        dynamicSearchBarHeight: number;
      };
    }
  | {
      type: typeof GET_COUNTS_BEGIN;
      payload: {
        query: SearchParams;
        zone: Zone;
        languageCode: Language;
      };
    }
  | {
      type: typeof GET_COUNTS_SUCCESS;
      payload: {
        result: CountsApiResult;
        zone: Zone;
        languageCode: Language;
      };
    }
  | {
      type: typeof GET_COUNTS_ERROR;
      error: true;
      payload: {
        err: Error;
        zone: Zone;
        languageCode: Language;
      };
    }
  | {
      type: typeof WHERE_FIELD_SUGGESTED_SELECTED;
    }
  | {
      type: typeof WHERE_FIELD_BLANKED;
    }
  | {
      type: typeof WHERE_FIELD_RECENT_SELECTED;
    }
  | {
      type: typeof WHERE_FIELD_AUTOSUGGESTED_SELECTED;
    }
  | {
      type: typeof WHERE_FIELD_SUGGESTED_SHOWN;
    }
  | {
      type: typeof WHERE_FIELD_RECENT_SHOWN;
    };
