import type { SearchResultJobV5 } from '@seek/chalice-types';
import { Box, Loader, Stack } from 'braid-design-system';
import { type ComponentProps, type ReactNode, useEffect } from 'react';

import { AccessabilityLinks } from 'src/components/SearchResultPage/SplitView/AccessabilityLinks/AccessabilityLinks';
import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import useAuthenticated from 'src/hooks/useAuthenticated';
import { selectTags } from 'src/store/location/selectors';
import { useDispatch, useSelector } from 'src/store/react';
import type { SearchViewModelCompanySuggestion } from 'src/store/results/types';
import { useRunSearch } from 'src/store/search/useRunSearch';
import {
  selectFeatureFlag,
  selectIsResultsLoading,
  selectLocationPageNumber,
  selectPaginationTotalPages,
} from 'src/store/selectors';

import NewToYouSignIn from '../NewToYouSignIn/NewToYouSignIn';
import SearchResultList from '../SearchResultList/SearchResultList';
import { UpToDateLastPage } from '../UpToDateLastPage/UpToDateLastPage';
import UpToDateZeroCount from '../UpToDateZeroCount/UpToDateZeroCount';
import ZeroResults from '../ZeroResults/ZeroResults';

import JobDetail from './JobDetail/JobDetail';
import SplitViewLayout from './SplitViewLayout/SplitViewLayout';
import { useBlockDisplayingSearchResultsUntilClientRenders } from './ssaAuthWorkAround';
import useSplitViewOpen1stJobAdEffect from './useSplitViewOpen1stJobAdEffect/useSplitViewOpen1stJobAdEffect';

import * as styles from './SplitView.css';

interface SplitViewProps {
  jobs: SearchResultJobV5[];
  resultSummary: ReactNode;
  isCompanySearch: ComponentProps<typeof SearchResultList>['isCompanySearch'];
  isZeroResults: boolean;

  companySuggestion?: SearchViewModelCompanySuggestion;
}

const SplitView = ({
  jobs,
  resultSummary,
  isCompanySearch,
  companySuggestion,
  isZeroResults = false,
}: SplitViewProps) => {
  const {
    selectedJob,
    setSelectedJob,
    currentJobDetails,
    fetchJobDetails,
    isLoading,
  } = useSplitViewContext();

  const isSearchLoading = useSelector(selectIsResultsLoading);

  useSplitViewOpen1stJobAdEffect(jobs);

  const totalPages = useSelector(selectPaginationTotalPages);
  const currentPageNumber = useSelector(selectLocationPageNumber);
  const searchTags = useSelector(selectTags);

  const SrpLoader = () => (
    <Box
      paddingY="xlarge"
      style={{ height: 400 }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      data-automation="searchResultsLoader"
    >
      <Loader size="large" />
    </Box>
  );

  const authenticateState = useAuthenticated();

  const showUpToDateZeroCount =
    authenticateState === 'authenticated' &&
    isZeroResults &&
    searchTags === 'new';

  const isBehaviouralCuesFiltersEnabled = useSelector(
    selectFeatureFlag('behaviouralCuesFilters'),
  );

  // SSA we need to check for count, if it's null it means we haven't done
  // a search on the server side - probably because we are searching for
  // a 'new' tag. This is to prevent first search results under 'new to you'
  // to appear before client side auth steps in and removes all the results
  // that the user had already seen.
  const newToYouCount = useSelector((c) => c.results.totalCountNewToYou) ?? -1;
  const dispatch = useDispatch();
  const runSearch = useRunSearch();

  useEffect(() => {
    if (searchTags === 'new' && (newToYouCount === null || newToYouCount < 0)) {
      dispatch(runSearch({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newToYouCount, dispatch]);

  // SSA ensure we don't render the search result summary until we have done a search
  // In the case of 'new to you' that means waiting for client side search happening first
  // which setBlockRenderUntilFirstNewToYouCompletedRequest manages
  const blockUntilClientReady =
    useBlockDisplayingSearchResultsUntilClientRenders();

  const isNewToYou = searchTags === 'new';

  const hideResults =
    showUpToDateZeroCount ||
    isSearchLoading ||
    isZeroResults ||
    blockUntilClientReady ||
    (authenticateState !== 'authenticated' && isNewToYou);

  const shouldShowSaveSearchAbovePagination =
    authenticateState === 'authenticated' && !hideResults;
  const shouldShowSaveSearchUnderPagination =
    authenticateState === 'unauthenticated' && !hideResults;

  const showMRECBanner = !hideResults;

  return (
    <Box className={styles.LoadingWrap}>
      {isBehaviouralCuesFiltersEnabled && (
        <>
          {isSearchLoading && (
            <Box
              className={styles.LoadingWrapItem}
              zIndex={1}
              pointerEvents="none"
            >
              <Box width="full" className={styles.content}>
                <SrpLoader />
              </Box>
            </Box>
          )}

          {authenticateState === 'unauthenticated' && searchTags === 'new' && (
            <Box
              className={styles.LoadingWrapItem}
              zIndex={1}
              marginTop="xxxlarge"
            >
              <Box width="full" className={styles.content}>
                <NewToYouSignIn />
              </Box>
            </Box>
          )}

          {authenticateState === 'authenticated' &&
            searchTags === 'new' &&
            isZeroResults && (
              <Box
                className={styles.LoadingWrapItem}
                zIndex={1}
                marginTop="xxxlarge"
              >
                <Box width="full" className={styles.content}>
                  <UpToDateZeroCount />
                </Box>
              </Box>
            )}

          {!isSearchLoading && isZeroResults && (
            <Box
              className={styles.LoadingWrapItem}
              zIndex={1}
              marginTop="xxxlarge"
            >
              <Box width="full" className={styles.content}>
                <ZeroResults companySuggestion={companySuggestion} />
              </Box>
            </Box>
          )}

          <Box className={styles.LoadingWrapItem}>
            <SplitViewLayout
              showMRECBanner={showMRECBanner}
              shouldShowSaveSearchAbovePagination={
                shouldShowSaveSearchAbovePagination
              }
              shouldShowSaveSearchUnderPagination={
                shouldShowSaveSearchUnderPagination
              }
              ListView={
                <>
                  {resultSummary}
                  {hideResults ? null : (
                    <>
                      <Stack space="medium">
                        {jobs.length > 0 && (
                          <SearchResultList
                            jobs={jobs}
                            isCompanySearch={isCompanySearch}
                            setCurrentJob={setSelectedJob}
                            selectedJobId={selectedJob?.id}
                          />
                        )}
                        {currentPageNumber === totalPages &&
                          searchTags === 'new' && <UpToDateLastPage />}
                      </Stack>
                    </>
                  )}
                </>
              }
              DetailView={
                hideResults ? null : (
                  <>
                    <AccessabilityLinks
                      placement="job-details"
                      position="top"
                    />
                    <JobDetail
                      currentJobDetails={selectedJob ? currentJobDetails : null}
                      fetchJobDetails={fetchJobDetails}
                      isLoading={isLoading}
                    />
                    {selectedJob ? (
                      <AccessabilityLinks
                        placement="job-details"
                        position="bottom"
                      />
                    ) : null}
                  </>
                )
              }
            />
          </Box>
        </>
      )}

      {!isBehaviouralCuesFiltersEnabled && (
        <>
          {!isSearchLoading && isZeroResults && (
            <Box
              className={styles.LoadingWrapItem}
              zIndex={1}
              marginTop="xxxlarge"
            >
              <Box width="full" className={styles.content}>
                <ZeroResults companySuggestion={companySuggestion} />
              </Box>
            </Box>
          )}

          {isSearchLoading && (
            <Box
              className={styles.LoadingWrapItem}
              zIndex={1}
              pointerEvents="none"
            >
              <Box width="full" className={styles.content}>
                <SrpLoader />
              </Box>
            </Box>
          )}

          {(isBehaviouralCuesFiltersEnabled &&
            searchTags === 'new' &&
            authenticateState === 'pending') ||
          (isZeroResults && !isBehaviouralCuesFiltersEnabled) ? null : (
            <Box className={styles.LoadingWrapItem}>
              <SplitViewLayout
                showMRECBanner={showMRECBanner}
                shouldShowSaveSearchAbovePagination={
                  shouldShowSaveSearchAbovePagination
                }
                shouldShowSaveSearchUnderPagination={
                  shouldShowSaveSearchUnderPagination
                }
                ListView={
                  <>
                    {resultSummary}
                    {hideResults ? null : (
                      <Stack space="medium">
                        {jobs.length > 0 && (
                          <SearchResultList
                            jobs={jobs}
                            isCompanySearch={isCompanySearch}
                            setCurrentJob={setSelectedJob}
                            selectedJobId={selectedJob?.id}
                          />
                        )}
                        {isBehaviouralCuesFiltersEnabled &&
                          currentPageNumber === totalPages &&
                          searchTags === 'new' && <UpToDateLastPage />}
                      </Stack>
                    )}
                  </>
                }
                DetailView={
                  hideResults ? null : (
                    <>
                      <AccessabilityLinks
                        placement="job-details"
                        position="top"
                      />
                      <JobDetail
                        currentJobDetails={
                          selectedJob ? currentJobDetails : null
                        }
                        fetchJobDetails={fetchJobDetails}
                        isLoading={isLoading}
                      />
                      {selectedJob ? (
                        <AccessabilityLinks
                          placement="job-details"
                          position="bottom"
                        />
                      ) : null}
                    </>
                  )
                }
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default SplitView;
