import { useSelector } from 'src/store/react';
import { selectIsSrp } from 'src/store/selectors';

import { ANIMATION_DURATION } from '../constants';

export const useAnimationDuration = () => {
  const isSRP = useSelector(selectIsSrp);

  return isSRP ? ANIMATION_DURATION : 0;
};
