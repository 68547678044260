import type { CountrySiteName } from '@seek/melways-sites';

export const SERVER_SIDE_AUTH_COOKIE_NAME = 'ENABLE_CHALICE_SSA';

// For now we're enabling SSA for all sites to allow testing
// This will be updated to only include released sites
export const releasedSSASites: CountrySiteName[] = [
  'candidate-seek-au',
  'candidate-seek-nz',
  'candidate-jobsdb-hk',
  'candidate-jobsdb-th',
  'candidate-jobstreet-id',
  'candidate-jobstreet-my',
  'candidate-jobstreet-ph',
  'candidate-jobstreet-sg',
];
