
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IkFsbCByZW1vdGUgb3B0aW9ucyIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJBbGwgb3B0aW9ucyIsIkFsbCByZW1vdGUgb3B0aW9ucyI6Intjb3VudCwgcGx1cmFsLCA9MCB7QWxsIHJlbW90ZSBvcHRpb25zfSBvdGhlciB7e2NvdW50fSByZW1vdGUgb3B0aW9uc319IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtSZW1vdGV9IG90aGVyIHt7Y291bnR9IG9wdGlvbnN9fSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IlNlbXVhIG9wc2kgamFyYWsgamF1aCIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJTZW11YSBvcHNpIiwiQWxsIHJlbW90ZSBvcHRpb25zIjoie2NvdW50LCBwbHVyYWwsID0wIHtTZW11YSBvcHNpIGphcmFrIGphdWh9IG90aGVyIHt7Y291bnR9IG9wc2kgamFyYWsgamF1aH19IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtKYXJhayBqYXVofSBvdGhlciB7e2NvdW50fSBvcHNpfX0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IlNlbXVhIG9wc2kgamFyYWsgamF1aCIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJTZW11YSBvcHNpIiwiQWxsIHJlbW90ZSBvcHRpb25zIjoie2NvdW50LCBwbHVyYWwsID0wIHtTZW11YSBvcHNpIGphcmFrIGphdWh9IG90aGVyIHt7Y291bnR9IG9wc2kgamFyYWsgamF1aH19IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtKYXJhayBqYXVofSBvdGhlciB7e2NvdW50fSBvcHNpfX0ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IuC4l%2BC4uOC4geC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4geC4h%2BC4suC4meC4o%2BC4sOC4ouC4sOC5hOC4geC4pSIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiLguJXguLHguKfguYDguKXguLfguK3guIHguJfguLHguYnguIfguKvguKHguJQiLCJBbGwgcmVtb3RlIG9wdGlvbnMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge%2BC4l%2BC4uOC4geC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4geC4h%2BC4suC4meC4o%2BC4sOC4ouC4sOC5hOC4geC4pX0gb3RoZXIge3tjb3VudH0g4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4LiwfX0iLCJSZW1vdGUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge%2BC4o%2BC4sOC4ouC4sOC5hOC4geC4pX0gb3RoZXIge3tjb3VudH0g4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiBfX0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IuC4l%2BC4uOC4geC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4geC4h%2BC4suC4meC4o%2BC4sOC4ouC4sOC5hOC4geC4pSIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiLguJXguLHguKfguYDguKXguLfguK3guIHguJfguLHguYnguIfguKvguKHguJQiLCJBbGwgcmVtb3RlIG9wdGlvbnMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge%2BC4l%2BC4uOC4geC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4geC4h%2BC4suC4meC4o%2BC4sOC4ouC4sOC5hOC4geC4pX0gb3RoZXIge3tjb3VudH0g4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4LiwfX0iLCJSZW1vdGUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge%2BC4o%2BC4sOC4ouC4sOC5hOC4geC4pX0gb3RoZXIge3tjb3VudH0g4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiBfX0ifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IlNlbXVhIG9wc2kgamFyYWsgamF1aCIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJTZW11YSBvcHNpIiwiQWxsIHJlbW90ZSBvcHRpb25zIjoie2NvdW50LCBwbHVyYWwsID0wIHtTZW11YSBvcHNpIGphcmFrIGphdWh9IG90aGVyIHt7Y291bnR9IG9wc2kgamFyYWsgamF1aH19IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtKYXJhayBqYXVofSBvdGhlciB7e2NvdW50fSBvcHNpfX0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IlNlbXVhIG9wc2kgamFyYWsgamF1aCIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiJTZW11YSBvcHNpIiwiQWxsIHJlbW90ZSBvcHRpb25zIjoie2NvdW50LCBwbHVyYWwsID0wIHtTZW11YSBvcHNpIGphcmFrIGphdWh9IG90aGVyIHt7Y291bnR9IG9wc2kgamFyYWsgamF1aH19IiwiUmVtb3RlIjoie2NvdW50LCBwbHVyYWwsID0wIHtKYXJhayBqYXVofSBvdGhlciB7e2NvdW50fSBvcHNpfX0ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IuC4l%2BC4uOC4geC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4geC4h%2BC4suC4meC4o%2BC4sOC4ouC4sOC5hOC4geC4pSIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiLguJXguLHguKfguYDguKXguLfguK3guIHguJfguLHguYnguIfguKvguKHguJQiLCJBbGwgcmVtb3RlIG9wdGlvbnMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge%2BC4l%2BC4uOC4geC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4geC4h%2BC4suC4meC4o%2BC4sOC4ouC4sOC5hOC4geC4pX0gb3RoZXIge3tjb3VudH0g4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4LiwfX0iLCJSZW1vdGUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge%2BC4o%2BC4sOC4ouC4sOC5hOC4geC4pX0gb3RoZXIge3tjb3VudH0g4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiBfX0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IuC4l%2BC4uOC4geC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4geC4h%2BC4suC4meC4o%2BC4sOC4ouC4sOC5hOC4geC4pSIsIkFsbCBvcHRpb25zICh0b3RhbCkiOiLguJXguLHguKfguYDguKXguLfguK3guIHguJfguLHguYnguIfguKvguKHguJQiLCJBbGwgcmVtb3RlIG9wdGlvbnMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge%2BC4l%2BC4uOC4geC4leC4seC4p%2BC5gOC4peC4t%2BC4reC4geC4h%2BC4suC4meC4o%2BC4sOC4ouC4sOC5hOC4geC4pX0gb3RoZXIge3tjb3VudH0g4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiB4Lij4Liw4Lii4LiwfX0iLCJSZW1vdGUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge%2BC4o%2BC4sOC4ouC4sOC5hOC4geC4pX0gb3RoZXIge3tjb3VudH0g4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiBfX0ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IlvhuqzGmsaaIMWZ4bq94bq94bq9bcyCw7bDtsO24bmv4bq94bq94bq9IMO2w7bDtsal4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiQWxsIG9wdGlvbnMgKHRvdGFsKSI6IlvhuqzGmsaaIMO2w7bDtsal4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiQWxsIHJlbW90ZSBvcHRpb25zIjoiW3tjb3VudCxwbHVyYWwsPTB74bqsxprGmiDFmeG6veG6veG6vW3MgsO2w7bDtuG5r%2BG6veG6veG6vSDDtsO2w7bGpeG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhfSBvdGhlcnt7Y291bnR9IMWZ4bq94bq9bcyCw7bDtuG5r%2BG6veG6vSDDtsO2xqXhua%2FDrMOsw7bDtuC4geC4teC5icWhfX1dIiwiUmVtb3RlIjoiW3tjb3VudCxwbHVyYWwsPTB7xZjhur3hur1tzILDtsO24bmv4bq94bq9fSBvdGhlcnt7Y291bnR9IMO2w7bDtsal4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaF9fV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgcmVtb3RlIG9wdGlvbnMgKHRvdGFsKSI6IlvhuqzGmsaaIMWZ4bq94bq94bq9bcyCw7bDtsO24bmv4bq94bq94bq9IMO2w7bDtsal4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiQWxsIG9wdGlvbnMgKHRvdGFsKSI6IlvhuqzGmsaaIMO2w7bDtsal4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiQWxsIHJlbW90ZSBvcHRpb25zIjoiW3tjb3VudCxwbHVyYWwsPTB74bqsxprGmiDFmeG6veG6veG6vW3MgsO2w7bDtuG5r%2BG6veG6veG6vSDDtsO2w7bGpeG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhfSBvdGhlcnt7Y291bnR9IMWZ4bq94bq9bcyCw7bDtuG5r%2BG6veG6vSDDtsO2xqXhua%2FDrMOsw7bDtuC4geC4teC5icWhfX1dIiwiUmVtb3RlIjoiW3tjb3VudCxwbHVyYWwsPTB7xZjhur3hur1tzILDtsO24bmv4bq94bq9fSBvdGhlcnt7Y291bnR9IMO2w7bDtsal4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaF9fV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    