import { type ApolloError, useMutation, useQuery } from '@apollo/client';
import type { Country } from '@seek/audience-zones';
import type { SearchParams } from '@seek/chalice-types';
import { useMelwaysInfo } from '@seek/melways-react';
import checksum from '@seek/request-checksum';
import { useTranslations } from '@vocab/react';
import pick from 'lodash/pick';
import querystring from 'query-string';
import type { FunctionComponent, ReactChildren } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import {
  CreateApacSavedSearchDocument,
  type CreateApacSavedSearchMutation,
  type CreateApacSavedSearchSuccess,
  CreateUnregisteredApacSavedSearchWithChecksumDocument,
  type CreateUnregisteredApacSavedSearchWithChecksumMutation,
  type CreateUnregisteredApacSavedSearchWithChecksumSuccess,
  GetSavedSearchesDocument,
} from 'src/graphql/graphql';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useDispatch, useSelector } from 'src/store/react';
import {
  createJobMailErrorAction,
  createSavedSearchErrorAction,
  saveSearchSuccess,
} from 'src/store/saveSearch';
import searchSaved from 'src/store/saveSearch/is-search-saved';
import {
  JOB_MAIL_SUCCESS,
  type JobMailPosition,
  type JobMailType,
  SAVE_SEARCH_DUPLICATE,
  SAVE_SEARCH_ERROR,
  SAVE_SEARCH_EXCEEDED,
  SAVE_SEARCH_INVALID_LOCATION,
  SavedSearchStatus,
} from 'src/store/saveSearch/types';
import { selectSearchQuery } from 'src/store/selectors';
import { useMelwaysLocale, useMelwaysZone } from 'src/utils/melwaysHelper';

import translations from './.vocab';

const attributesToSave = [
  'sitekey',
  'where',
  'sourcesystem',
  'classification',
  'subclassification',
  'keywords',
  'whereid',
  'graduatesearch',
  'worktype',
  'workarrangement',
  'include',
  'page',
  'daterange',
  'salarytype',
  'salaryrange',
  'distance',
  'sortmode',
];

export interface CreateSavedSearchProps {
  children: ReactChildren | string;
  component?: string | FunctionComponent;
  saveSearchPosition?: string;
}

export interface UseCreateUnregisteredSavedSearchProps {
  emailAddress: string;
  jobMailType: JobMailType;
  jobMailPosition: JobMailPosition;
}

const getSiteKey = (country: Country) => `${country.toUpperCase()}-Main`;

const mapPropsToSearchQueryString = (query: SearchParams): string =>
  querystring.stringify(pick(query, attributesToSave));

const isCreateSavedSearchSuccess = (
  response: CreateApacSavedSearchMutation['createApacSavedSearch'],
): response is CreateApacSavedSearchSuccess =>
  response.__typename === 'CreateApacSavedSearchSuccess';

const isCreateUnregisteredSaveSearchSuccess = (
  response: CreateUnregisteredApacSavedSearchWithChecksumMutation['createUnregisteredApacSavedSearchWithChecksum'],
): response is CreateUnregisteredApacSavedSearchWithChecksumSuccess =>
  response.__typename ===
  'CreateUnregisteredApacSavedSearchWithChecksumSuccess';

const savedSearchErrorStatusMap = (
  errorType: CreateApacSavedSearchMutation['createApacSavedSearch'],
) => {
  if ('error' in errorType) {
    switch (errorType.error.__typename) {
      case 'CreateApacSavedSearchDuplicateError':
        return SAVE_SEARCH_DUPLICATE;
      case 'CreateApacSavedSearchErrorMaxExceeded':
        return SAVE_SEARCH_EXCEEDED;
      case 'CreateApacSavedSearchInvalidLocationError':
        return SAVE_SEARCH_INVALID_LOCATION;
      default:
        return SAVE_SEARCH_ERROR;
    }
  }
  return SAVE_SEARCH_ERROR;
};

const jobMailErrorStatusMap = (
  errorType: CreateUnregisteredApacSavedSearchWithChecksumMutation['createUnregisteredApacSavedSearchWithChecksum'],
) => {
  if ('error' in errorType) {
    switch (errorType.error.__typename) {
      case 'CreateUnregisteredApacSavedSearchInvalidEmailError':
        return SavedSearchStatus.INVALID_EMAIL;
      case 'CreateUnregisteredApacSavedSearchInvalidLocationError':
        return SavedSearchStatus.INVALID_LOCATION;
      case 'CreateUnregisteredApacSavedSearchErrorMaxExceeded':
        return SavedSearchStatus.EXCEEDED;
      case 'CreateUnregisteredApacSavedSearchDuplicateError':
        return SavedSearchStatus.DUPLICATE;
      case 'CreateUnregisteredApacSavedSearchInvalidChecksumError':
      default:
        return SavedSearchStatus.ERROR;
    }
  }
  return SavedSearchStatus.ERROR;
};

export const useCreateSavedSearch = (): (() => void) => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();
  const dispatch = useDispatch();
  const { country } = useAppConfig();
  const { language } = useMelwaysInfo();
  const locale = useMelwaysLocale();
  const zone = useMelwaysZone();
  const searchParams = useSelector(selectSearchQuery);
  const workArrangements = searchParams.workarrangement
    ?.split(',')
    .sort()
    .join(',');
  const workTypes = searchParams.worktype?.split(',').sort().join(',');
  const query = {
    sitekey: getSiteKey(country),
    ...searchParams,
    workarrangement: workArrangements,
    worktype: workTypes,
  };
  const searchQueryString = mapPropsToSearchQueryString(query);

  const [saveSearch] = useMutation(CreateApacSavedSearchDocument, {
    variables: {
      input: {
        searchQueryString,
        zone,
        isUnified: true,
      },
      languageCode: language,
      locale,
    },
    onCompleted: ({ createApacSavedSearch: apacSavedSearch }) => {
      if (isCreateSavedSearchSuccess(apacSavedSearch)) {
        dispatch(saveSearchSuccess());
        analyticsFacade.createSaveSearchClicked({
          saveSearchPosition: 'fixed',
        });
        return;
      }

      if ('error' in apacSavedSearch) {
        dispatch(
          createSavedSearchErrorAction(
            savedSearchErrorStatusMap(apacSavedSearch),
            apacSavedSearch.error.message,
          ),
        );
      }
    },
    update: (cache, { data }) => {
      const savedSearchesCached = cache.readQuery({
        query: GetSavedSearchesDocument,
        variables: {
          languageCode: language,
        },
      });

      if (
        data?.createApacSavedSearch.__typename ===
          'CreateApacSavedSearchSuccess' &&
        savedSearchesCached?.viewer
      ) {
        cache.writeQuery({
          query: GetSavedSearchesDocument,
          data: {
            viewer: {
              id: savedSearchesCached.viewer.id,
              __typename: savedSearchesCached.viewer.__typename,
              apacSavedSearches: [
                data.createApacSavedSearch.savedSearch,
                ...(savedSearchesCached.viewer.apacSavedSearches ?? []),
              ],
            },
          },
        });
      }
    },
    onError: (_: ApolloError) => {
      dispatch(
        createSavedSearchErrorAction(
          SAVE_SEARCH_ERROR,
          t('There is something wrong on our end...'),
        ),
      );
    },
  });

  return saveSearch;
};

export const useCreateUnregisteredSavedSearch = () => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();
  const dispatch = useDispatch();
  const { country } = useAppConfig();
  const locale = useMelwaysLocale();
  const zone = useMelwaysZone();
  const query = {
    sitekey: getSiteKey(country),
    ...useSelector(selectSearchQuery),
  };

  const [createUnregisteredSaveSearch] = useMutation(
    CreateUnregisteredApacSavedSearchWithChecksumDocument,
  );

  const onCreateUnregisteredSavedSearch = ({
    emailAddress,
    jobMailType,
    jobMailPosition,
  }: UseCreateUnregisteredSavedSearchProps) =>
    createUnregisteredSaveSearch({
      variables: {
        input: {
          checksum: checksum({ emailAddress, ...query }),
          emailAddress,
          searchQueryString: mapPropsToSearchQueryString(query),
          zone,
          isUnified: true,
        },
        locale,
      },
      onCompleted: ({
        createUnregisteredApacSavedSearchWithChecksum: unregisteredSavedSearch,
      }) => {
        const jobMailPositionName =
          jobMailPosition === 'top' ? 'above-results' : 'below-results';
        if (!isCreateUnregisteredSaveSearchSuccess(unregisteredSavedSearch)) {
          const jobMailErrorStatus = jobMailErrorStatusMap(
            unregisteredSavedSearch,
          );

          dispatch(
            createJobMailErrorAction(
              jobMailErrorStatus,
              jobMailErrorStatus === SavedSearchStatus.ERROR
                ? t('There is something wrong on our end...')
                : unregisteredSavedSearch.error?.message,
            ),
          );

          analyticsFacade.createUnregisteredSaveSearchError({
            message:
              jobMailErrorStatus === SavedSearchStatus.ERROR
                ? 'There is something wrong on our end. Try again in a little while.'
                : unregisteredSavedSearch.error?.message,
          });
          return;
        }

        dispatch({
          type: JOB_MAIL_SUCCESS,
          meta: { hotjar: 'Job Mail Created', jobMailType },
          payload: {
            emailAddress,
            jobMailPositionName,
          },
        });

        analyticsFacade.createUnregisteredSaveSearchClicked({
          jobMailPosition: jobMailPositionName,
          linkText: 'Create alert',
        });
      },
      onError: (_: ApolloError) => {
        dispatch(
          createJobMailErrorAction(
            SavedSearchStatus.ERROR,
            t('There is something wrong on our end...'),
          ),
        );
        analyticsFacade.createUnregisteredSaveSearchError({
          message:
            'There is something wrong on our end. Try again in a little while.',
        });
      },
    });

  return { onCreateUnregisteredSavedSearch };
};

export const useFetchSavedSearches = () => {
  const query = useSelector(selectSearchQuery);
  const { country } = useAppConfig();
  const { language: languageCode } = useMelwaysInfo();
  const { data, error, loading } = useQuery(GetSavedSearchesDocument, {
    variables: {
      languageCode,
    },
    ssr: false,
  });

  const savedSearches = data?.viewer?.apacSavedSearches || null;
  const isSearchSaved = searchSaved(query, savedSearches, country);

  return {
    error,
    isSearchSaved,
    loading,
    savedSearches,
  };
};

export default useFetchSavedSearches;
