import { qualifyUrl } from '@seek/seek-jobs-seo';

import type { SeoInfo } from 'src/graphql/graphql';

const buildJobDetailsSEOLinks = ({
  normalisedRoleTitle,
  where,
  workType,
  classification,
  subClassification,
}: SeoInfo) => {
  const locationPath =
    normalisedRoleTitle && where
      ? qualifyUrl({
          path: '/jobs',
          query: {
            keywords: normalisedRoleTitle,
            where,
          },
          filteredEncoding: true,
        }).path
      : null;

  const specialisationPaths = classification
    ? classification.map((classItem, index) => {
        const subClassItem = subClassification?.[index];
        const { path } = qualifyUrl({
          path: '/jobs',
          query: {
            classification: classItem,
            ...(subClassItem && { subclassification: subClassItem }),
          },
          filteredEncoding: true,
        });
        return path;
      })
    : null;

  const workTypePath =
    normalisedRoleTitle && workType
      ? qualifyUrl({
          path: '/jobs',
          query: {
            keywords: normalisedRoleTitle,
            worktype: workType,
          },
          filteredEncoding: true,
        }).path
      : null;

  return {
    ...(locationPath && { locationUrl: locationPath }),
    ...(specialisationPaths && { classificationUrls: specialisationPaths }),
    ...(workTypePath && { workTypeUrl: workTypePath }),
  };
};

export default buildJobDetailsSEOLinks;
