import { useTranslations } from '@vocab/react';
import type { ComponentType } from 'react';

import type { NudgeType } from 'src/modules/AnalyticsFacade';

import translations from './.vocab';
import { IconSalary, IconWorkingRights } from './icons';
import { useSalaryNudgeQuery } from './useSalaryNudgeQuery';

const getProfileNudgeType = (prompt?: string): NudgeType => {
  // There's currently no way to know the nudge type.
  // Relying on nudge prompt text to decide for now.
  const nudgePrompt = prompt?.toLowerCase() || '';
  if (nudgePrompt.includes('salary')) {
    return 'Salary';
  } else if (nudgePrompt.includes('work')) {
    return 'Right To Work';
  }
  return 'Unknown';
};

export const getProfileNudgeTypeForTest = getProfileNudgeType;

const nudgeTypeIconMap: Partial<Record<NudgeType, ComponentType>> = {
  'Right To Work': IconWorkingRights,
  Salary: IconSalary,
};

export const useProfileNudge = () => {
  const { t } = useTranslations(translations);

  const { nudge, loading, error } = useSalaryNudgeQuery();

  const nudgeType = getProfileNudgeType(nudge?.prompt);
  const { heading, subHeading, text } = nudge
    ? {
        heading: t('Hi {name},', { name: nudge.firstName }),
        subHeading: nudge.prompt,
        text: nudge.text,
      }
    : {};

  return {
    heading,
    Icon: nudgeTypeIconMap[nudgeType],
    nudge,
    nudgeType,
    // Original code: fire metrics only when the nudge is loaded on the first time
    shouldFireMetrics: !loading && !error,
    subHeading,
    text,
    loading,
  };
};
