import type { Language } from '@seek/melways-sites';
import { useCallback } from 'react';

import { useDispatch } from '../react';

import { setLanguage as setLanguageStore } from '.';

/**
 * This hook will help to synchronize the language in the store. Which the `language` will be used outside of the react life cycle, such as React Router, or Rudex.
 */
export const useSetLanguage = () => {
  const dispatch = useDispatch();

  const setLanguage = useCallback(
    (lang: Language) => {
      dispatch(setLanguageStore(lang));
    },
    [dispatch],
  );

  return setLanguage;
};
