import { useQuery } from '@apollo/client';
import { metrics } from '@seek/metrics-js';

import { useZoneFeatures } from 'src/config/appConfig';
import { GetSalaryNudgeDocument } from 'src/graphql/graphql';
import { logger } from 'src/modules/logger';
import { useSelector } from 'src/store/react';
import { selectAuthenticated } from 'src/store/selectors';
import { useMelwaysZone } from 'src/utils/melwaysHelper';

export const NUDGE_METRICS_PREFIX = 'nudges';

export const useSalaryNudgeQuery = () => {
  const zone = useMelwaysZone();
  const isAuthenticated = useSelector(selectAuthenticated);
  const { NUDGE_ENABLED } = useZoneFeatures();

  const { data, error, loading } = useQuery(GetSalaryNudgeDocument, {
    variables: {
      zone,
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    skip: !NUDGE_ENABLED || !isAuthenticated,
    ssr: false,
    onCompleted() {
      metrics.count(NUDGE_METRICS_PREFIX, [
        `morethanzero:${salaryNudge ? 'true' : 'false'}`,
      ]);
    },
    onError(responseError) {
      metrics.count(NUDGE_METRICS_PREFIX, ['fetch:error']);
      logger.error(
        { input: { error: responseError, zone } },
        'SalaryNudge GraphQL Error',
      );
    },
  });

  const salaryNudge = data?.viewer?.salaryNudge;

  return {
    nudge: salaryNudge,
    loading,
    error,
  };
};
