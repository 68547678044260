import { useTranslations } from '@vocab/react';

import { Pagination as BraidPagination } from 'src/components/NavLink/NavLink';
import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useSelector } from 'src/store/react';
import {
  selectLocationPageNumber,
  selectPaginationTotalPages,
  selectSearchQuery,
} from 'src/store/selectors';

import translations from './.vocab';

import * as styles from './Pagination.css';

const Pagination = () => {
  const { clearSelectedJob } = useSplitViewContext();
  const analyticsFacade = useAnalyticsFacade();
  const currentPageNumber = useSelector(selectLocationPageNumber);
  const query = useSelector(selectSearchQuery);
  const totalPages = useSelector(selectPaginationTotalPages);

  const { t } = useTranslations(translations);

  return totalPages > 1 ? (
    <div className={styles.paginationContainer}>
      <BraidPagination
        page={currentPageNumber}
        total={totalPages}
        pageLimit={3}
        linkProps={({ page }) => ({
          'data-automation': `page-${page}`,
          location: {
            pathname: '/jobs',
            query: { ...query, ...{ page: page.toString() } },
          },
          preNavigationHooks: [
            () => {
              clearSelectedJob();
              analyticsFacade.paginationPressed();
            },
          ],
        })}
        label={t('Pagination of results')}
        previousLabel={t('Prev')}
        nextLabel={t('Next')}
      />
    </div>
  ) : null;
};

export default Pagination;
