import querystring from 'query-string';

const mockSkillData = [
  {
    keyword: {
      text: '.NET',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/97662222-086e-4d7e-b95e-3f2413611d9a',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  {
    keyword: {
      text: 'Web Development',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/83e4c1fa-37a2-4ad2-8c8e-bc33765ba7ec',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  {
    keyword: {
      text: 'C#',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/f5476482-dcee-4f3d-8703-0fb5032db2b5',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  {
    keyword: {
      text: 'JavaScript',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/88dc05ae-4673-41dd-a83e-570c999523e7',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  {
    keyword: {
      text: 'AngularJS',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/a0e455c7-c6b0-48f7-b288-e04c84a0a5ee',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  {
    keyword: {
      text: 'Typescript Programming',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/c7304e46-a3e7-45f1-a038-11f5af499cae',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/6279a02e-8d2c-4893-9176-fd9be4235efc',
      text: 'Mining Industry',
    },
  },
  {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/3c89d92d-4eae-4b6c-8668-55a2b960d1ad',
      text: 'Drilling',
    },
  },
];
const resumeData = [{ id: '90b515d1-7916-4ca2-8d76-cc0d93d91702' }];

export const defaultCandidateResolver = {
  __typename: 'Candidate',
  personalDetails: {
    firstName: 'Joe',
  },
  skills2: mockSkillData,
  resumes: resumeData,
  emailAddress: 'joe@gmail.com',
  id: 39675318,
  trackingId: '2cef3f488f07f1e946cd6a6bdcc62bf9',
  userCookie: {
    key: 'last-known-sol-user-id',
    value: '3364edb5-9fa8-40bc-9068-2cb3c032529c',
  },
};

const defaultMockResolvers = {
  Candidate: () => defaultCandidateResolver,
};

export default () => {
  const { testData = '' } =
    typeof window !== 'undefined'
      ? querystring.parseUrl(window.location.href).query
      : {};

  if (testData === 'apacSavedSearchesError') {
    return {
      ...defaultMockResolvers,
      Candidate: () => ({
        ...defaultCandidateResolver,
        apacSavedSearches: new Error('ERROR_WITH_APAC_SAVED_SEARCHES_RESOLVER'),
      }),
    };
  }

  if (testData === 'newCandidateNoProfileSetup') {
    return {
      ...defaultMockResolvers,
      Candidate: () => ({
        ...defaultCandidateResolver,
        apacSavedSearches: [],
      }),
    };
  }

  return defaultMockResolvers;
};
