import { useEffect, useState } from 'react';

import type { TargetingData } from 'src/modules/gpt-react';
import { isClientWidthAbove } from 'src/modules/responsive-helpers';
import { useSelector } from 'src/store/react';
import { selectGPTTargeting } from 'src/store/results/selectors';
import { selectResultsLocation, selectSearchQuery } from 'src/store/selectors';
import { useMelwaysLocale } from 'src/utils/melwaysHelper';

interface UseHoustonAdProps {
  minimumClientWidth: number;
}

const clean = (gptTargeting: TargetingData) =>
  gptTargeting.filter(
    ({ value }) =>
      (Array.isArray(value) && value.length) ||
      (typeof value === 'string' && Boolean(value)),
  );

const useHoustonAd = ({ minimumClientWidth }: UseHoustonAdProps) => {
  const [shouldRenderAdverts, setShouldRenderAdverts] = useState(
    isClientWidthAbove(minimumClientWidth),
  );
  const searchQuery = useSelector(selectSearchQuery);
  const location = useSelector(selectResultsLocation);
  const gptTargeting = useSelector(selectGPTTargeting);
  const locale = useMelwaysLocale();
  const [memorizedTargetingData, setMemorizedTargetingData] =
    useState<TargetingData>(() => {
      if (gptTargeting) {
        return clean(gptTargeting);
      }

      return [];
    });

  useEffect(() => {
    const newTargetingData = gptTargeting ? clean(gptTargeting) : [];

    if (
      JSON.stringify(newTargetingData) !==
      JSON.stringify(memorizedTargetingData)
    ) {
      setShouldRenderAdverts(false);
      setMemorizedTargetingData(newTargetingData);
    }
  }, [location, memorizedTargetingData, searchQuery, locale, gptTargeting]);

  useEffect(() => {
    if (isClientWidthAbove(minimumClientWidth) && !shouldRenderAdverts) {
      setShouldRenderAdverts(true);
    }
  }, [shouldRenderAdverts, minimumClientWidth]);

  return {
    shouldRenderAdverts,
    targetingData: memorizedTargetingData,
  };
};

export default useHoustonAd;
