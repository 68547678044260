import type { GetHomeSavedJobsQuery } from 'src/graphql/graphql';

export const mockSavedJobs: GetHomeSavedJobsQuery = {
  viewer: {
    id: 123,
    savedJobs: {
      edges: [
        {
          node: {
            id: '29677777',
            isActive: true,
            job: {
              id: '29677777',
              title: 'Prison Officer - Metropolitan Remand Centre',
              advertiser: {
                name: 'Department of Justice and Regulation',
              },
              salary: {
                label: '$70,000 – $75,000 per year',
                currencyLabel: 'NZD',
              },
              location: {
                label: 'Melbourne',
              },
              listedAt: {
                label: '10d ago',
              },
              products: {
                branding: null,
              },
            },
          },
        },
        {
          node: {
            id: '29800269',
            isActive: false,
            job: {
              id: '29800269',
              title: 'Warehouse Supervisor',
              advertiser: {
                name: 'Titan Recruitment Pty Ltd',
              },
              salary: null,
              location: {
                label: 'Perth',
              },
              listedAt: {
                label: '30m ago',
              },
              products: {
                branding: {
                  logo: {
                    url: 'https://image-service-cdn.seek.com.au/659ebcc208d9ecd6fa4128989c784a0294baef42/dffceafa8da80ae865f1fa835a87a74a26be6546',
                  },
                },
              },
            },
          },
        },
        {
          node: {
            id: '55570316',
            isActive: true,
            job: {
              id: '55570316',
              title: 'Distribution Centre Manager - Truganina',
              advertiser: {
                name: 'Inghams Enterprises Pty Ltd',
              },
              salary: {
                label: '$25.66 p/hr + 11.5% Super + NFP Salary Packaging',
              },
              location: {
                label: 'Cairns, Cairns & Far North QLD',
              },
              listedAt: {
                label: '1h ago',
              },
              products: {
                branding: {
                  logo: {
                    url: 'https://image-service-cdn.seek.com.au/2e92eb70e5c5adfdc1e0ac590b3c64debd9dc3a4/f3c5292cec0e05e4272d9bf9146f390d366481d0',
                  },
                },
              },
            },
          },
        },
      ],
      total: 33,
    },
  },
};
