import type { ApacCandidateHeader } from '@seek/apac-candidate-header-footer';
import { useMelwaysInfo } from '@seek/melways-react';
import { siteConfig } from '@seek/melways-sites';
import {
  cleanPath,
  isSearchResultsPath,
  qualifyUrl,
} from '@seek/seek-jobs-seo';
import isEmpty from 'lodash/isEmpty';
import { stringify } from 'query-string';
import { type ComponentProps, useMemo } from 'react';

import { supportedLanguages } from 'src/modules/routes-regexp';
import { useSelector } from 'src/store/react';
import {
  selectIsJDP,
  selectLocation,
  selectQuery,
  selectResultsLocationDescriptions,
  selectSearchQuery,
} from 'src/store/selectors';
import { useLocalisedLinks } from 'src/utils/melwaysHelper';

const useBasePath = (): string => {
  const location = useSelector(selectLocation);
  if (isSearchResultsPath(location.pathname)) {
    return '/jobs';
  }

  return cleanPath(location.pathname, true);
};

type LanguageSwitcher = NonNullable<
  ComponentProps<typeof ApacCandidateHeader>['languageSwitcher']
>;
export const useLanguageSwitcher = () => {
  const isJDP = useSelector(selectIsJDP);
  const query = useSelector(selectQuery);
  const searchQuery = useSelector(selectSearchQuery);

  const resultsLocationDescriptions = useSelector(
    selectResultsLocationDescriptions,
  );
  const basePath = useBasePath();

  const { site } = useMelwaysInfo();
  const siteLanguages = siteConfig[site].languages;
  const languages = siteLanguages.filter((language) =>
    supportedLanguages.includes(language),
  );

  const links = useMemo(() => {
    if (languages.length < 2) {
      return [];
    }

    const newQuery = { ...(isJDP ? query : searchQuery) };
    return languages.map((language) => {
      if (
        basePath === '/jobs' &&
        resultsLocationDescriptions?.[language]?.contextualName
      ) {
        // use the location in the other language
        newQuery.where =
          resultsLocationDescriptions?.[language]?.contextualName;
      }

      const { path: qualifiedPath, query: qualifiedQuery } = qualifyUrl({
        path: basePath,
        query: newQuery,
      });

      return {
        path: qualifiedPath,
        query: qualifiedQuery,
        language,
      };
    });
  }, [
    basePath,
    isJDP,
    languages,
    query,
    resultsLocationDescriptions,
    searchQuery,
  ]);

  const localisedLanguageSwitcherLinks = useLocalisedLinks(links);

  if (links.length) {
    return links.reduce<LanguageSwitcher>(
      (allLinks, { language, query: qualifiedQuery }, index) => {
        allLinks[language] = {
          url: `${localisedLanguageSwitcherLinks[index]}${
            qualifiedQuery && !isEmpty(qualifiedQuery)
              ? `?${stringify(qualifiedQuery)}`
              : ''
          }`,
        };

        return allLinks;
      },
      {},
    );
  }
};
