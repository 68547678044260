import { Box, ButtonIcon, IconChevron } from 'braid-design-system';

import * as styles from './ScrollButton.css';

export const ScrollButton = ({
  direction,
  display,
  label,
  handleOnClick,
}: {
  display: 'flex' | 'none';
  direction: 'left' | 'right';
  label: string;
  handleOnClick: (event: React.MouseEvent<HTMLElement>) => void;
}) => (
  <>
    {direction === 'right' && (
      <Box
        display={display}
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        className={styles.fadeInRight}
        pointerEvents="none"
      />
    )}

    <Box
      display={display}
      position="absolute"
      top={0}
      bottom={0}
      left={direction === 'left' ? 0 : undefined}
      right={direction === 'right' ? 0 : undefined}
      onClick={handleOnClick}
      zIndex={1}
    >
      {direction === 'left' && (
        <Box
          display={display}
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          className={styles.fadeInLeft}
          pointerEvents="none"
          zIndex={0}
        />
      )}
      <ButtonIcon
        data={{ automation: 'scrollButton' }}
        variant="soft"
        id={label}
        label={label}
        icon={<IconChevron direction={direction} tone="secondary" />}
        size="standard"
        bleed={false}
      />
    </Box>
  </>
);
