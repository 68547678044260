import { useTranslations } from '@vocab/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import { getWorkArrangements } from 'src/modules/refine-job-search';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag, selectSearchQuery } from 'src/store/selectors';
import type { Option } from 'src/types/globals';
import { useMelwaysLocale } from 'src/utils/melwaysHelper';

import type { RefineField } from '../RefineBarV2';
import WorkArrangementSummary from '../WorkArrangementSummary/WorkArrangementSummary';

import translations from './.vocab';
import WorkArrangements from './WorkArrangements';

export const useWorkArrangementsField = (
  handleSelection: (fieldId: string) => void,
): RefineField => {
  const { t } = useTranslations(translations);
  const locale = useMelwaysLocale();
  const query = getRefineSearchQuery(useSelector(selectSearchQuery));
  const workArrangements = query?.workarrangement || '';
  const isRemoteFilterEnabled = useSelector(
    selectFeatureFlag('remoteSearchFilter'),
  );
  const showRemoteFilterNewLabel = useSelector(
    selectFeatureFlag('remoteSearchFilterNewLabel'),
  );

  const workArrangementList = getWorkArrangements(locale);

  const [activeId, setActiveId] = useState<string[]>(
    workArrangements ? workArrangements.split(',') : [],
  );

  useEffect(() => {
    const currWorkArrangements = workArrangements
      ? workArrangements.split(',')
      : [];

    const uniqCurrWorkArrangements = new Set(currWorkArrangements);
    const uniqActiveValues = new Set(activeId);

    const setsAreEqual =
      uniqCurrWorkArrangements.size === uniqActiveValues.size &&
      [...uniqCurrWorkArrangements].every((value) =>
        uniqActiveValues.has(value),
      );

    if (!setsAreEqual) {
      setActiveId(currWorkArrangements);
    }

    // Only the Redux state (workArrangements) should trigger a re-run not local state (activeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workArrangements]);

  const getOptionItem = useCallback(
    (label: string, value: string) => {
      const isActive = activeId.includes(value);
      const workArrangementRefineParam = isActive
        ? activeId.filter((v) => v !== value)
        : [...activeId, value];
      return {
        label,
        value,
        isActive,
        refineParams: {
          ...query,
          workarrangement: workArrangementRefineParam.join(','),
        },
      };
    },
    [activeId, query],
  );

  const workArrangementOptions: Option[] = useMemo(
    () =>
      workArrangementList
        ? workArrangementList.map(({ id, label }) => getOptionItem(label, id))
        : [],
    [workArrangementList, getOptionItem],
  );

  const handleWorkArrangementSelection = (value: string) => {
    setActiveId(() => {
      if (activeId.includes(value)) {
        return activeId.filter((v) => v !== value);
      }
      return [...activeId, value];
    });
    handleSelection('WorkArrangement');
  };

  const isSelected = activeId.length > 0;

  return {
    id: 'WorkArrangement',
    ariaLabel: t('work arrangement'),
    label: <WorkArrangementSummary workArrangements={workArrangementOptions} />,
    filterComponent: () => (
      <WorkArrangements
        handleSelection={handleWorkArrangementSelection}
        workArrangements={workArrangementOptions}
      />
    ),
    ...(isRemoteFilterEnabled &&
      showRemoteFilterNewLabel && { status: { label: t('New') } }),
    isSelected,
  };
};
