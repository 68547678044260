import gql from 'graphql-tag';

export const GET_SAVED_SEARCHES = gql`
  query GetSavedSearches($languageCode: LanguageCodeIso) {
    viewer {
      id
      apacSavedSearches {
        id
        name(languageCode: $languageCode)
        countLabel
        newToYouCountLabel
        query(languageCode: $languageCode) {
          searchQueryString
          parameters {
            type
            value
          }
        }
      }
    }
  }
`;
