import type {
  TestHeaders,
  TestTags,
} from 'src/utils/productionTesting/productionTesting';

import type { Meta } from '../meta';

export const UPDATE_AUTHENTICATED = 'UPDATE_AUTHENTICATED';
export const SET_SEEKER_ID = 'SET_SEEKER_ID';
export const UPDATE_TEST_HEADERS = 'UPDATE_TEST_HEADERS';
export const UPDATE_TEST_TAGS = 'UPDATE_TEST_TAGS';

export interface UserState {
  authenticated?: boolean;
  userClientId: string;
  /**
   * If you're selecting this value in a React component or a custom React hook, it's
   * advised to use `useCandidateDetails` hook instead.
   * We're only keeping this value here for the sake of compatibility with Redux actions.
   */
  seekerId?: number;
  sessionId: string;
  testHeaders: TestHeaders;
  testTags?: TestTags;
}

interface UpdateAuthenticatedAction {
  type: typeof UPDATE_AUTHENTICATED;
  payload: {
    authenticated: boolean;
  };
  meta?: Meta;
}

interface UpdateTestHeadersAction {
  type: typeof UPDATE_TEST_HEADERS;
  payload: {
    testHeaders: TestHeaders;
  };
}

interface UpdateTestTagsAction {
  type: typeof UPDATE_TEST_TAGS;
  payload: {
    testTags?: TestTags;
  };
}

interface SetSeekerIdAction {
  type: typeof SET_SEEKER_ID;
  payload: {
    id: number;
  };
}

export type UserAction =
  | UpdateAuthenticatedAction
  | SetSeekerIdAction
  | UpdateTestHeadersAction
  | UpdateTestTagsAction;
