import { useMelwaysInfo } from '@seek/melways-react';
import { useTranslations } from '@vocab/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getDateRanges } from 'src/modules/refine-job-search';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag, selectSearchQuery } from 'src/store/selectors';
import type { Option } from 'src/types/globals';
import { useMelwaysZone } from 'src/utils/melwaysHelper';

import { getRefineSearchQuery } from '../../utils/utils';

import translations from './.vocab';
import DateListed from './DateListed';

export const useDateListedField = (
  handleSelection: (fieldId: string) => void,
) => {
  const { t } = useTranslations(translations);
  const { language } = useMelwaysInfo();
  const zone = useMelwaysZone();
  const query = getRefineSearchQuery(useSelector(selectSearchQuery));
  const dateListed = query?.daterange || '';
  const dynamicPillsV2 = useSelector(selectFeatureFlag('dynamicPillsV2'));

  const defaultDateListed = useMemo(
    () => ({
      label: t('Any time'),
      value: '',
    }),
    [t],
  );

  const dates = getDateRanges(zone, language);

  const [activeValue, setActiveValue] = useState<string>(dateListed);

  useEffect(() => {
    if (dateListed !== activeValue) {
      setActiveValue(dateListed);
    }
  }, [dateListed, activeValue]);

  const getOptionItem = useCallback(
    (label: string, value: string) => {
      const isActive = activeValue === value;
      return {
        label,
        value,
        isActive,
        ...(!isActive && {
          refineParams: {
            ...query,
            daterange: value,
          },
        }),
      };
    },
    [activeValue, query],
  );

  const dateListedOptions: Option[] = useMemo(
    () =>
      dates ? dates.map(({ label, value }) => getOptionItem(label, value)) : [],
    [dates, getOptionItem],
  );

  const selectedDateListed =
    dateListedOptions?.find((value) => value.isActive) ?? defaultDateListed;
  const refineDateListedLabel =
    selectedDateListed.value === '' || selectedDateListed.value === '1'
      ? t('listed')
      : t('listed in');

  let refineDateListedLabelAlternative;
  switch (selectedDateListed.value) {
    case '':
      refineDateListedLabelAlternative = t('Listing time');
      break;
    case '1':
      refineDateListedLabelAlternative = t('listed');
      break;
    default:
      refineDateListedLabelAlternative = t('listed in');
  }

  const isSelected = activeValue !== defaultDateListed.value;

  const summarySentence = dynamicPillsV2
    ? refineDateListedLabelAlternative
    : refineDateListedLabel;
  const label =
    dynamicPillsV2 && selectedDateListed.value === ''
      ? ''
      : selectedDateListed.label.toLowerCase();

  return {
    id: 'DateListed',
    summarySentence,
    ariaLabel: t('date listed'),
    label,
    filterComponent: () => (
      <DateListed
        handleSelection={() => handleSelection('DateListed')}
        dateRanges={dateListedOptions ?? []}
      />
    ),
    isSelected,
  };
};
